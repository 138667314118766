import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
  typography: {
    "fontFamily": `"Oxygen", "Helvetica", "Arial", sans-serif`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
   },
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#41c0f0',
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;