import { Box, Paper, Breadcrumbs, Link } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Container, Image } from "react-bootstrap";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { getApi } from "../../services";
import { ApiState, PaymentState as SearchReduxState, useSelector } from "../../redux";
export const PatientAlert: React.FC = () => {
  const navigate = useNavigate();
  const [doctor,setDoctor] = useState<any>([])
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );
  const apistate = useSelector<ApiState>((state) => state.ApiReducer)
  const getapi = getApi();

  // useEffect(() =>{
  //   // Retrieve the cookie value
  //   var cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)DoctorDetails\s*=\s*([^;]*).*$)|^.*$/, '$1');
    
  //   // If the cookie value exists, decode it and parse the JSON string back to an array
  //   if (cookieValue) {
  //     var retrievedArray = JSON.parse(decodeURIComponent(cookieValue));
 
  //     setDoctor(retrievedArray)
  //   } else {

  //   }
  //   },[])

    const Apistate = useSelector<ApiState>(
      (state) => state.ApiReducer
    );

    const DoctorDetails = () => {
      // setLoading(true);
       let data = {
          location_id: Apistate.location_id,
          user_id: Apistate.userid ?Apistate.userid :Cookies.get("userid"),
   
        
       };
       
   
       getapi
         .post("doctorprofile/", data)
         .then((res) => {
        // console.log('doctorprofile',res.data.data[0].BILLING_DETAILS)
           setDoctor(res.data.data[0]);
           
         })
         .catch((err) => {
          
         });
     };

useEffect(() =>{
  DoctorDetails()
},[])
    const LinkCheck = () =>{
      const bookingdetails = doctor.BillingDetails ? doctor.BillingDetails : doctor.BILLING_DETAILS ? doctor.BILLING_DETAILS : ''; 
      const profilenote = doctor.ProfileNote ? doctor.ProfileNote : doctor.PROFILE_NOTE ? doctor.PROFILE_NOTE : '';

      if (!bookingdetails && !profilenote )
      
        { 
          navigate("/appointment_reason")
        }
        else{
          navigate("/profile")
        }
      
    }


    

    useEffect(() => {
      const clearCookiesOnTabClose = () => {
        document.cookie = "APTReason=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        Cookies.remove('selectedSlotCount')             
                  
      };
    
      window.addEventListener('beforeunload', clearCookiesOnTabClose);
      window.addEventListener('unload', clearCookiesOnTabClose);
    
      return () => {
          window.removeEventListener('beforeunload', clearCookiesOnTabClose);
          window.removeEventListener('unload', clearCookiesOnTabClose);
      };
    }, [Apistate.location_id]); 


  return (
    <Fragment>
      <Container>
        <Row>
          <Col lg={12} className="left-content mx-auto">
            <Box className="h-100" component={Paper} elevation={2} p={2}>
              { <Breadcrumbs separator="<" aria-label="breadcrumb">
                        <Link
                          className="breadcrumb"
                          underline="hover"
                          key="1"
                          color="inherit"
                          onClick={() =>  navigate(-1)}
                        >
                          <ChevronLeftIcon sx={{ mr: 0.5 }} />
                          Back
                        </Link>
                      </Breadcrumbs>}
              <br />
              <Row>
                <Col lg={3}></Col>
                <Col lg={6}>
                  <div className="align-content-center">
                    <h3 className="top_button d-flex justify-content-center align-items-center">
                      Patient Alert
                    </h3>
                    <h4 className="d-flex justify-content-center smt align-items-center pat-title mt-3">
                      <b>Do you have ANY of these symptoms</b>
                    </h4>
                  </div>

                  <div className="p-2">
                    <ul className="list_symtom">
                      <li className="symtoms">
                        <span>Fever</span>
                      </li>
                      <li className="symtoms">
                        <span>Cough</span>
                      </li>
                      <li className="symtoms">
                        <span>Cold</span>
                      </li>
                      <li className="symtoms">
                        <span>Sore throat</span>
                      </li>
                      <li className="symtoms">
                        <span>Shortness of breath</span>
                      </li>
                      <li className="symtoms">
                        <span>Any flu-like symptoms</span>
                      </li>
                      <li className="symtoms">
                        <span>Runny nose</span>
                      </li>
                      {/* <li>
    <span>Have you visited any of the exposure sites listed by WA health</span>
</li>
<li>
    <span>Travelled overseas/Interstate in the last two weeks</span>
</li> */}
                    </ul>
                  </div>
                  <div className=" justify-content-center align-content-center align-content-center mb-5 mt--10">
                    <Row>
                      <Col xs={1} md={1}></Col>
                      <Col xs={5} md={5}>

                       
                        <Button
                          variant="primary"
                          className="buttons btn-blue mt--0"
                          onClick={() => navigate("/patient-alert-yes")}
                        >
                          <b>Yes</b>
                        </Button>
                      </Col>
                      <Col xs={5} md={5}>
                       
                        <Button
                          variant="primary"
                          className="buttons btn-blue mt--0"
                          //onClick={() => (window.location.href = item.ProfileLink)}
                          // onClick={() =>{ 
                          //   if( !doctor.BillingDetails || !doctor.BILLING_DETAILS && !doctor.ProfileNote || !doctor.PROFILE_NOTE)
                          //   { 
                          //     navigate("/appointment_reason")
                          //   }
                          //   else{
                          //     navigate("/profile")
                          //   }
                            
                          // }}

                          onClick={() =>{ 
                              
                              LinkCheck()
                             }}


                        >
                          <b>No</b>
                        </Button>
                      </Col>
                      <Col xs={1} md={1}></Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Box>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
