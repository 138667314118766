import { MobileData } from "../../../models/mobileapp";
import { UpdateMobileData, UPDATE_MOBILE_DATA,MRESET_DATA,MResetData } from "../actions";
export const initialState: MobileData = {
    UUID: '',
    FirstName:'',
    SurName:'',
    DOB:'',
    Gender:'',
    LoginFrom:'',
    location_id:'',
    location_name:'',
    phone_no:'',
    pid:'',
    user_firstname:'',
    user_surname:'',
    user_dob:'',
    user_gender:''
}

const mobileReducer = (state: MobileData = initialState, action: UpdateMobileData | MResetData):MobileData => {
    switch (action.type) {
        case UPDATE_MOBILE_DATA:
            return {

                ...state,
                [action.key]: action.value

            }
            case MRESET_DATA:
                return {
                    ...initialState,
                   // location_id: state.location_id, // Preserve location_id from the initial state
                   // login_from: state.login_from
                }
        default:
            return state
    }
}

export default mobileReducer