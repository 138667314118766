import React, { Fragment, useEffect, useRef, useState } from "react";
import {
    Breadcrumbs,
    Link,
    Typography,
     FormControl,
    Button,
    CircularProgress,
    OutlinedInput,
    FormHelperText
} from "@mui/material";

import { Image } from "react-bootstrap";
import './style.css'
import { Mobileheader } from "../../components/mobile_header";
import { useNavigate } from "react-router-dom";
import { getApi } from '../../services';
import { ApiState, MobileState as SearchReduxState, useSelector } from "../../redux";
import { useDispatch } from "react-redux";
import { UpdateMobileData } from "../../redux/MobileApp/actions";
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from "@mui/material/Alert";
const Login: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');
    const [error,seterror] = useState(false)
    const [submitting, setsubmitting] = useState(false);
    const searchRedux = useSelector<SearchReduxState>((state) => state.mobileReducer);
    const apistate = useSelector<ApiState>((state) => state.ApiReducer)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const getapi = getApi();

    const [open, setOpen] = React.useState(false);
    const[msg,setmsg] = React.useState('')
    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref
      ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });

     const handleClose =() =>{
        setOpen(false)
      }

    const handleMobileNumberChange = (event: any) => {
        seterror(false)
        const inputValue = event.target.value;
        // Regex to match only 11-digit numbers
        const regex = /^\d{0,10}$/; // Allows only digits, up to 11 characters
        if (regex.test(inputValue)) {
            setMobileNumber(inputValue);
        }
    };

const Submit =(event:any) =>{
    event.preventDefault();
    setsubmitting(true)
    if (!mobileNumber || !/^\d{10}$/.test(mobileNumber)) {
        seterror(true); 
        setsubmitting(false)
        return
      }
    let data ={
        
        "location_id" : apistate.location_id,
        "phone_no" : mobileNumber
    }
    console.log(data)
    
        getapi
          .post("registrationotp/",data)
          .then((response) => {
            dispatch(UpdateMobileData('phone_no',mobileNumber))
            dispatch(UpdateMobileData('LoginFrom','MobileApp'))
            localStorage.setItem('phone_no', mobileNumber);
            console.log("response data",response);
            setOpen(true)
            setmsg(response.data.Message)
            if(response.data.Success === 'True'){
              
                  setTimeout(() => {
                    navigate('/loginotp')
                  }, 3000);
                  
            }
            setsubmitting(false)
          })
          .catch((error) => {
            console.log(error);
            setsubmitting(false)
          });
      
}

    return (
        <Fragment>
            {loading ?
                <div className="d-flex align-items-center loader justify-content-center">
                    <CircularProgress />
                </div>
                :
                <div className="loginpage">
                    <div className="blue_bg">
                        <Mobileheader />
                        <div className="login_form text-center align-item-center p-4 bg-white">
                          {apistate.location_id === 'S25880ANA' ?
                            <Image className="dialpad mt-5" src={require("../../assets/images/mobile/pink/dialpad1.png")} />
                            :
                            <Image className="dialpad mt-5" src={require("../../assets/images/mobile/dialpad1.png")} />
                             }

                            <Typography className="fw-bold pt-4 pb-4 fs-3">Verification</Typography>
                            <Typography className="smtext mb-4  fs-5">We will send you a <b>One-Time Password</b> on your mobile number.</Typography>
                            <form noValidate autoComplete="off">
                                <FormControl 
                                error={error}
                                fullWidth>
                                    <OutlinedInput
                                    sx={{
                                        borderColor:'#989898',
                                        borderRadius: '10px',
                                        textAlign: 'center', // Center aligns the entered text
                                        '& input::placeholder': {
                                          textAlign: 'center', // Center aligns the placeholder text
                                          color:'#989898',
                                          fontWeight:500
                                        },
                                      }}
                                    className="mobile_inp"
                                        placeholder="Enter Mobile Number"
                                        value={mobileNumber}
                                        onChange={handleMobileNumberChange}
                                        inputProps={{
                                            maxLength: 11, // Maximum length of the input
                                            pattern: '[0-9]*', // Pattern to allow only numeric input
                                            inputMode: 'numeric', // Specifies the input type as numeric
                                        }}
                                    />
                                 {error &&   <FormHelperText>Enter a valid 10-digit mobile number</FormHelperText> }
                                </FormControl>

                                <Button 
                                sx={{
                                    borderRadius: '10px',
                                   
                                }}
                                onClick={Submit}
                                className="mvbutton mt-5 btn-blue"
                                type='submit'
                                variant="contained"                               
                                fullWidth
                                >
                                  {submitting ? <CircularProgress sx={{color:"#FFF"}} size={32}/> : 'Get OTP' }  
                                </Button>
                            </form>
                        </div>
                    </div>

                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity='success'
              sx={{ width: "100%" }}
            >
              {msg}
            </Alert>
          </Snackbar>

                </div>
            }
        </Fragment>
    )
}
export default  Login;
