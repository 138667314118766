
export const UPDATE_MOBILE_DATA = 'update_mobile_data';
export const MRESET_DATA = 'mreset_data'

export interface UpdateMobileData {
    type: typeof UPDATE_MOBILE_DATA,
    key: string,
    value: any
}

export interface MResetData {
    type: typeof MRESET_DATA 
}

// eslint-disable-next-line
export const UpdateMobileData = (key: string, value: any):UpdateMobileData => ({
    type: UPDATE_MOBILE_DATA,
    key,
    value
})

export const MResetdata = () : MResetData =>({
    type:MRESET_DATA
})