
export const UPDATE_PAYMENT_DATA = 'update_payment_data';
export const RESET_DATA = 'reset_data'

export interface UpdatePaymentData {
    type: typeof UPDATE_PAYMENT_DATA,
    key: string,
    value: any
}

export interface ResetData {
    type: typeof RESET_DATA 
}

// eslint-disable-next-line
export const UpdatePaymentData = (key: string, value: any):UpdatePaymentData => ({
    type: UPDATE_PAYMENT_DATA,
    key,
    value
})

export const Resetdata = () : ResetData =>({
    type:RESET_DATA
})