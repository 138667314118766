
export const UPDATE_API_DATA = 'update_api_data';
export const ARESET_DATA = 'areset_data'

export interface UpdateApiData {
    type: typeof UPDATE_API_DATA,
    key: string,
    value: any
}

export interface AResetData {
    type: typeof ARESET_DATA 
}

// eslint-disable-next-line
export const UpdateApiData = (key: string, value: any):UpdateApiData => ({
    type: UPDATE_API_DATA,
    key,
    value
})

export const AResetData = () : AResetData =>({
    type:ARESET_DATA
})