import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { getApi } from "../../services";
import { ApiState, PaymentState as SearchReduxState, useSelector } from "../../redux";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
export const OTPscreen: React.FC = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const getapi = getApi();
  const [seconds, setSeconds] = useState(12);
  const [minutes, setMinutes] = useState(0);
  // Redux
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [open, setOpen] = React.useState(false);
  const [errorotp, seterrorotp] = React.useState(false);
  const [errorotp2, seterrorotp2] = React.useState(false);
  const [submitting, setsubmitting] = React.useState(false);
  const [errorotp1, seterrorotp1] = React.useState(false);
  const [errormsg, seterrormsg] = React.useState('');
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const Apistate = useSelector<ApiState>(
    (state) => state.ApiReducer
  );
  
  const [error, seterror] = useState(false);
  const Verifyotp = () => {
    setsubmitting(true);
    if (otp.trim() === "") {
      seterror(true);
      setsubmitting(false);
      return;
    }
    let data = {
      location_id: Apistate.location_id,
      id: Apistate.uniqueid,
      otp: otp,
    };
    

    getapi
      .post("otpverification/", data)
      .then((res) => {
        setsubmitting(false);
       
        if (res.data.Success && res.data.Status == 200) {
          navigate("/success");
        }
        else{
          seterrorotp2(true)
          seterrormsg(res.data.Message)
  seterrorotp1(true)
        }
      })
      .catch((err) => {
        setsubmitting(false);
   
      if(err.message.includes("404")){
        seterrorotp2(true)
      }
      if(err.message.includes("400")){
        seterrorotp1(true)
      }

      });
  };

  const resendotp = () => {
    setOtp('')
    seterror(false);
    seterrorotp2(false)
    let data = {
      location_id: Apistate.location_id,
      id: Apistate.uniqueid,
    };
    

    getapi
      .post("resendotp/", data)
      .then((res) => {
       
        if (res.data.Success && res.data.Status == 200) {
          handleClick();
          setMinutes(0);
          setSeconds(12);
        }
        else {

  seterrormsg(res.data.Message)
  seterrorotp1(true)
        }
      })
      .catch((err) => {
      
        seterrorotp(true);
      });

    setMinutes(0);
    setSeconds(12);
  };
  return (
    <main className="otpscreen">
      <Container>
        <Row>
          <Col md={12} className="align-self-center mx-auto">
            <Box className="h-100" component={Paper} elevation={2} p={2}>
              <Row>
                <Breadcrumbs separator="<" aria-label="breadcrumb">
                  <Link
                    className="breadcrumb"
                    underline="hover"
                    key="1"
                    color="inherit"
                    onClick={() => navigate(-1)}
                  >
                    <ChevronLeftIcon sx={{ mr: 0.5 }} />
                    Back
                  </Link>
                </Breadcrumbs>
                <br />
                <Col md={2}></Col>
                <Col md={8} className="mb-5">
                  <div className="mid_cnt justify-content-center mt-5">
                    <div className="text-center justify-content-center big-bar2 drak-blue-btn d-flex">
                      <Typography>Enter OTP</Typography>
                    </div>

                    <Typography className="text-center peotp pt-3 pb-4">
                      Please enter the one time password which you received on
                      your mobile{" "}
                    </Typography>
                  </div>
                  <div className="d-flex large_view justify-content-center align-items-center">
                    <OtpInput
                      value={otp}
                      inputStyle={{
                        width: "3rem",
                        height: "3rem",
                        margin: 10,
                        fontSize: "25px",
                        borderRadius: 4,
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: error || errorotp2 ? "#DC3545" : "#B0B0B0",
                      }}
                      onChange={(e) => {
                        setOtp(e);
                        seterror(false);
                        seterrorotp2(false)
                      }}
                      numInputs={5}
                      //renderSeparator={<span>-</span>}
                      renderInput={(props) => <input {...props} />}
                    />
                   </div>
                   <div className="d_view">
                  {error && (
                    <p className="text-center text-danger">
                      This field is required
                    </p>
                  )}
                   {errorotp2 && (
                      <p className="text-center large_view_e text-danger">
                        Invalid OTP
                      </p>
                    )}
                   </div>
                  <div className="small_view">
                    <div className="d-flex  m-auto justify-content-center align-items-center">
                      <OtpInput
                        value={otp}
                        inputStyle={{
                          width: 40,
                          height: 40,
                          margin: 4,
                          fontSize: "20px",
                          borderRadius: 4,
                          borderWidth: 1,
                          borderStyle: "solid",
                          borderColor: error || errorotp2 ? "#DC3545" : "#B0B0B0",
                        }}
                        onChange={(e) => {
                          setOtp(e);
                          seterror(false);
                          seterrorotp2(false)
                        }}
                        numInputs={5}
                        //renderSeparator={<span>-</span>}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                    {error && (
                      <p className="text-center text-danger">
                        This field is required
                      </p>
                    )}
                         {errorotp2 && (
                      <p className="text-center small_view_e text-danger">
                        Invalid OTP
                      </p>
                    )}

                  </div>
                  <br />

                  {seconds > 0 || minutes > 0 ? (
                    <Typography className="text-center mt-5">
                      Resend OTP in {seconds} sec
                    </Typography>
                  ) : (
                    <div className="book-apt-btn d-flex justify-content-center">
                    <Button
                    variant='outline-primary'
                    className="re_otp text-center"
                    onClick={() => resendotp()}
                   
                    >
                  
                      Resend OTP
                   
                    </Button>
                    </div>
                  )}
                  <div className="book-apt-btn d-flex justify-content-center">
                    <Button
                      disabled={submitting}
                      className="book_appbtn2"
                      onClick={Verifyotp}
                      type="submit"
                      id="submit"
                    >
                      {submitting ? (
                        <CircularProgress size={20} style={{ color: "#FFF" }} />
                      ) : (
                        "Verify OTP"
                      )}
                    </Button>
                  </div>

                  <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                  >
                    <Alert
                      onClose={handleClose}
                      severity="success"
                      sx={{ width: "100%" }}
                    >
                      OTP Reset Successfully!
                    </Alert>
                  </Snackbar>
                  <Snackbar
                    open={errorotp}
                    autoHideDuration={3000}
                    onClose={() =>seterrorotp(false)}
                  >
                    <Alert
                      onClose={() =>seterrorotp(false)}
                      severity="warning"
                      style={{
                        backgroundColor: "#ff9966",
                      }}
                      sx={{ width: "100%" }}
                    >
                      something went wrong.Try again
                    </Alert>
                  </Snackbar>

                  <Snackbar
                    open={errorotp1}
                    autoHideDuration={3000}
                    onClose={() =>seterrorotp1(false)}
                  >
                    <Alert
                      onClose={() =>seterrorotp1(false)}
                      severity="warning"
                      style={{
                        backgroundColor: "#ff9966",
                      }}
                      sx={{ width: "100%" }}
                    >

  {errormsg}
                    </Alert>
                  </Snackbar>

                </Col>
              </Row>
            </Box>
          </Col>
        </Row>
      </Container>
    </main>
  );
};
