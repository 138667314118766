import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import { PaymentState as SearchReduxState, themeReducer, useSelector,ApiState } from "../redux"
import { useDispatch } from 'react-redux';
import { UpdatePaymentData } from '../redux/User/actions';
import { toggleTheme, setThemeColor } from '../redux/theme/action/themeActions';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, useMediaQuery } from '@mui/material';
import theme from '../theme';
import { MobileState as MReduxState } from "../redux";
import Cookies from 'js-cookie';
import { Nav } from 'react-bootstrap';
import { getApi } from "../services";
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import InfoIcon from '@mui/icons-material/Info';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {  useLocation  } from "react-router-dom";
export const MHeader: React.FC = () => 

{
   // Redux 
   const searchRedux = useSelector<SearchReduxState>(state => state.paymentReducer)
   const themeRedux = useSelector<themeReducer>(
    (state) => state.themeReducer
  );
  const location_path= useLocation();
  const searchRedux2 = useSelector<MReduxState>((state) => state.mobileReducer);
  const apistate = useSelector<ApiState>((state) => state.ApiReducer)
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const location = Cookies.get('Location_Name')
  const phone_no = Cookies.get('Phone_no')
  const navigate = useNavigate();
  const getapi = getApi();
  const [address,setaddress] = useState('')
  const [phoneno,setphoneno] = useState('')
  const [Webdata, setWebdata] = useState<any>([]);
  const homepage = () => {

    
   // navigate("/?"+paramVal);
   if(apistate.login_from[1] === 'book'){

    const paramVal = btoa(apistate.location_id+'_'+localStorage.getItem('userid'))
    navigate("/book?"+paramVal)
   }
   else if(searchRedux2.LoginFrom === "MobileApp"){
    navigate("/patientslist")
   }
   else{
    navigate("/"+apistate.login_from)
   }
  

};
useEffect(() =>{
  getData()
},[])
const getData = async () => {
  

  try {
      let data = {
          location_id: apistate.location_id,
      };

      const res = await getapi.post("getpracticeinfo/", data);
    
       //console.log('practice details =', res.data.data[0].Webdata[0].PHONENUMBER);
       setWebdata(res.data.data[0].Webdata);
       setphoneno(res.data.data[0].Webdata[0].PHONENUMBER)
      // setData(res.data.data);
  } catch (err) {
      // Handle error if needed
  }
}

const handleCall = () => {
  window.location.href = `tel:${phoneno.replace(/[\s()-]/g, '')}`;
};

 
return(
    <>
    {!matches ?
  <Navbar className={"nav-wrapper " +themeRedux.themeColor+ " header fixed-top cyan darken-1 px1"}>
  <Container>
    <Navbar.Brand style={{
      color:"#fff",
      cursor:'pointer'
    }}onClick={()=>homepage()}>
{apistate.location_id === 'S25880ANA' ?
      <img
        alt=""
        src={require("../assets/images/Pinklogo1.png")}
        className="d-inline-flex align-left pink_header-logo"
      />
:
<img
alt=""
src={require("../assets/images/practicelogo.png")}
className="d-inline-flex align-left header-logo"
/>
}
      {' '}
    {apistate.location_id != 'S25880ANA' &&
    // <span className='LocName'>CODD <b style={{color:"#00B8E9"}}>Appointments</b></span> 
    <span className='LocName'>{searchRedux.Location_Name ? searchRedux.Location_Name : location }</span>
 
    }
    </Navbar.Brand>

    
    
        <Nav>
        
            <Nav.Item>
            <Typography className= {location_path.pathname == '/practice-information' ? 'adds_div prinfo' :'adds_div'}>
         
          
           <PhoneInTalkIcon style={{ marginLeft:15 }}/> {phone_no}
            </Typography>
            </Nav.Item>

           {location_path.pathname != '/practice-information' &&
            <Nav.Item>
            <Button 
            variant='contained' 
            className='buttons m_info_b btn-blue'
            onClick={() => navigate("/practice-information")}
            >
        More info <DoubleArrowIcon/>
      
      </Button>
      </Nav.Item>
}
          </Nav> 
    
 
  </Container>
</Navbar>
:
<Navbar className={"nav-wrapper " +themeRedux.themeColor+ " header mdfixed-top cyan darken-1 px1"}>
<Container>
  <Navbar.Brand style={{
    color:"#fff",
    cursor:'pointer'
  }}onClick={()=>homepage()}>
{apistate.location_id === 'S25880ANA' ?
    <img
      alt=""
      src={require("../assets/images/Pinklogo1.png")}
      className="d-inline-flex align-left pink_header-logo"
    />
:
<img
alt=""
src={require("../assets/images/mobile/small_logo.png")}
className="d-inline-flex align-left header-logo"
/>
}
    {' '}
  {apistate.location_id != 'S25880ANA' &&          
  <span className='LocName'>{searchRedux.Location_Name ? searchRedux.Location_Name : location }</span> 
  }
  </Navbar.Brand>
   <Nav className='text-right justify-content-end'>
  <Nav.Link eventKey={2} >
  <PhoneInTalkIcon onClick ={handleCall} style={{ color:'#fff',cursor:'pointer' }}/>
  </Nav.Link>
  {location_path.pathname != '/practice-information' &&
  <Nav.Link eventKey={2} >
  <InfoIcon onClick={() => navigate("/practice-information")} style={{ color: apistate.location_id === 'S25880ANA' ? '#fff' :'#00B8E9',cursor:'pointer' }}/>
  </Nav.Link>
}
  </Nav> 
</Container>
</Navbar>
}
</>
  )
      }