import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './responsive.css' 
import App from './App';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { Provider } from 'react-redux';
import reduxStore from './redux/store';
import { CircularProgress } from '@mui/material';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';

export const store = reduxStore()
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

  // <React.StrictMode>
    
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <Provider store={store.store}>
      <PersistGate loading={ <CircularProgress />} persistor={store.persistor}>
      <BrowserRouter>
     
      <App />
      
      </BrowserRouter>
      </PersistGate>
    </Provider>
    </ThemeProvider> 
    
  // </React.StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
