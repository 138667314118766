import React, { Fragment, useState } from 'react'

import { Col, Container, Row, Image } from "react-bootstrap";
import { Modal,Fade,Box, Button,FormControl,FormControlLabel,FormHelperText,FormLabel,OutlinedInput,Paper, Radio, RadioGroup, TextField, Typography, Backdrop } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { getApi } from '../../services';
import { ApiState, MobileState as SearchReduxState, useSelector } from "../../redux";
import { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'; 
import moment from 'moment';
import { themeReducer } from '../../redux'
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 1,
};
export const AddPatient: React.FC = () => {
 
  const themeRedux = useSelector<themeReducer>(
    (state) => state.themeReducer
  );
    const [firstname, setFirstname] = useState('');
    const [surname, setSurname] = useState('');
    const [dob, setDOB] = React.useState(null);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    
    const [firstnameError, setFirstnameError] = useState(false);
    const [surnameError, setSurnameError] = useState(false);
    const [dobError, setDOBError] = useState(false);
    const [gError, setgError] = useState(false);
     const [submitting,setsubmitting]= useState(false)

    const [success,setsuccess] = useState(false)
    const navigate = useNavigate()
    const getapi = getApi();
    const searchRedux = useSelector<SearchReduxState>((state) => state.mobileReducer);
    const [gender, setGender] = React.useState('');
    const apistate = useSelector<ApiState>((state) => state.ApiReducer)
  const genderhandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGender((event.target as HTMLInputElement).value);
    setgError(false)
  };

  useEffect(() =>{
    //console.log('add page redux',searchRedux)
  },[searchRedux])

    const validateInputs = () => {
        let isValid = true;
    
        if (firstname.trim() === '') {
          setFirstnameError(true);
          isValid = false;
        } else {
          setFirstnameError(false);
        }
    
        
        if (surname.trim() === '') {
          setSurnameError(true);
          isValid = false;
        } else {
          setSurnameError(false);
        }
    
        // Add validation logic for date of birth
        if (!dob) {
          setDOBError(true);
          isValid = false;
          //console.log'Date of birth cannot be empty.');
        } else {
          // Handle submission with the valid date
          //console.log'Submitting with date:', dob);
        }
      

        // if (dob === null) {
        //   setDOBError(true);
        //   isValid = false;
        // } else {
        //   setDOBError(false);
        // }

        if (!gender) {
          setgError(true);
          isValid = false;
        } else {
          setgError(false);
        }
        

        return isValid;
      };
    
      const handleDateChange = (e: any) => {
        if (e) {
          setDOB(e);
          setDOBError(false);
        } else {
        
          setDOBError(true);
        }
        //console.log'dob', e);
      };


      const validateName = (name: string): boolean => {
        // Regular expression pattern to allow only alphabetic characters
       // const namePattern = /^[a-zA-Z]*$/;
       const namePattern = /^[a-zA-Z\s]*$/;
       
        return namePattern.test(name);
      };
    
      const handleFirstnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
       
        if (validateName(value) || value.trim() === '') {
          
          setFirstname(value);
          setFirstnameError(false);
        } else {
         // setFirstnameError(true);
        }
      };
    
      const handleSurnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (validateName(value) || value === '') {
          setSurname(value);
          setSurnameError(false);
        } else {
        //  setSurnameError(true);
        }
      };

      


      const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setsubmitting(true)
        if (validateInputs()) {
          let data = {
            firstname: firstname,
            surname: surname,
            dob: moment(dob).format('YYYY-MM-DD'),
            mobile: searchRedux.phone_no ? searchRedux.phone_no :localStorage.getItem('phone_no'),
            udid: searchRedux.UUID,
            gender: gender == 'male' ? '2' : gender == 'female' ? '1' : gender == 'other' ? '0' : gender == 'unknown' ? '3':'' , // Using the 'gender' state here
            location_id: apistate.location_id ? apistate.location_id :localStorage.getItem('location_id')
          };
      //console.log'submit data',data)
      
          getapi
            .post("instentryhistory/", data)
            .then((response) => {
              //console.log"response patients data", response);
              setsubmitting(false)
              if (response.data.Success) {
               // setsuccess(true)
                handleOpen()
                setTimeout(() => {
                  navigate('/patientslist');
                }, 6000);
                
              } else {
                setsubmitting(false)
              }
            })
            .catch((error) => {
              //console.logerror);
              setsubmitting(false)
            });
        } else {
          setsubmitting(false)
          // Form validation failed
          //console.log'Form validation failed');
        }
      };


      const resetForm = () => {
        setFirstname('');
        setSurname('');
        setDOB(null);
        setFirstnameError(false);
        setSurnameError(false);
        setDOBError(false);
        setgError(false)
        navigate('/patientslist')
      };

 // Get today's date in the format YYYY-MM-DD to set the max attribute
 const today = new Date().toISOString().split('T')[0];
    return (
        <Fragment>
       <LocalizationProvider dateAdapter={AdapterMoment}>  
            <div className="addpatientslist mt-2 float-left w-100">

            <Box>
                    <Row>
                        <Col>
                        {!open &&
                        <Typography className='plisttext'>Provide your details</Typography>
                        }
                        </Col>
                        {/* <Col className='d-flex justify-content-end'>
                            <div className='text-right d-flex justify-content-right align-item-right'>
                           <Button
                           onClick={()=>setsuccess(false)}
                           style={{ 
                            textTransform: 'capitalize',
                            fontSize:12,
                            fontWeight:600
                        }}
                           variant='contained'
                           color='secondary'
                           > 
                            Add Member
                           </Button>
                           </div>
                        </Col> */}

                    </Row>
                </Box>

{!success ?
       
       <form onSubmit={handleSubmit}>
       <>
             <Box className="mt-4 w-100" component={Paper} elevation={2} p={2}>
              
      <div  className='inputs-block mt-3'>
        <FormControl fullWidth error={firstnameError}>
          <FormLabel className="edit-form-label">First Name</FormLabel>
          <OutlinedInput
          type='text'
          
           sx={{
            borderColor:'#989898',
            borderRadius: '10px',
            textAlign: 'left', // Center aligns the entered text
            '& input::placeholder': {
              textAlign: 'left', // Center aligns the placeholder text
              color:'#989898',
              fontWeight:500
            },
          }}
          inputProps={{
            minLength: 3,  // Set the minimum character length
            maxLength: 30
           }}
        placeholder='(Name as per medicare card)'
            error={firstnameError}
            value={firstname}
            fullWidth
            onChange={handleFirstnameChange}
            className="mt-1 sinput-border"
          />
          {firstnameError && <FormHelperText>This field is required</FormHelperText>}
        </FormControl>
      </div>
      {/* Add similar blocks for Surname and Date of Birth */}
      
       <div className='inputs-block  mt-3'>
        <FormControl fullWidth error={surnameError}>
          <FormLabel className="edit-form-label">Surname</FormLabel>
          <OutlinedInput
          placeholder='(Name as per medicare card)'
          inputProps={{
            minLength: 3,  // Set the minimum character length
            maxLength: 30
           }}
            sx={{
                borderColor:'#989898',
                borderRadius: '10px',
                textAlign: 'left', // Center aligns the entered text
                '& input::placeholder': {
                  textAlign: 'left', // Center aligns the placeholder text
                  color:'#989898',
                  fontWeight:500
                },
              }}
            error={surnameError}
            value={surname}
            fullWidth
            onChange={handleSurnameChange}
            className="mt-1 sinput-border"
          />
          {surnameError && <FormHelperText>This field is required</FormHelperText>}
        </FormControl>
      </div> 
      {/* for Date of Birth */}

      <div className='inputs-block  mt-3 mb-2'>
      <FormControl id='bobd' fullWidth error={dobError}>
          <FormLabel className="edit-form-label">Date of Birth</FormLabel>
      <DatePicker 
       className='Mui-error'
 
      disableFuture
      closeOnSelect={true}
      format="DD/MM/YYYY"
       value={dob}
     // maxDate={moment()}
      //  onChange={(e:any) => {
      //    setDOB(e); 
      //    setDOBError(false);
      //    //console.log'dob',e)
      //  }}
      onChange={(e: any) => handleDateChange(e)}
       sx={{
        borderColor:'#989898',
        borderRadius: '10px',
        textAlign: 'left', // Center aligns the entered text
        '& input::placeholder': {
          textAlign: 'left', // Center aligns the placeholder text
          color:'#989898',
          fontWeight:500
        },
      }}
       />
      </FormControl>
{dobError && 
  <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1hn9ihz-MuiFormHelperText-root">This field is required</p>
  
  }
        {/* <FormControl fullWidth error={dobError}>
          <FormLabel className="edit-form-label">Date of Birth</FormLabel>
          <OutlinedInput
          id='datein'
            sx={{
                borderColor:'#989898',
                borderRadius: '10px',
                textAlign: 'left', // Center aligns the entered text
                '& input::placeholder': {
                  textAlign: 'left', // Center aligns the placeholder text
                  color:'#989898',
                  fontWeight:500
                },
              }}
              inputProps={{ 
                max: today,
                style: { color: '#000000' }, // Text color
                placeholderstyle: { color: '#d9d9d9' }, 
            }}
              placeholder='[DD/MM/YYYY]'
            error={dobError}
            type='date'
            value={dob}
            fullWidth
            onChange={(e) => {
              setDOB(e.target.value); 
              setDOBError(false);
            }}
            className="mt-1 sinput-border"
          />
          {dobError && <FormHelperText>This field is required</FormHelperText>}
        </FormControl> */}
      </div> 

  
      <div className="ml-1 mb-3 mgenter_class">
                   

                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="gender"
                            onChange={genderhandleChange}
                          >
                            <FormControlLabel
                             
                              value="male"
                              control={
                                <Radio
                                  sx={{
                                    "&.Mui-checked": {
                                      color: "#388FA2",
                                    },
                                  }}
                                />
                              }
                              label="Male"
                            />
                            <FormControlLabel
                              value="female"
                              control={
                                <Radio
                                  sx={{
                                    "&.Mui-checked": {
                                      color: "#388FA2",
                                    },
                                  }}
                                />
                              }
                              label="Female"
                            />
                            <FormControlLabel
                              value="other"
                              control={
                                <Radio
                                  sx={{
                                    "&.Mui-checked": {
                                      color: "#388FA2",
                                    },
                                  }}
                                />
                              }
                              label="Other"
                            />
                            <FormControlLabel
                              value="unknown"
                              control={
                                <Radio
                                  sx={{
                                    "&.Mui-checked": {
                                      color: "#388FA2",
                                    },
                                  }}
                                />
                              }
                              label="Unknown"
                            />
                          </RadioGroup>
                       {gError &&  <FormHelperText className='Mui-error'>This field is required</FormHelperText> }
                        </FormControl>

                         {/* <p className="error">This field is required </p>  */}
                      </div>
                      </Box>
      <Row className='mb-3'>
        

        <Col className='d-flex mt-3 mb-3 justify-content-center'>
        <Button 
        type='submit'
     
        className='bbtn btn-primary'
       fullWidth
       color='secondary'
       
                    variant='contained'
                    sx={{
                      color:"#fff",
                      textTransform: 'capitalize',
                      fontWeight: 600,
                      borderRadius:25,
                      fontSize: 19
                    }}
    
        >
       {submitting ? <CircularProgress sx={{color:"#FFF"}} size={32}/> :  ' Save Member'}
      </Button>

        </Col>
        <Col md={12}>
                <Button
                 
                    className='cbtn otnbtn-primary'
                    fullWidth
                    variant='outlined'
                    color='secondary'
                    onClick={resetForm}
                    sx={{
                      borderRadius:25,
                      textTransform: 'capitalize',
                      fontWeight: 600,
                      fontSize: 19
                    }}
                  >
                    Cancel
                  </Button>

                </Col>
      </Row>
    </>
    </form>
             
            // </Box>
:
           
          <Box className="h-100 mt-4 w-100" sx={{minHeight:'77vh'}} component={Paper} elevation={2} p={1}>
             {/* <div className='blueheader btn-blue'>
                <Row className='in_bluerow'>
                    <Col className='d-flex justify-content-center'>
                    <p>Provide your details</p>
                    </Col>
                    
                </Row>
                
             </div> */}


             <div className='align-middle  mt-5 no_app justify-content-center text-center'>
             {apistate.location_id === 'S25880ANA' ?
                <Image src={require("../../assets/images/mobile/pink/check.png")}/>
                :
                <Image src={require("../../assets/images/mobile/check.png")}/>
                  }
                <p className='p-3 no-text'>Member added successfully.</p>
                </div>

                <Box>
               
      

  
     
      <Row className='mb-3'>
        
        <Col xs={3}></Col>
        <Col className='d-flex justify-content-center'>
        <Button 
        onClick={() => navigate('/patientslist')}
        type='submit'
        className='bbtn btn-blue'
       fullWidth
        variant='contained'
        sx={{
          
            textTransform:'capitalize',
            fontWeight:600,
            fontSize:16
        }}
    
        >
        Close
      </Button>

        </Col>
        <Col xs={3}></Col>
      </Row>
     
    
                </Box>
            </Box> 
}

{/* update success modal */}
<Modal
      id={themeRedux.themeColor}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box  sx={style}>
          <div className='align-middle  mt-5 no_app justify-content-center text-center'>
             {apistate.location_id === 'S25880ANA' ?
                <Image src={require("../../assets/images/mobile/pink/check.png")}/>
                :
                <Image src={require("../../assets/images/mobile/check.png")}/>
                  }
                <p className='p-3 no-text'>Member added successfully.</p>
                </div>
          </Box>
        </Fade>
      </Modal>


            </div>
            </LocalizationProvider>
            </Fragment>
    )
}