export const TOGGLE_THEME = 'TOGGLE_THEME';
export const SET_THEME_COLOR = 'SET_THEME_COLOR';

export const toggleTheme = () => ({
  type: TOGGLE_THEME,
});

export const setThemeColor = (color: string) => ({
  type: SET_THEME_COLOR,
  payload: color,
});