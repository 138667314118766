import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Image } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { ApiState, PaymentState as SearchReduxState, themeReducer, useSelector } from "../../redux";

import Cookies from "js-cookie";
import moment from "moment";
import { getApi } from "../../services";


export const OTPVarifiedScreen: React.FC = () => {

  const getapi = getApi();
  
  const navigate = useNavigate();
  // Redux
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );
  const themeRedux = useSelector<themeReducer>(
    (state) => state.themeReducer
  );
  const Apistate = useSelector<ApiState>(
    (state) => state.ApiReducer
  );
  const convertSecondsToHMS = (seconds: any) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    // Convert to 12-hour format

    let hours12 = hours % 12 || 12;
    hours12 = hours12 === 0 ? 12 : hours12; // Handle midnight (12 AM)

    const amPm = hours < 12 ? "AM" : "PM";

    return `${hours12.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${amPm.toString().padStart(2, "0")}`;
  };


  const homepage = () => {
    Cookies.remove('selectedSlotCount')
    Cookies.remove('selecteddate')
    const paramVal = btoa(Apistate.location_id+'_'+Cookies.get('userid'))

   if(Apistate.login_from[1] === 'book'){
    navigate("/book?"+paramVal)
   }
   else{
    navigate("/"+Apistate.login_from)
   }
  

};
const [doctor,setDoctor] = useState<any>([])
const [aptreason,setAptreason] = useState<any>([])
const selectedDateFromApi = Apistate.selecteddate;
const selectedDateFromLocalStorage = Cookies.get("selecteddate");

const selectedDate = selectedDateFromApi ?? selectedDateFromLocalStorage;

// Using optional chaining to safely access properties
const formattedDate = selectedDate
  ? moment(selectedDate)?.format("DD MMMM YYYY")
  : "Default Value";
useEffect(() =>{
  // Retrieve the cookie value
  var cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)DoctorDetails\s*=\s*([^;]*).*$)|^.*$/, '$1');
  
  // If the cookie value exists, decode it and parse the JSON string back to an array
  if (cookieValue) {
    var retrievedArray = JSON.parse(decodeURIComponent(cookieValue));
    //console.log('DoctorDetails',retrievedArray);
    setDoctor(retrievedArray)
  } else {

  }

  /* get the aptreason to cookies */
  var cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)aptreasonbyuser\s*=\s*([^;]*).*$)|^.*$/, '$1');
  
  // If the cookie value exists, decode it and parse the JSON string back to an array
  if (cookieValue) {
    var retrievedArray = JSON.parse(decodeURIComponent(cookieValue));
    
    setAptreason(retrievedArray)
  } else {
   
  }

  

  },[])

const [BTN_1_URL,setBTN_1_URL] = useState('')
const [BTN_1,setBTN_1] = useState('')
const [loading, setLoading] = useState(false);
  const practicedetails = async () => {
    setLoading(true)
  
    try {
      let data = {
        location_id: Apistate.location_id,
      };
  
      const res = await getapi.post("practicedetails/", data);
  console.log('practicedetails',res)
  

  if (res.data.Success) {
    setBTN_1_URL(res.data.data[0].BTN_1_URL);
    setBTN_1(res.data.data[0].BTN_1)
  setLoading(false)
  }

    } catch (err) {
      // Handle the error
      console.log("error", err);
      setLoading(false)
    }
  };

  useEffect(() =>{
    practicedetails()
  },[])

  // const BTN_1 = searchRedux.BTN_1 ? searchRedux.BTN_1 : Cookies.get('BTN_1')
  // let BTN_1_URL = searchRedux.BTN_1_URL ? searchRedux.BTN_1_URL : Cookies.get('BTN_1_URL')
  return (
    <main className="OTPVarifiedScreen">

{loading ? (
        <div className="d-flex align-items-center loader justify-content-center">
          <CircularProgress />
        </div>
      ) : (

      <Container>
        <Row>
          <Col md={12} className="align-self-center mx-auto">
            <Box
              className="h-100 text-center align-center justify-content-center"
              component={Paper}
              elevation={2}
              p={3}
            >
              <Row>
                <Col md={2}></Col>
                <Col md={8}>
                  {themeRedux.themeColor != 'pink' ?
                    <Image
                      rounded
                      className="d-inline-flex align-left object-fit-cover align-middle mb-3 success-icon"
                      // src={item.image}
                      src={require("../../assets/images/badge1.png")}
                      roundedCircle
                    />
                    :
                    <Image
                      rounded
                      className="d-inline-flex align-left object-fit-cover align-middle mb-3 success-icon"
                      // src={item.image}
                      src={require("../../assets/images/checked.png")}
                      roundedCircle
                    />
                  }
                  <Typography className="text-center peotp pt-3 pb-1 mb-2">
                    You have successfully booked appointment with
                  </Typography>
                  <Typography className="text-center peotp1  pb-4">
                  {doctor.ISDOCTOR} {doctor.UserName ? doctor.UserName :doctor.USERNAME} on{" "}
                    {moment(Apistate.selecteddate ? Apistate.selecteddate  : selectedDateFromLocalStorage).format("DD MMMM YYYY")} |{" "}
                    {convertSecondsToHMS(Apistate.selectedslot)}
                  </Typography>
                  {BTN_1_URL ? 
<> 
                  <Typography className="text-center peotp pt-3 pb-1 mt-3">
                    We would love to hear your feedback. Please take a moment to
                    review us on google through the link below. Thank you for
                    your continued support!
                  </Typography>


                  <div className="book-apt-btn d-flex justify-content-center">
                    <Button
                      className="book_appbtn3"
                      onClick={() =>
                     {  
                      window.location.href = BTN_1_URL;
                      //  const storedBTN_1_URL = Cookies.get('BTN_1_URL');

                      //   if (typeof storedBTN_1_URL === 'string' && storedBTN_1_URL.trim() !== '') {
                      //     window.location.href = storedBTN_1_URL;
                      //   } else {
                        
                      //   }
                     
                     }
                      }
                      type="submit"
                      id="submit"
                    >
                      Leave your feedback
                    </Button>
                  </div>
                  <div className="book-apt-btn d-flex justify-content-center">
                    <Button
                      className="book_appbtn3 mb-5"
                      onClick={() => homepage()}
                      type="submit"
                      id="submit"
                    >
                      No Thanks
                    </Button>
                  </div>
                  </>
                       :            
                        <div className="book-apt-btn d-flex justify-content-center">
                                    <Button
                                      className="book_appbtn3 mb-5"
                                      onClick={() => homepage()}
                                      type="submit"
                                      id="submit"
                                    >
                                      Home
                                    </Button>
                                  </div>
                }

                </Col>
              </Row>
            </Box>
          </Col>
        </Row>
      </Container>
      )
              }
    </main>
  );
};
