import React, { useEffect, useState } from "react";
import {
  Breadcrumbs,
  Link,
  Typography,
  Divider,
  CircularProgress,
  TextField,
} from "@mui/material";
import { Col, Container, Row, Image, Button } from "react-bootstrap";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate, useParams  } from "react-router-dom";
import { getApi } from "../../services";
import Cookies from "js-cookie";
import { ApiState, PaymentState as SearchReduxState, useSelector } from "../../redux";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  Modal,
  useMediaQuery,
  useTheme,
} from "@mui/material";

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

type DocDetails = {
  [key: string]: any;
};
export const Profile: React.FC = () => {
  const searchredux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );
 

  const navigate = useNavigate();
  const { id } = useParams();
  const getapi = getApi();
  const [data, setData] = useState<any>([]);
  const [doctor,setDoctor] = useState<any>([])
  
  const [doctorinfo,setDoctorinfo] = useState<any>([])
  const theme = useTheme();
  const [details, setDetails] = useState<DocDetails>([]);
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  // BillingDetails
  const [dataBilling, setDataBilling] = useState<any>([]);

  // FirstAvailable Time
  const [FirstAvail, setFirstAvail] = useState<any>([]);

  // Redux
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [loadingBill, setLoadingBill] = useState(false);
  const convertSecondsToHMS = (seconds: any) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
 
    // Convert to 12-hour format

    let hours12 = hours % 12 || 12;
    hours12 = hours12 === 0 ? 12 : hours12; // Handle midnight (12 AM)

    const amPm = hours < 12 ? "AM" : "PM";

    return `${hours12.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${amPm.toString().padStart(2, "0")}`;
  };

  useEffect(()=>{
   
      setLoading(false)
    
    
  },[]) 


  const Apistate = useSelector<ApiState>(
    (state) => state.ApiReducer
  );

  const DoctorDetails = () => {
    // setLoading(true);
     let data = {
        location_id: Apistate.location_id,
        user_id: Apistate.userid ?Apistate.userid :Cookies.get("userid"),
 
      
     };
     
 
     getapi
       .post("doctorprofile/", data)
       .then((res) => {
     //  console.log('doctorprofile',res.data.data[0].BILLING_DETAILS) 
         setDoctorinfo(res.data.data[0]);
         
       })
       .catch((err) => {
        
       });
   };

useEffect(() =>{
DoctorDetails()
},[])


useEffect(() =>{
  // Retrieve the cookie value
  var cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)DoctorDetails\s*=\s*([^;]*).*$)|^.*$/, '$1');
  
  // If the cookie value exists, decode it and parse the JSON string back to an array
  if (cookieValue) {
    var retrievedArray = JSON.parse(decodeURIComponent(cookieValue));
   
    setDoctor(retrievedArray)
  } else {

  }
  },[])


  useEffect(() => {

    if(doctor.APTDate ?doctor.APTDate : doctor.ADATE !=null  && doctor.APTDate ?doctor.APTDate : doctor.ADATE !='-'){
      const VsetFirstAvailD =moment(doctor.APTDate ? doctor.APTDate : doctor.ADATE).format("ddd DD MMM");
      const VsetFirstAvailT =convertSecondsToHMS(doctor.APTTime ? doctor.APTTime : doctor.ATIME);
      setFirstAvail(VsetFirstAvailD+" "+VsetFirstAvailT)
    }else{
      setFirstAvail("Appointments not available")
    }
  
 
  }, [doctor.APTDate,doctor.ADATE]);

  const getData = () => {
    setLoading(true);
   
    let data = {
      location_id: Apistate.location_id,
    };

    
    getapi
      .post("practicedetails/", data)
      .then((res) => {
        setLoading(false);
       
        setData(res.data.data);
      })
      .catch((err) => {
       
      });
  };

  const getData2 = () => {
    setLoadingBill(true);
  
    let data = {
      location_id: Apistate.location_id,
      doctorlist_order: details[0]?.Doctors_ListingOrder
        ? "" + details[0]?.Doctors_ListingOrder
        : "0",
    };


    getapi
      .post("doctorlist/", data)
      .then((res) => {
        setLoadingBill(false);
       
        setDataBilling(res.data.data);
      })
      .catch((err) => {
       
      });
  };

 
  return (
    <div>
      {loading ? (
        <div className="d-flex align-items-center loader justify-content-center">
          <CircularProgress />
        </div>
      ) : (
        <>
          <section className="fvjobdetailheaderbgcolor"></section>

          <main className="fvjobdetailheaderbgmain">
              <Container>
                <Row>
                  <Col lg={12} className="left-content mx-auto">
                    <Box
                      className="h-100"
                      component={Paper}
                      elevation={2}
                      p={3}
                    >
                      <Breadcrumbs separator="<" aria-label="breadcrumb" style={{
                        marginBottom:15
                      }}>
                        <Link
                          className="breadcrumb"
                          underline="hover"
                          key="1"
                          color="inherit"
                          onClick={() => navigate(-1)}
                        >
                          <ChevronLeftIcon sx={{ mr: 0.5 }} />
                          Back
                        </Link>
                      </Breadcrumbs>


                      <Row>
                        <Col lg={2}>
                          <p className="text-center show-web1">
                            <Image
                              rounded
                              width={65}
                              height={100}
                              className="d-inline-flex align-left username object-fit-cover align-middle profile-img"
                              src={
                                doctor.ProfPhotoN ? doctor.ProfPhotoN : doctor.PROFILE_PHOTO_N ? doctor.PROFILE_PHOTO_N : require("../../assets/images/user_1.png")
                              }
                              //  src={require('../../assets/images/user_1.png')}
                              roundedCircle
                            />
                          </p>
                        </Col>
                        <Col lg={9} className="mb-text-center show-web1">
                          <div className="mt--15">
                            <Typography
                              className="main_txt mt-3"
                              variant="h5"
                              component="h5"
                            >
                               {doctor.ISDOCTOR} {doctor.UserName ? doctor.UserName :doctor.USERNAME}
                            </Typography>
                            <Typography>
                              <b>  {doctor.Designation ? doctor.Designation : doctor.DESIGNATION}</b>
                            </Typography>

                            <Typography className="sub_txt2 mt-2 font-14 show-web">
                              <b className="color-00B8E9">Next Available :</b>{" "}
                              <b className="color-00B8E9">
                                {FirstAvail}
                              </b>
                            </Typography>
                            <Typography className="sub_txt2 mt-2 font-14 show-mob">
                              <Row>
                                <div className="col-12" style={{
                                  paddingRight:0
                                }}>
                                  <b className="color-00B8E9">
                                    Next Available : <b className="color-00B8E9">
                                    {FirstAvail}
                                  </b>
                                  </b>{" "}
                                </div>
                             
                              </Row>
                            </Typography>
                          </div>
                        </Col>
                        <Divider
                          className="hr-profile"
                          variant="middle"
                        />
                      </Row>
                      <Row className="doc_data">
                      <Col lg={12}>
                      {  doctorinfo.ProfileNote || doctorinfo.PROFILE_NOTE  ?
                      <>
                          <div className="note_text">
                            <h3 className="bi_title fw-bold">Note</h3>
                           
                            <p style={{textAlign:'justify' }}>
                               <div
                            className="checkdata"
                              dangerouslySetInnerHTML={{
                                __html: doctorinfo.ProfileNote ? doctorinfo.ProfileNote : doctorinfo.PROFILE_NOTE ? doctorinfo.PROFILE_NOTE :''
                              }}
                            />
                            </p>
                            </div>
                            <Divider
                            className="hr-profile"
                            variant="middle"
                          />
                          </>
                          :
                          <></>
                        }
                            </Col>

                            {doctorinfo.BillingDetails || doctorinfo.BILLING_DETAILS ?
                        <Col lg={12}>
                          <div className="normal_txt">
                            <h3 className="bi_title fw-bold">Billings</h3>

                            <div
                              dangerouslySetInnerHTML={{
                                __html: doctorinfo.BillingDetails ? doctorinfo.BillingDetails :doctorinfo.BILLING_DETAILS ? doctorinfo.BILLING_DETAILS :'',
                              }}
                            />
                          </div>
                        </Col>
                        :
                        <Col></Col>
}

                        <Col lg={12}>
                          {!matches ?
                          <div className="d-flex mb-4 mt-5 div-right">
                            <Button
                          
                              onClick={() => {
                            
                                navigate("/appointment_reason");
                              }}
                              className="d_button div-Continue  btn-blue"
                            >
                              Continue
                            </Button>
                          </div>
                          :
                          <div className=" mb-4 mt-5">
                          <Button
                        
                            onClick={() => {
                          
                              navigate("/appointment_reason");
                            }}
                            className="d_button div-Continue inmobile btn-blue"
                          >
                            Continue
                          </Button>
                        </div>
                            }

                        </Col>
                      </Row>
                    </Box>
                  </Col>
                </Row>
              </Container>
              </main>
        </>
      )}
    </div>
  );
};
