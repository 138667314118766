// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doctorlistDrop{
  margin-bottom: 0px;
  background-color: #f7f6f6;
  padding: 4px 10px;
  border-bottom: solid #eee 1px;
  cursor: pointer;
}
.mt-10{
  margin-top: 10rem !important;
}
.css-1wuilmg-MuiAutocomplete-root {
  width: 100% !important;
}

`, "",{"version":3,"sources":["webpack://./src/pages/patients/homecss.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,iBAAiB;EACjB,6BAA6B;EAC7B,eAAe;AACjB;AACA;EACE,4BAA4B;AAC9B;AACA;EACE,sBAAsB;AACxB","sourcesContent":[".doctorlistDrop{\n  margin-bottom: 0px;\n  background-color: #f7f6f6;\n  padding: 4px 10px;\n  border-bottom: solid #eee 1px;\n  cursor: pointer;\n}\n.mt-10{\n  margin-top: 10rem !important;\n}\n.css-1wuilmg-MuiAutocomplete-root {\n  width: 100% !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
