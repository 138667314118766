import { Box, Paper } from "@mui/material";
import React, { Fragment, useState } from "react";
import { Col, Row, Container, Image } from "react-bootstrap";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import "./homecss.css";

export const NotFound: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Fragment>
      {!matches ? (
        <Container className="webview">
          <Row>
            <Col md={10} className="mx-auto">
              <Box
                component={Paper}
                elevation={2}
                p={0}
                mt={0}
                className="boxes"
              >
                <div className="login-div">
                  <Row>
                    <Col>
                      <div className="text-center">
                   
                        <Image
                          rounded
                          width={300}
                          height={300}
                          className="object-fit-cover"
                          src={require("../../assets/images/404Error.png")}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Box>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container className="mobileview">
          <Row>
            <Col lg={12} className="left-content mx-auto">
              <Box className="h-100" component={Paper} elevation={2} p={2}>
                <Row>
                  <Col lg={3}>
                    <div className="text-center">
                      {/* <p className="text-center p-5 fw-bold">404 Page Found</p> */}
                      <Image
                        rounded
                        width={300}
                        height={300}
                        className="object-fit-cover"
                        src={require("../../assets/images/404Error.png")}
                      />
                    </div>
                  </Col>
                </Row>
              </Box>
            </Col>
          </Row>
        </Container>
      )}
    </Fragment>
  );
};
