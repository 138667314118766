import React, { Fragment, useEffect, useRef, useState } from "react";
import {
    Typography,
    FormControl,
    Button,
    CircularProgress,
    OutlinedInput,
    FormHelperText
} from "@mui/material";

import { Image } from "react-bootstrap";
import './style.css'
import { Mobileheader } from "../../components/mobile_header";
import { useNavigate } from "react-router-dom";
import { getApi } from "../../services";
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { ApiState, MobileState as SearchReduxState, useSelector } from "../../redux";
export const LoginOTPVerify: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState('');
    const [error,seterror] = useState(false)
    const [userCookie, setuserCookie] = useState("");
    const [submitting, setsubmitting] = useState(false);
    const searchRedux = useSelector<SearchReduxState>((state) => state.mobileReducer);
    const apistate = useSelector<ApiState>((state) => state.ApiReducer)
    
    const navigate = useNavigate()
    const getapi = getApi();
    const [open, setOpen] = React.useState(false);
    const[msg,setmsg] = React.useState('')
    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref
      ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });

     const handleClose =() =>{
        setOpen(false)
      }
    const handleMobileNumberChange = (event: any) => {
        seterror(false)
        const inputValue = event.target.value;
        // Regex to match only 5-digit numbers
        const regex = /^\d{0,5}$/; // Allows only digits, up to 11 characters
        if (regex.test(inputValue)) {
            setOtp(inputValue);
        }
    };

    const Submit =(event:any) =>{
        event.preventDefault();
        setsubmitting(true)
        if (!otp || !/^\d{5}$/.test(otp)) {
            seterror(true); 
            setsubmitting(false)
            return
          }
        let data ={
            
            "location_id" : apistate.location_id,
            "phone_no" : searchRedux.phone_no,
            "otp" : otp
        }
       // console.log(data)
        
            getapi
              .post("registrationotpverify/",data)
              .then((response) => {
               // console.log("response data",response);
                setOpen(true)
                setmsg(response.data.Message)
                if(response.data.Success){
                    /*store the uuid and location id in cookies */
        setuserCookie(searchRedux.UUID+"_"+apistate.location_id);
        const cockie_name="userCookie";
        const cockie_days =30;
        const cockie_expires = new Date();
        const cockie_value = searchRedux.phone_no+"_"+apistate.location_id+"_"+searchRedux.phone_no;
        cockie_expires.setTime(cockie_expires.getTime() + cockie_days * 24 * 60 * 60 * 1000);
        document.cookie = `${cockie_name}=${cockie_value};expires=${cockie_expires.toUTCString()};path=/`;

       


                      navigate('/patientslist')
                }
           
                setsubmitting(false)
              })
              .catch((error) => {
              //  console.log(error);
                setsubmitting(false)
                seterror(true)
              });
          
    }

    return (
        <Fragment>
            {loading ?
                <div className="d-flex align-items-center loader justify-content-center">
                    <CircularProgress />
                </div>
                :
                <div className="loginpage">
                    <div className="blue_bg">
                        <Mobileheader />
                        <div className="login_form text-center align-item-center p-4 bg-white">
                {apistate.location_id === 'S25880ANA' ?
                  <Image className="dialpad mt-5" src={require("../../assets/images/mobile/pink/pin-code.png")} />
                  :
                  <Image className="dialpad mt-5" src={require("../../assets/images/mobile/pin-code.png")} />
                }

                            <Typography className="fw-bold pt-4 pb-4 fs-3">Verification</Typography>
                            <Typography className="smtext mb-4  fs-5">
                              You will get a OTP via <b>SMS</b>
                              </Typography>
                            <form noValidate autoComplete="off">
                                <FormControl 
                                error={error}
                                fullWidth>
                                    <OutlinedInput
                                    sx={{
                                        borderColor:'#989898',
                                        borderRadius: '10px',
                                        textAlign: 'center', // Center aligns the entered text
                                        '& input::placeholder': {
                                          textAlign: 'center', // Center aligns the placeholder text
                                          color:'#989898',
                                          fontWeight:500
                                        },
                                      }}
                                    className="mobile_inp"
                                        placeholder="Enter OTP"
                                        value={otp}
                                        onChange={handleMobileNumberChange}
                                        inputProps={{
                                            maxLength: 5, // Maximum length of the input
                                            pattern: '[0-9]*', // Pattern to allow only numeric input
                                            inputMode: 'numeric', // Specifies the input type as numeric
                                        }}
                                    />
                                 {error &&   <FormHelperText>Enter a valid OTP</FormHelperText> }
                                </FormControl>

                                <Button 
                                sx={{
                                    borderRadius: '10px',
                                    backgroundColor:'#000'
                                }}
                                onClick={Submit}
                                className="mvbutton mt-5 btn-blue"
                                type='submit'
                                variant="contained"                               
                                fullWidth
                                >
                                  {submitting ? <CircularProgress sx={{color:"#FFF"}} size={32}/> : 'Verify' }  
                                </Button>
                            </form>
                        </div>
                    </div>
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity='success'
              sx={{ width: "100%" }}
            >
              {msg}
            </Alert>
          </Snackbar>
                </div>
            }
        </Fragment>
    )
}

