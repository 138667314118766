import { Box, Paper, Breadcrumbs, Link, CircularProgress } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Container, Image } from "react-bootstrap";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { PaymentState as SearchReduxState, useSelector, ApiState } from "../../redux";
import { useDispatch } from "react-redux";
import { UpdatePaymentData, Resetdata } from "../../redux/User/actions";
import moment from "moment";
import { UpdateApiData } from "../../redux/Api/actions";
import { getApi } from "../../services";

export const PatientAlertYes: React.FC = () => {
  const navigate = useNavigate();
  const searchredux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );
  const Apistate = useSelector<ApiState>(
    (state) => state.ApiReducer
  );
  const [doctor, setDoctor] = useState<any>([])
  const [Aptreasonbyuser, setAptreasonbyuser] = useState<any>([])
  const [PractiesDetails, setPractiesDetails] = useState<any>([])
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const getapi = getApi();


  const getpracticedetails = async () => {

    try {
      let data = {
        location_id: Apistate.location_id,
      };

      const res = await getapi.post("practicedetails/", data);


      dispatch(UpdatePaymentData("Is_ServerDown_Msg", res.data.data[0].Is_ServerDown_Msg));
      dispatch(
        UpdatePaymentData("Location_Name", res.data.data[0].Location_Name)
      );
      localStorage.setItem('Location_Name', res.data.data[0].Location_Name);

      dispatch(
        UpdateApiData("Doctors_ListingOrder", res.data.data[0].Doctors_ListingOrder)
      );

      setPractiesDetails(res.data.data[0]);

      if (res.data.Success) {
        setLoading(false);

      }

    } catch (err) {
      // Handle the error
      console.log("error", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getpracticedetails()
 
  }, [])


  const handleButtonClick = () => {
    // Extract the phone number from PractiesDetails
    const phoneNumber = PractiesDetails.Phone_Number;

    // Trigger the phone call
    window.location.href = `tel:${phoneNumber}`;
  };


  //console.log(searchredux);
  return (
    <Fragment>
      <Container>
        {loading ?
          <div className="d-flex align-items-center loader justify-content-center">
            <CircularProgress />
          </div>
          :
          <Row>
            <Col lg={12} className="left-content mx-auto">
              <Box className="h-27" component={Paper} elevation={2} p={2}>
                <Breadcrumbs separator="<" aria-label="breadcrumb">
                  <Link
                    className="breadcrumb"
                    underline="hover"
                    key="1"
                    color="inherit"
                    onClick={() => navigate(-1)}
                  >
                    <ChevronLeftIcon sx={{ mr: 0.5 }} />
                    Back
                  </Link>
                </Breadcrumbs>
                <br />
                <Row>
                  <Col lg={3}></Col>
                  <Col lg={6} xs={12}>
                    <div className="align-content-center">
                      {Apistate.location_id === 'S23920ANA' ?
                                            <h3 className="patientalertYes  justify-content-center align-items-center">
                                            Please call us at  <a href={`tel:${PractiesDetails.Phone_Number}`}>{PractiesDetails.Phone_Number}</a> to book a consultation.
                                          </h3>

                      :
                      <h3 className="patientalertYes  justify-content-center align-items-center">
                        Please call us at  <a href={`tel:${PractiesDetails.Phone_Number}`}>{PractiesDetails.Phone_Number}</a> to book a
                        telephone consultation.
                      </h3>
}
                    </div>
                    <Button
                      onClick={handleButtonClick}
                      fullWidth
                      variant='outlined'
                      className="call des"
                    >Call Now</Button>
                  </Col>
                </Row>
                <Button
                  onClick={handleButtonClick}
                  fullWidth
                  variant='outlined'
                  className="call mobile"
                >Call Now</Button>
              </Box>
            </Col>
          </Row>
        }
      </Container>
    </Fragment>
  );
};
