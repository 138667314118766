import { Box, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Image } from "react-bootstrap";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";
import { ApiState, MobileState as SearchReduxState, themeReducer, useSelector } from "../../redux";
import { useDispatch } from "react-redux";
import { UpdatePaymentData } from "../../redux/User/actions";
import { UpdateMobileData } from "../../redux/MobileApp/actions";
import { toggleTheme, setThemeColor } from '../../redux/theme/action/themeActions';
import moment from "moment";
import { PaymentState as SearchReduxState2 } from "../../redux";
interface Props {}

export const AppSuccess: React.FC = () => {
  const navigate = useNavigate();
  // Redux

  const searchReduxm = useSelector<SearchReduxState>(
    (state) => state.mobileReducer
  );
  const searchRedux = useSelector<SearchReduxState2>(
    (state) => state.paymentReducer
  );
  const themeRedux = useSelector<themeReducer>(
    (state) => state.themeReducer
  );
  const [doctor,setDoctor] = useState<any>([])
  const dispatch = useDispatch();
  const convertSecondsToHMS = (seconds: any) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    // Convert to 12-hour format

    let hours12 = hours % 12 || 12;
    hours12 = hours12 === 0 ? 12 : hours12; // Handle midnight (12 AM)

    const amPm = hours < 12 ? "AM" : "PM";

    return `${hours12.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${amPm.toString().padStart(2, "0")}`;
  };

useEffect(() =>{
    dispatch(UpdateMobileData('UUID',searchReduxm.UUID))
    dispatch(UpdateMobileData('location_id',searchReduxm.location_id))
   
},[])


useEffect(() =>{
  
  var cookieValue2 = document.cookie.replace(/(?:(?:^|.*;\s*)DoctorDetails\s*=\s*([^;]*).*$)|^.*$/, '$1');

  // If the cookie value exists, decode it and parse the JSON string back to an array
  if (cookieValue2) {
    var retrievedArray2 = JSON.parse(decodeURIComponent(cookieValue2));
    
    
    setDoctor(retrievedArray2)
   
  } else {
   
  }



   


},[document.cookie])
const Apistate = useSelector<ApiState>(
  (state) => state.ApiReducer
);

  return (
    <main className="OTPVarifiedScreen">
      <Container>
        <Row>
          <Col md={12} className="align-self-center mx-auto">
            <Box
              className="h-100 text-center align-center justify-content-center"
              component={Paper}
              elevation={2}
              p={3}
            >
              <Row>
                <Col md={2}></Col>
                <Col md={8}>
                  {themeRedux.themeColor != 'pink' ?
                    <Image
                      rounded
                      className="d-inline-flex align-left object-fit-cover align-middle mb-3 success-icon"
                      // src={item.image}
                      src={require("../../assets/images/badge1.png")}
                      roundedCircle
                    />
                    :
                    <Image
                      rounded
                      className="d-inline-flex align-left object-fit-cover align-middle mb-3 success-icon"
                      // src={item.image}
                      src={require("../../assets/images/checked.png")}
                      roundedCircle
                    />
                  }
                  <Typography className="text-center peotp pt-3 pb-1 mb-2">
                    You have successfully booked appointment with
                  </Typography>
                  <Typography className="text-center peotp1  pb-4">
                    Dr {doctor.UserName} on{" "}
                    {moment(Apistate.selecteddate).format("DD MMMM YYYY")} |{" "}
                    {convertSecondsToHMS(Apistate.selectedslot)}
                  </Typography>
         
                        <div className="book-apt-btn d-flex justify-content-center">
                                    <Button
                                      className="book_appbtn3 mb-5"
                                      onClick={() =>{
                            
                                         navigate('/patientslist')
                                        }}
                                      type="submit"
                                      id="submit"
                                    >
                                      Home
                                    </Button>
                                  </div>
              

                </Col>
              </Row>
            </Box>
          </Col>
        </Row>
      </Container>
    </main>
  );
};
