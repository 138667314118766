import React, { Fragment, useEffect, useState, Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Header } from "./components/Navbar";
import { MHeader } from "./components/Navbar-mobileApp";
import { Home } from "./pages/Home";
import { PatientsIndex } from "./pages/patients/patients-list";
import { Book } from "./pages/patients/book";
import { Profile } from "./pages/patients/doctor_profile";
import { Reason } from "./pages/patients/appointment_reason";
import { Appointments } from "./pages/patients/appointments";
//import { Availableslots } from './pages/patients/availableslots'
import { AppointmentForm } from "./pages/patients/appointmentform";
import { OTPscreen } from "./pages/patients/OTPscreen";
import { OTPVarifiedScreen } from "./pages/patients/OTPVarifiedScreen";
import { HomePage } from "./pages/patients/home";
import { FG } from "./pages/patients/Appointmentformtest";
import { PatientAlert } from "./pages/patients/PatientAlert";
import { PatientAlertYes } from "./pages/patients/PatientAlertYes";
import { NotFound } from "./pages/patients/NotFound";
import { useSelector, themeReducer } from "./redux";
import { ServerDown } from "./pages/patients/serverdown";
import { PracticeInformation } from "./pages/patients/Practice_information";

// New Mobileview pages
import { SplashScreen } from "./pages/mobileview/SplashScreen";
import Login from "./pages/mobileview/Login";
import { LoginOTPVerify } from "./pages/mobileview/Otp_verifiy";
import { PatientsList } from "./pages/mobileview/Patients_list";
import { AddPatient } from "./pages/mobileview/Add_patient";
import { EditPatientsList } from "./pages/mobileview/Edit_patient";
import { AppSuccess } from "./pages/patients/AppSuccess";

import { useDispatch } from "react-redux";
import { PaymentState as SearchReduxState } from "./redux";
import { toggleTheme, setThemeColor } from "./redux/theme/action/themeActions";
import { useParams, useLocation } from "react-router-dom";
import { Box, CircularProgress, CssBaseline } from "@mui/material";
import { Resetdata } from "./redux/User/actions";
import { AResetData } from "./redux/Api/actions";
const App: React.FC = () => {
  const [loading, setLoading] = useState(true);

  // Check Devices Details state
  const [device, setDevice] = useState("");

  // Device fn()
  function isTabletDevice() {
    return (
      /iPad|Tablet|PlayBook|Silk|Kindle|Nexus 7|Nexus 10|KFAPWI/i.test(
        navigator.userAgent
      ) && !/Mobi|iPhone/i.test(navigator.userAgent)
    );
  }
  useEffect(() => {
    if (isTabletDevice()) {
      console.log("This is a tablet device");
      setDevice("tablet_view");
    } else {
      //  console.log("This is not a mobile device");
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []);

  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );

  const dispatch = useDispatch();

  const themeRedux = useSelector<themeReducer>((state) => state.themeReducer);

  /* do the dynamic color change */
  // dispatch(setThemeColor('color name here'));

  const location = useLocation();
  let locationid = location.search.split("?");
  const [location_id, setlocationid] = useState(
    locationid[1] ? atob(locationid[1]) : ""
  );

  return (
    <Fragment>
      {/* {location.pathname !== '/login' || '/loginotp' ?
    <></>
    :
     <Header /> 
     } */}
      {/* descktop header */}
      {/* <Header /> */}
      {/* Mobile view header */}
      {location.pathname !== "/login" &&
        location.pathname !== "/loginotp" &&
        location.pathname !== "/splash" && <MHeader />}
      <CssBaseline />
      <Box id={device && "Tab_view"} sx={{ display: "flex" }}>
        <div
          className={
            "App " +
            themeRedux.themeColor +
            (location.pathname !== "/login" &&
            location.pathname !== "/loginotp" &&
            location.pathname !== "/splash"
              ? " container content-body"
              : "")
          }
        >
          <Suspense fallback={<CircularProgress />}>
            <Routes>
              <Route path="/" element={<PatientsIndex />} />
              <Route path="/doctorslist" element={<PatientsIndex />} />
              <Route path="/book" element={<Book />} />
              <Route path="/profile" element={<Profile />} />

              <Route
                path="/practice-information"
                element={<PracticeInformation />}
              />
              <Route path="/appointment_reason" element={<Reason />} />
              <Route path="/appointments" element={<Appointments />} />
              {/* <Route path="/availableslots" element={<Availableslots />} /> */}
              <Route path="/appointmentform" element={<AppointmentForm />} />
              <Route path="/otp" element={<OTPscreen />} />
              <Route path="/success" element={<OTPVarifiedScreen />} />
              <Route path="/patient-alert" element={<PatientAlert />} />
              <Route path="/patient-alert-yes" element={<PatientAlertYes />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/serverdown" element={<ServerDown />} />
              {/* <Route path="/patient-alert2" element={<Home />} /> */}
              <Route path="/login" element={<Login />} />
              <Route path="/splash/:lid" element={<SplashScreen />} />
              <Route path="/loginotp" element={<LoginOTPVerify />} />
              <Route path="/patientslist" element={<PatientsList />} />
              <Route path="/edit-patientslist" element={<EditPatientsList />} />
              <Route path="/add-patientslist" element={<AddPatient />} />
              <Route path="/booking-success" element={<AppSuccess />} />
              <Route path="/*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </div>
      </Box>
    </Fragment>
  );
};

export default App;
