import React, { useEffect, useRef, useState } from "react";
import {
  Breadcrumbs,
  Link,
  Typography,
  Divider,
  Button as B,
  Alert,
  CircularProgress,
  Modal,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Col, Container, Row, Image, Button } from "react-bootstrap";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate, useParams } from "react-router-dom";
import Calendar from "react-calendar";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import moment from "moment";
import "react-calendar/dist/Calendar.css";
import { getApi } from "../../services";
import {
  ApiState,
  PaymentState as SearchReduxState,
  themeReducer,
  useSelector,
} from "../../redux";
import { useDispatch } from "react-redux";
import { UpdatePaymentData } from "../../redux/User/actions";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { MobileState as MReduxState } from "../../redux";
import { UpdateApiData } from "../../redux/Api/actions";
import Cookies from "js-cookie";
type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

export const Appointments: React.FC = () => {
  const [loc_phone, setphoneno] = useState();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { id } = useParams();
  const getapi = getApi();
  const count = 100;
  const dispatch = useDispatch();
  const [data, setData] = useState<any>([]);
  const [dataSlot, setslotData] = useState<any>([]);
  const [datalength, setdatalength] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageloading, setPageLoading] = useState(false);
  const [infomsg, setInfomsg] = useState(false);
  const [Noinfomsg, setNoInfomsg] = useState(false);
  const maxDate = new Date(Date.now() + 13 * 24 * 60 * 60 * 1000); // 14 days from now
  const tileDisabled = ({ date }: { date: Date }) => {
    // Disable dates beyond the maximum date
    return date.getTime() > maxDate.getTime();
    //  const formattedDate = moment(date).format("DD-MM-YYYY");
    //  return !mark.includes(formattedDate);
  };

  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );
  const searchRedux2 = useSelector<MReduxState>((state) => state.mobileReducer);
  const themeRedux = useSelector<themeReducer>((state) => state.themeReducer);
  const Apistate = useSelector<ApiState>((state) => state.ApiReducer);
  const [date, setdate] = useState(Apistate.selecteddate);
  const [dateclass, setdateclass] = useState<any>([]);

  const [hidecal, sethidecal] = useState(false);
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [value, onChange] = useState<Date>(new Date());
  const [Aptdate, setAptdate] = useState([]);
  const mark: string[] = Aptdate.map((item: any) =>
    moment(item.Date).format("DD-MM-YYYY")
  );

  const [availabeDates, setAvailabeDates] = useState<string[]>([]);

  const shouldLog = useRef(true);
  useEffect(() => {
    // shouldLog.current = false;
    // Update mark whenever Aptdate changes
    const updatedMark = Aptdate.map((item: any) =>
      moment(item.Date).format("DD-MM-YYYY")
    );
    // setLoading(true)
    setAvailabeDates(updatedMark);
  }, [Aptdate]);

  // useEffect(() =>{
  // console.log('aptdate =',Aptdate)
  // },[Aptdate])

  useEffect(() => {
    setdateclass(moment(Apistate.selecteddate).format("DD-MM-YYYY"));
  }, [Apistate.selecteddate]);

  useEffect(() => {}, [Apistate]);

  const getAptDates = () => {
    // setLoading(true);
    let data = {
      location_id: Apistate.location_id,
      user_id: Apistate.userid ? Apistate.userid : Cookies.get("userid"),
    };

    getapi
      .post("profileopenview/", data)
      .then((res) => {
        setAptdate(res.data.data);
      })
      .catch((err) => {});
  };

  const getslots = async () => {
    setLoading(true);
    // console.log('Apistate.selecteddate',Apistate.selecteddate)
    try {
      let selectedSlotCount = Cookies.get("selectedSlotCount");
      let stringValue1 = String(selectedSlotCount);
      let data = {
        location_id: Apistate.location_id,
        user_id: Apistate.userid ? Apistate.userid : Cookies.get("userid"),
        slot_count: stringValue1,
        apt_type: searchRedux.selectedreasonid,
        date: Apistate.selecteddate
          ? moment(Apistate.selecteddate).format("YYYY-MM-DD")
          : moment(Cookies.get("selecteddate")).format("YYYY-MM-DD"),
      };

      console.log("new data", data);
      const res = await getapi.post("getfreeappointmentbysbnew/", data);
      if (res.data.Success === "True") {
        setslotData(res.data.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setdatalength(res.data.data.length);
      }
    } catch (err) {
      // Handle error if needed
    }
  };

  function checkCookie(cookieName: any) {
    var allCookies = document.cookie;
    var cookiesArray = allCookies.split(";");
    for (var i = 0; i < cookiesArray.length; i++) {
      var cookie = cookiesArray[i].trim();
      if (cookie.indexOf(cookieName + "=") === 0) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    // Check if the cookie exists
    const isCookieExists = checkCookie("DoctorDetails");
    if (isCookieExists) {
      getslots();
    }
  }, []);

  const slotcheck = (time: any) => {
    sethidecal(false);
    let data = {
      location_id: Apistate.location_id,
      user_id: Apistate.userid ? Apistate.userid : Cookies.get("userid"),
      bdate: moment(Apistate.selecteddate).format("YYYY-MM-DD")
        ? moment(Apistate.selecteddate).format("YYYY-MM-DD")
        : moment(Cookies.get("selecteddate")).format("YYYY-MM-DD"),
      btime: "" + time,
    };

    getapi
      .post("bookinglog/", data)
      .then((res) => {
        if (res.data.Status_Code === 1) {
          sethidecal(false);
          navigate("/appointmentform");
        } else {
          sethidecal(false);
          dispatch(UpdateApiData("SlotLog", res.data.Status_Code));
          dispatch(UpdateApiData("SlotMsg", res.data.Message));

          if (searchRedux2.LoginFrom === "MobileApp") {
          } else {
            navigate("/appointmentform");
          }
        }
      })
      .catch((err) => {});
  };
  const convertSecondsToHMS = (seconds: any) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    // Convert to 12-hour format

    let hours12 = hours % 12 || 12;
    hours12 = hours12 === 0 ? 12 : hours12; // Handle midnight (12 AM)

    const amPm = hours < 12 ? "AM" : "PM";

    return `${hours12.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${amPm.toString().padStart(2, "0")}`;
  };

  const getreason = (id: any, reason: any) => {};

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const Mstyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const MCstyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  const [modalopen, setMOpen] = React.useState(false);
  const handleOpen = () => setMOpen(true);
  const modalhandleClose = () => setMOpen(false);
  const [Aptreason, setAptreason] = useState<any>([]);

  const [modalopen2, setMOpen2] = React.useState(false);
  const handleOpen2 = () => setMOpen2(true);
  const modalhandleClose2 = () => setMOpen2(false);

  const [popopen, setPopOpen] = React.useState(false);

  const pophandleClose = () => setPopOpen(false);

  const handleMonthChange = (newDate: Date) => {
    dispatch(UpdateApiData("selecteddate", newDate));
    Cookies.set("selecteddate", moment(newDate).format("YYYY-MM-DD"));

    onChange(newDate);
  };

  const [doctor, setDoctor] = useState<any>([]);
  const [Aptreasonbyuser, setAptreasonbyuser] = useState<any>([]);
  const [PractiesDetails, setPractiesDetails] = useState<any>([]);

  const UserReasonData = () => {
    let data = {
      location_id: Apistate.location_id,
      user_id: Apistate.userid
        ? Apistate.userid
        : doctor.USERID
        ? doctor.USERID
        : Cookies.get("userid"),
    };

    getapi
      .post("aptreasonbyuser/", data)
      .then((res) => {
        setAptreasonbyuser(res.data.data);

        // var jsonString = JSON.stringify(res.data.data);
        // document.cookie = 'aptreasonbyuser=' + encodeURIComponent(jsonString);
      })
      .catch((err) => {});
  };

  const getpracticedetails = async () => {
    //setLoading(true);

    try {
      let data = {
        location_id: Apistate.location_id,
      };

      const res = await getapi.post("practicedetails/", data);

      dispatch(
        UpdatePaymentData(
          "Is_ServerDown_Msg",
          res.data.data[0].Is_ServerDown_Msg
        )
      );
      dispatch(
        UpdatePaymentData("Location_Name", res.data.data[0].Location_Name)
      );
      Cookies.set("Location_Name", res.data.data[0].Location_Name);

      dispatch(
        UpdateApiData(
          "Doctors_ListingOrder",
          res.data.data[0].Doctors_ListingOrder
        )
      );

      setPractiesDetails(res.data.data[0]);
      setphoneno(res.data.data[0].Phone_Number);
      if (res.data.Success) {
        // setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getpracticedetails();
    UserReasonData();
  }, []);

  useEffect(() => {
    // Retrieve the cookie value
    var cookieValue = document.cookie.replace(
      /(?:(?:^|.*;\s*)APTReason\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    );

    // If the cookie value exists, decode it and parse the JSON string back to an array
    if (cookieValue) {
      var retrievedArray = JSON.parse(decodeURIComponent(cookieValue));

      setAptreason(retrievedArray);
    } else {
    }

    var cookieValue2 = document.cookie.replace(
      /(?:(?:^|.*;\s*)DoctorDetails\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    );

    // If the cookie value exists, decode it and parse the JSON string back to an array
    if (cookieValue2) {
      var retrievedArray2 = JSON.parse(decodeURIComponent(cookieValue2));

      setDoctor(retrievedArray2);
    } else {
    }

    /* get the aptreason to cookies */
    // var cookieValue3 = document.cookie.replace(/(?:(?:^|.*;\s*)aptreasonbyuser\s*=\s*([^;]*).*$)|^.*$/, '$1');

    // // If the cookie value exists, decode it and parse the JSON string back to an array
    // if (cookieValue3) {
    //   var retrievedArray3 = JSON.parse(decodeURIComponent(cookieValue3));

    //   setAptreasonbyuser(retrievedArray3)
    // } else {

    // }

    var cookieVal;

    document.cookie.split(";").forEach((cookie) => {
      const parts = cookie.split("=");
      const name = parts[0].trim();
      if (name === "APTDate") {
        cookieVal = parts[1];
        // setDate(cookieVal)
      }
    });
  }, [document.cookie]);

  useEffect(() => {
    setData(Aptreasonbyuser);
  }, [Aptreasonbyuser]);

  useEffect(() => {
    if (Apistate.selecteddate) {
      // console.log('selected date',Apistate.selecteddate)
      // console.log('available date',availabeDates)
      if (
        availabeDates &&
        availabeDates.includes(
          moment(Apistate.selecteddate).format("DD-MM-YYYY")
        )
      ) {
        //  console.log('av called')
        getslots();
      } else {
        setslotData([]);
        // console.log('else called')
      }
    }
  }, [Apistate.selecteddate]);

  /*appointment dates api call */
  useEffect(() => {
    getAptDates();
  }, [doctor.Id]);

  /* form submition in background when user came from mobile app */
  const [error, seterror] = useState(false);
  const [errormsg, seterrormsg] = useState(" ");

  const PresubmitForm = async () => {
    try {
      let res = moment(Apistate.selecteddate)
        .format("YYYY-MM-DD")
        .replace(/[^a-zA-Z0-9 ]/g, "");

      let sdata = {
        booked_from: searchRedux2.LoginFrom === "MobileApp" && 2,
        location_id_main: Apistate.location_id,
        date_time_id: res + Apistate.selectedslot,
        bdate: moment(Apistate.selecteddate).format("YYYY-MM-DD"),
        btime: Apistate.selectedslot,
        blength: Apistate.AptLength,
        location_id: Apistate.location_id,
        user_id: Apistate.userid ? Apistate.userid : Cookies.get("userid"),
        pfirstname: searchRedux2.user_firstname,
        psurname: searchRedux2.user_surname,
        pdob: moment(searchRedux2.user_dob).format("YYYY-MM-DD"),
        pmobileno: searchRedux2.phone_no
          ? searchRedux2.phone_no
          : Cookies.get("phone_no"),
        potp: "",
        bstatus: "",
        aptstype: "",
        aptsreason: searchRedux.selectedreason,
        sex: searchRedux2.user_gender,
        bookingtime: "",
        medicareno: "",
        email: "",
        street: "",
        suburb: "",
        postcode: "",
        layout_id: "",
      };

      //  console.log('mobile data',sdata)

      const response = await getapi.post("bookinginsert/", sdata);

      if (response.data.Status === 200) {
        dispatch(UpdatePaymentData("uniqueid", response.data.UserId));
        dispatch(UpdateApiData("uniqueid", response.data.UserId));
        modalhandleClose2();

        navigate("/booking-success");
      } else {
        seterror(true);
        seterrormsg(response.data.Message);
        setTimeout(() => {
          modalhandleClose2();
        }, 3000);
      }
    } catch (error) {
      // Handle error here
      console.error("Error occurred:", error);
      setTimeout(() => {
        modalhandleClose2();
      }, 3000);
    }
  };

  return (
    <div>
      {pageloading ? (
        <div className="d-flex align-items-center loader justify-content-center">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <section className="fvjobdetailheaderbgcolor"></section>

          <Modal
            id={themeRedux.themeColor}
            open={modalopen2}
            onClose={modalhandleClose2}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={!matches ? style : MCstyle}
              style={{
                textAlign: "center",
              }}
            >
              <Typography
                className={
                  themeRedux.themeColor + "  text-center poptitle main_txt"
                }
                variant="h5"
                component="h5"
              >
                Confirm your appointment
              </Typography>

              <div onClick={() => setMOpen2(false)}>
                <HighlightOffIcon className="cbtntt" />
              </div>
              <div className="p-3">
                <Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                  id="timimg"
                  variant="h6"
                  component="h2"
                >
                  {doctor.ISDOCTOR} {doctor.UserName}
                </Typography>
                <Typography
                  style={{
                    color: "#111",
                    fontSize: 16,
                  }}
                >
                  {moment(Apistate.selecteddate).format("DD MMMM YYYY")} |{" "}
                  {convertSecondsToHMS(Apistate.selectedslot)}
                </Typography>
                <div
                  className="btn2"
                  style={{
                    margin: 15,
                  }}
                >
                  <B
                    onClick={() => {
                      PresubmitForm();
                    }}
                    variant="contained"
                    className="btn-primary"
                    color="secondary"
                    style={{
                      marginRight: 10,
                      textTransform: "capitalize",
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#fff",
                    }}
                  >
                    Book Appointment
                  </B>
                  <B
                    variant="contained"
                    className="btn-pink btn-blue"
                    onClick={() => setMOpen2(false)}
                    color="primary"
                    style={{
                      textTransform: "capitalize",
                      fontWeight: 600,
                      fontSize: 16,
                    }}
                  >
                    Cancel
                  </B>
                </div>

                {errormsg && (
                  <p style={{ color: "#cc0000" }}>
                    {errormsg}
                    {/* Network issue is identified please try again later...  */}
                  </p>
                )}
              </div>
            </Box>
          </Modal>

          <main className="fvjobdetailheaderbgmain desktop_view">
            <Container>
              <Row className="row-eq-height">
                {/* col left starts  */}

                <Col lg={4}>
                  <Box className="h-100" component={Paper} elevation={2} p={1}>
                    <Breadcrumbs separator="<" aria-label="breadcrumb">
                      <Link
                        className="breadcrumb"
                        underline="hover"
                        key="1"
                        color="inherit"
                        onClick={() => navigate(-1)}
                      >
                        <ChevronLeftIcon sx={{ mr: 0.5 }} />
                        Back
                      </Link>
                    </Breadcrumbs>
                    <br />

                    <div className="text-center align-center justify-content-center">
                      <Image
                        rounded
                        width={100}
                        height={100}
                        className="d-inline-flex align-left username object-fit-cover align-middle mt-3"
                        src={
                          doctor.ProfPhotoN
                            ? doctor.ProfPhotoN
                            : doctor.PROFILE_PHOTO_N
                            ? doctor.PROFILE_PHOTO_N
                            : require("../../assets/images/user_1.png")
                        }
                        //  src={require('../../assets/images/user_1.png')}
                        roundedCircle
                      />

                      <Typography
                        className="text-center main_txt"
                        variant="h5"
                        component="h5"
                      >
                        {doctor.ISDOCTOR}{" "}
                        {doctor.UserName ? doctor.UserName : doctor.USERNAME}
                      </Typography>
                      <Typography className="fw-bold">
                        {doctor.Designation
                          ? doctor.Designation
                          : doctor.DESIGNATION}
                      </Typography>

                      {/* <Typography>{searchRedux.Gender},{searchRedux.Academy}</Typography> */}

                      <Divider
                        style={{
                          marginTop: 15,
                          marginBottom: 15,
                          height: 2,
                          background: "#ddd",
                        }}
                        variant="middle"
                      />
                    </div>

                    <div className="normal_txt">
                      <h3 className="bi_title text-center fw-bold">
                        Select Appointment Reason
                      </h3>

                      {Aptreasonbyuser?.map((item: any, idx: number) => (
                        <Button
                          //  disabled={searchRedux.selectedreasonid !== item.ReasonId && searchRedux.selectedreasonid !== undefined}
                          className={
                            Aptreason.ReasonId === item.ReasonId &&
                            Aptreason.ReasonId !== undefined
                              ? "app_button active v_button"
                              : "app_button"
                          }
                          //  size="sm"
                          onClick={() =>
                            getreason(item.ReasonId, item.MainReason)
                          }
                        >
                          {item.MainReason}
                        </Button>
                      ))}
                    </div>
                  </Box>
                </Col>

                {/* col left ends  */}

                {/* col right starts  */}

                <Col lg={8} className="left-content">
                  <Box className="h-100 slot-card css-soiwqq-MuiPaper-root">
                    {(doctor?.ProfMessage || doctor?.PROFILE_MESSAGE) &&
                      Aptreason.ReasonForUnSet === 0 && (
                        <div className="mb-4">
                          <Alert
                            sx={{
                              borderColor: "#38c390",
                              color: "#38c390",
                              fontWeight: 600,
                              fontSize: 16,
                            }}
                            className="alert_new"
                            variant="outlined"
                            // severity="info"
                            elevation={0}
                          >
                            {doctor.ProfMessage
                              ? doctor.ProfMessage
                              : doctor.PROFILE_MESSAGE}
                            {/* Your Safety and our staffs is our utmost priority.Tele-consultation Now Available */}
                          </Alert>
                        </div>
                      )}

                    {Aptreason.ReasonForUnSet === 0 ? (
                      <>
                        {!hidecal && (
                          <div className="slotdev cal-size">
                            <div
                              onClick={() => sethidecal(true)}
                              className="react-calendar__navigation"
                            >
                              <div className="leftarrow">
                                <ArrowBackIosNewIcon />
                              </div>
                              <div className="calicon">
                                <CalendarMonthIcon />
                              </div>

                              <button
                                aria-label=""
                                className="react-calendar__navigation__label bor-r-8"
                                type="button"
                                style={{ flexGrow: 1 }}
                              >
                                <span className="react-calendar__navigation__label__labelText react-calendar__navigation__label__labelText--from">
                                  {moment(
                                    Apistate.selecteddate
                                      ? Apistate.selecteddate
                                      : Cookies.get("selecteddate")
                                  ).format("ddd DD MMM YYYY")}
                                </span>
                              </button>
                            </div>

                            <div className="mt-4">
                              {loading ? (
                                <div className="d-flex align-items-center loader2 justify-content-center mt-4">
                                  <CircularProgress />
                                </div>
                              ) : (
                                <>
                                  {dataSlot.length > 0 ||
                                  availabeDates.includes(
                                    moment(Apistate.selecteddate).format(
                                      "DD-MM-YYYY"
                                    )
                                  ) ? (
                                    <div className="justify-content-center mt-4">
                                      {dataSlot.length > 0 ? (
                                        dataSlot.map(
                                          (item: any, idx: number) => (
                                            <>
                                              {Aptreason.ReasonId === 13 &&
                                              PractiesDetails.NewPatientAptMsg ===
                                                1 ? (
                                                <Button
                                                  className="slot_button slot-width-w"
                                                  onClick={() => {
                                                    dispatch(
                                                      UpdateApiData(
                                                        "selectedslot",
                                                        item.AptTime
                                                      )
                                                    );
                                                    dispatch(
                                                      UpdateApiData(
                                                        "AptLength",
                                                        item.AptLength
                                                      )
                                                    );
                                                    setInfomsg(false);
                                                    handleOpen();
                                                  }}
                                                >
                                                  {convertSecondsToHMS(
                                                    item.AptTime
                                                  )}
                                                </Button>
                                              ) : (
                                                <Button
                                                  className="slot_button slot-width-w"
                                                  onClick={() => {
                                                    setNoInfomsg(false);
                                                    dispatch(
                                                      UpdateApiData(
                                                        "selectedslot",
                                                        item.AptTime
                                                      )
                                                    );
                                                    dispatch(
                                                      UpdateApiData(
                                                        "AptLength",
                                                        item.AptLength
                                                      )
                                                    );
                                                    sethidecal(false);
                                                    if (
                                                      Aptreason.PopStatus ===
                                                      "1"
                                                    ) {
                                                      setPopOpen(true);
                                                    } else {
                                                      slotcheck(item.AptTime);
                                                    }
                                                  }}
                                                >
                                                  {convertSecondsToHMS(
                                                    item.AptTime
                                                  )}
                                                </Button>
                                              )}
                                            </>
                                          )
                                        )
                                      ) : (
                                        <>
                                          <Alert
                                            severity="error"
                                            className="align-item-center justify-content-center"
                                          >
                                            Appointments not available on this
                                            date
                                          </Alert>
                                        </>
                                      )}
                                    </div>
                                  ) : (
                                    <>
                                      <Alert
                                        severity="error"
                                        className="align-item-center justify-content-center"
                                      >
                                        Appointments not available on this date
                                      </Alert>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div
                        className="d-flex align-middle justify-content-center h-100"
                        style={{
                          alignItems: "center",
                        }}
                      >
                        {loc_phone ? (
                          <Typography className="align-middle no_app justify-content-center text-center">
                            {doctor.ISDOCTOR}{" "}
                            {doctor.UserName
                              ? doctor.UserName
                              : doctor.USERNAME}{" "}
                            is not currently accepting {Aptreason.MainReason}{" "}
                            online. Please call us on{" "}
                            <a href={`tel:${loc_phone}`}>{loc_phone}</a>
                          </Typography>
                        ) : (
                          <div className="d-flex align-items-center loader2 justify-content-center mt-4">
                            <CircularProgress />
                          </div>
                        )}
                      </div>
                    )}
                    {/* slot end */}

                    {hidecal && (
                      <div className="cal-size">
                        <Calendar
                          minDate={new Date()}
                          maxDate={
                            new Date(Date.now() + 13 * 24 * 60 * 60 * 1000)
                          }
                          tileDisabled={tileDisabled}
                          tileClassName={({ date }) => {
                            const formattedDate =
                              moment(date).format("DD-MM-YYYY");

                            const selectedDateFormatted = moment(
                              dateclass,
                              "DD-MM-YYYY"
                            ).format("DD-MM-YYYY");
                            if (dateclass === formattedDate) {
                              return "selecteddate";
                            }
                            if (mark.find((x) => x === formattedDate)) {
                              return "highlight";
                            }
                          }}
                          // navigationLabel={() => null}
                          // showNeighboringMonth={false}
                          navigationLabel={({ date, label, locale, view }) => (
                            <div
                              className="test"
                              onClick={() => sethidecal(false)}
                            >
                              {moment(date).format(" MMMM, YYYY")}
                            </div>
                            //`${moment(date).format("ddd DD MMM YYYY")}`
                          )}
                          onChange={(date: any) => {
                            dispatch(UpdateApiData("selecteddate", date));
                            Cookies.set("selecteddate", date);
                            sethidecal(false);
                            onChange(date);
                            //  window.location.reload();
                          }}
                          onClickMonth={handleMonthChange}
                          onClickYear={handleMonthChange}
                          // activeStartDate={value}
                          defaultActiveStartDate={value}
                          // defaultValue={new Date(2023,11,12)}
                          prev2Label=""
                          next2Label=""
                          value={value}
                          view="month"
                        />
                      </div>
                    )}
                  </Box>
                </Col>

                {/* col right  ends  */}
              </Row>
            </Container>
          </main>

          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="warning"
              style={{
                backgroundColor: "#ff9966",
              }}
              sx={{ width: "100%" }}
            >
              Select Appointment Reason
            </Alert>
          </Snackbar>

          <Modal
            id={themeRedux.themeColor}
            open={modalopen}
            onClose={modalhandleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={!matches ? style : Mstyle}
              style={{
                textAlign: "center",
                letterSpacing: "1px",
              }}
            >
              <div onClick={() => setMOpen(false)}>
                <HighlightOffIcon className="closebtn" />
              </div>
              <Typography className="text-center" variant="h6" component="h2">
                {PractiesDetails.New_PatientMsg}
              </Typography>

              {!infomsg && (
                <div
                  className="btn2"
                  style={{
                    margin: 15,
                  }}
                >
                  <B
                    onClick={() => {
                      slotcheck(Apistate.selectedslot);
                      //  navigate("/appointmentform");
                    }}
                    className="yes_btn"
                    variant="contained"
                    style={{
                      marginRight: 10,
                    }}
                  >
                    Yes
                  </B>
                  <B
                    variant="contained"
                    className="no_btn"
                    onClick={() => setInfomsg(true)}
                  >
                    No
                  </B>
                </div>
              )}
              {infomsg && (
                <Alert severity="error" id="patmsg">
                  {PractiesDetails.New_PatientMsg_No}
                </Alert>
              )}
            </Box>
          </Modal>

          <Modal
            id={themeRedux.themeColor}
            open={popopen}
            onClose={() => setPopOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={!matches ? style : Mstyle}
              style={{
                textAlign: "center",
                letterSpacing: "1px",
              }}
            >
              <div onClick={() => setPopOpen(false)}>
                <HighlightOffIcon className="closebtn" />
              </div>
              <Typography className="text-center" variant="h6" component="h2">
                {Aptreason.MainContent}
              </Typography>

              {!Noinfomsg && (
                <div
                  className="btn2"
                  style={{
                    margin: 15,
                  }}
                >
                  <B
                    onClick={() => {
                      slotcheck(Apistate.selectedslot);
                      //  navigate("/appointmentform");
                    }}
                    className="yes_btn"
                    variant="contained"
                    style={{
                      marginRight: 10,
                    }}
                  >
                    Yes
                  </B>
                  <B
                    variant="contained"
                    className="no_btn"
                    onClick={() => setNoInfomsg(true)}
                  >
                    No
                  </B>
                </div>
              )}
              {Noinfomsg && (
                <Alert severity="error" id="patmsg">
                  {Aptreason.NoContent}
                </Alert>
              )}
            </Box>
          </Modal>

          <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="warning"
              style={{
                backgroundColor: "#ff9966",
              }}
              sx={{ width: "100%" }}
            >
              Select Appointment Date
            </Alert>
          </Snackbar>

          {/* MOBILE VIEW */}
          <main className="mobile_view">
            <Box
              className="h-50rem pd-m-0"
              component={Paper}
              elevation={2}
              p={2}
            >
              <Breadcrumbs separator="<" aria-label="breadcrumb">
                <Link
                  className="breadcrumb"
                  underline="hover"
                  key="1"
                  color="inherit"
                  onClick={() => navigate(-1)}
                >
                  <ChevronLeftIcon sx={{ mr: 0.5 }} />
                  Back
                </Link>
              </Breadcrumbs>
              <br />

              <Row className="ml-5" style={{ paddingLeft: 16 }}>
                <Col
                  xs={2}
                  className="text-center d-flex justify-content-center"
                >
                  <Image
                    rounded
                    width={62}
                    height={62}
                    className="d-inline-flex align-left username object-fit-cover align-middle"
                    // src={item.image}
                    src={
                      doctor.ProfPhotoN
                        ? doctor.ProfPhotoN
                        : doctor.PROFILE_PHOTO_N
                        ? doctor.PROFILE_PHOTO_N
                        : require("../../assets/images/user_1.png")
                    }
                    roundedCircle
                  />
                </Col>
                <Col>
                  <Typography className="main_txt" variant="h5" component="h5">
                    {doctor.ISDOCTOR}{" "}
                    {doctor.UserName ? doctor.UserName : doctor.USERNAME}
                  </Typography>
                  <small className="text-muted align-middle sub_text">
                    {doctor.Designation
                      ? doctor.Designation
                      : doctor.DESIGNATION}
                  </small>
                </Col>
              </Row>
              <br />
              <Divider
                style={{
                  marginTop: 0,
                  marginBottom: 5,
                  height: 2,
                  background: "#ddd",
                }}
                variant="middle"
              />

              <div className="normal_txt">
                <Typography className="text-center">
                  <b className="selected-reason-apt"></b>
                  {Aptreasonbyuser?.map((item: any, idx: number) => (
                    <Button
                      // disabled={searchRedux.selectedreasonid !== item.ReasonId && searchRedux.selectedreasonid !== undefined}
                      className={
                        Aptreason.ReasonId === item.ReasonId &&
                        Aptreason.ReasonId !== undefined
                          ? "selected-reason-apt"
                          : "v_button app_button desktop_view"
                      }
                      variant="outline-secondary"
                      //  size="sm"
                      onClick={() => getreason(item.ReasonId, item.MainReason)}
                    >
                      {item.MainReason}
                    </Button>
                  ))}
                </Typography>
              </div>
              <Divider
                style={{
                  marginTop: 5,
                  marginBottom: 10,
                  height: 2,
                  background: "#ddd",
                }}
                variant="middle"
              />
              {(doctor?.ProfMessage || doctor?.PROFILE_MESSAGE) &&
                Aptreason.ReasonForUnSet === 0 && (
                  <>
                    <br />
                    <div className="mb-4">
                      <Alert
                        sx={{
                          borderColor: "#38c390",
                          color: "#38c390",
                          fontWeight: 600,
                          fontSize: 14,
                        }}
                        className="alert_new"
                        variant="outlined"
                        // severity="info"
                        elevation={0}
                      >
                        {doctor.ProfMessage
                          ? doctor.ProfMessage
                          : doctor.PROFILE_MESSAGE}
                        {/* Your Safety and our staffs is our utmost priority.Tele-consultation Now Available */}
                      </Alert>
                    </div>
                  </>
                )}
              <div>
                {Aptreason.ReasonForUnSet === 0 ? (
                  <>
                    {!hidecal && (
                      <div className="slotdev cal-size">
                        <div className="react-calendar__navigation">
                          <div
                            className="leftarrow"
                            onClick={() => sethidecal(true)}
                          >
                            <ArrowBackIosNewIcon />
                          </div>

                          <div
                            className="calicon"
                            onClick={() => sethidecal(true)}
                          >
                            <CalendarMonthIcon />
                          </div>
                          <button
                            aria-label=""
                            className="react-calendar__navigation__label  bor-r-8"
                            type="button"
                            onClick={() => sethidecal(true)}
                            style={{ flexGrow: 1 }}
                          >
                            <span className="react-calendar__navigation__label__labelText react-calendar__navigation__label__labelText--from">
                              {moment(
                                Apistate.selecteddate
                                  ? Apistate.selecteddate
                                  : Cookies.get("selecteddate")
                              ).format("ddd DD MMM YYYY")}
                            </span>
                          </button>
                        </div>

                        {/* <div className="mt--10">
                          {loading ? (
                            <div className="d-flex align-items-center loader2 justify-content-center mt-4">
                              <CircularProgress />
                            </div>
                          ) : (
                            <>
                              {dataSlot.length > 0 ||
                              availabeDates.includes(
                                moment(Apistate.selecteddate).format(
                                  "DD-MM-YYYY"
                                )
                              ) ? (
                                <div className="justify-content-center mt-4">
                                  {dataSlot?.map((item: any, idx: number) => (
                                    <>
                                      {searchRedux2.LoginFrom ===
                                      "MobileApp" ? (
                                        <>
                                          <Button
                                            className="slot_button slot-width-w"
                                            
                                            onClick={() => {
                                              dispatch(
                                                UpdateApiData(
                                                  "selectedslot",
                                                  item.AptTime
                                                )
                                              );

                                              dispatch(
                                                UpdateApiData(
                                                  "AptLength",
                                                  item.AptLength
                                                )
                                              );
                                              sethidecal(true);
                                              if (Aptreason.PopStatus === "1") {
                                                setPopOpen(true);
                                              } else {
                                                setMOpen2(true);
                                              }
                                              setInfomsg(false);
                                            }}
                                          >
                                            {convertSecondsToHMS(item.AptTime)}
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          {Aptreason.ReasonId === 13 &&
                                          PractiesDetails.NewPatientAptMsg ===
                                            1 ? (
                                            <Button
                                              className="slot_button slot-width-w"
                                              
                                              onClick={() => {
                                                dispatch(
                                                  UpdateApiData(
                                                    "selectedslot",
                                                    item.AptTime
                                                  )
                                                );
                                                dispatch(
                                                  UpdateApiData(
                                                    "AptLength",
                                                    item.AptLength
                                                  )
                                                );
                                                handleOpen();
                                                setInfomsg(false);
                                              }}
                                            >
                                              {convertSecondsToHMS(
                                                item.AptTime
                                              )}
                                            </Button>
                                          ) : (
                                            <Button
                                              className="slot_button slot-width-w"
                                              
                                              onClick={() => {
                                                setNoInfomsg(false);

                                                dispatch(
                                                  UpdateApiData(
                                                    "selectedslot",
                                                    item.AptTime
                                                  )
                                                );
                                                dispatch(
                                                  UpdateApiData(
                                                    "AptLength",
                                                    item.AptLength
                                                  )
                                                );
                                                sethidecal(false);
                                                if (
                                                  Aptreason.PopStatus === "1"
                                                ) {
                                                  setPopOpen(true);
                                                } else {
                                                  slotcheck(item.AptTime);
                                                }

                                                
                                              }}
                                            >
                                              {convertSecondsToHMS(
                                                item.AptTime
                                              )}
                                            </Button>
                                          )}
                                        </>
                                      )}
                                    </>
                                  ))}
                                </div>
                              ) : (
                                <>
                                  <Alert
                                    severity="error"
                                    className="align-item-center justify-content-center"
                                  >
                                    Appointments not available on this date
                                  </Alert>
                                </>
                              )}
                            </>
                          )}
                        </div> */}

                        <div className="mt--10">
                          {loading ? (
                            <div className="d-flex align-items-center loader2 justify-content-center mt-4">
                              <CircularProgress />
                            </div>
                          ) : (
                            <>
                              {dataSlot.length > 0 ||
                              availabeDates.includes(
                                moment(Apistate.selecteddate).format(
                                  "DD-MM-YYYY"
                                )
                              ) ? (
                                <div className="justify-content-center mt-4">
                                  {dataSlot.length > 0 ? (
                                    dataSlot.map((item: any, idx: number) => (
                                      <>
                                        {searchRedux2.LoginFrom ===
                                        "MobileApp" ? (
                                          <>
                                            <Button
                                              className="slot_button slot-width-w"
                                              onClick={() => {
                                                dispatch(
                                                  UpdateApiData(
                                                    "selectedslot",
                                                    item.AptTime
                                                  )
                                                );
                                                dispatch(
                                                  UpdateApiData(
                                                    "AptLength",
                                                    item.AptLength
                                                  )
                                                );
                                                sethidecal(true);
                                                if (
                                                  Aptreason.PopStatus === "1"
                                                ) {
                                                  setPopOpen(true);
                                                } else {
                                                  setMOpen2(true);
                                                }
                                                setInfomsg(false);
                                              }}
                                            >
                                              {convertSecondsToHMS(
                                                item.AptTime
                                              )}
                                            </Button>
                                          </>
                                        ) : (
                                          <>
                                            {Aptreason.ReasonId === 13 &&
                                            PractiesDetails.NewPatientAptMsg ===
                                              1 ? (
                                              <Button
                                                className="slot_button slot-width-w"
                                                onClick={() => {
                                                  dispatch(
                                                    UpdateApiData(
                                                      "selectedslot",
                                                      item.AptTime
                                                    )
                                                  );
                                                  dispatch(
                                                    UpdateApiData(
                                                      "AptLength",
                                                      item.AptLength
                                                    )
                                                  );
                                                  handleOpen();
                                                  setInfomsg(false);
                                                }}
                                              >
                                                {convertSecondsToHMS(
                                                  item.AptTime
                                                )}
                                              </Button>
                                            ) : (
                                              <Button
                                                className="slot_button slot-width-w"
                                                onClick={() => {
                                                  setNoInfomsg(false);
                                                  dispatch(
                                                    UpdateApiData(
                                                      "selectedslot",
                                                      item.AptTime
                                                    )
                                                  );
                                                  dispatch(
                                                    UpdateApiData(
                                                      "AptLength",
                                                      item.AptLength
                                                    )
                                                  );
                                                  sethidecal(false);
                                                  if (
                                                    Aptreason.PopStatus === "1"
                                                  ) {
                                                    setPopOpen(true);
                                                  } else {
                                                    slotcheck(item.AptTime);
                                                  }
                                                }}
                                              >
                                                {convertSecondsToHMS(
                                                  item.AptTime
                                                )}
                                              </Button>
                                            )}
                                          </>
                                        )}
                                      </>
                                    ))
                                  ) : (
                                    <>
                                      <Alert
                                        severity="error"
                                        className="align-item-center justify-content-center"
                                      >
                                        No appointment times available on this
                                        date.
                                      </Alert>
                                    </>
                                  )}
                                </div>
                              ) : (
                                <Alert
                                  severity="error"
                                  className="align-item-center justify-content-center"
                                >
                                  Appointments not available on this date.
                                </Alert>
                              )}
                            </>
                          )}
                        </div>

                      </div>
                    )}
                  </>
                ) : (
                  <div
                    className="d-flex align-middle justify-content-center h-100"
                    style={{
                      alignItems: "center",
                    }}
                  >
                    {loc_phone ? (
                      <Typography className="align-middle no_app justify-content-center text-center">
                        {doctor.ISDOCTOR}{" "}
                        {doctor.UserName ? doctor.UserName : doctor.USERNAME} is
                        not currently accepting {Aptreason.MainReason} online.
                        Please call us on{" "}
                        <a href={`tel:${loc_phone}`}>{loc_phone}</a>
                      </Typography>
                    ) : (
                      <div className="d-flex align-items-center loader2 justify-content-center mt-4">
                        <CircularProgress />
                      </div>
                    )}
                  </div>
                )}
                {hidecal && (
                  <div className="cal-size">
                    <Calendar
                      minDate={new Date()}
                      maxDate={new Date(Date.now() + 13 * 24 * 60 * 60 * 1000)}
                      tileDisabled={tileDisabled}
                      tileClassName={({ date }) => {
                        const formattedDate = moment(date).format("DD-MM-YYYY");

                        const selectedDateFormatted = moment(
                          dateclass,
                          "DD-MM-YYYY"
                        ).format("DD-MM-YYYY");
                        if (dateclass === formattedDate) {
                          return "selecteddate";
                        }
                        if (mark.find((x) => x === formattedDate)) {
                          return "highlight";
                        }
                      }}
                      navigationLabel={({ date, label, locale, view }) => (
                        <div className="test" onClick={() => sethidecal(false)}>
                          {moment(date).format(" MMMM, YYYY")}
                        </div>
                      )}
                      onChange={(date: any) => {
                        dispatch(UpdateApiData("selecteddate", date));
                        Cookies.set("selecteddate", date);
                        sethidecal(false);
                        onChange(date);
                        //  window.location.reload();
                      }}
                      onClickMonth={handleMonthChange}
                      onClickYear={handleMonthChange}
                      // activeStartDate={value}
                      defaultActiveStartDate={value}
                      // defaultValue={new Date(2023,11,12)}
                      prev2Label=""
                      next2Label=""
                      value={value}
                      view="month"
                    />
                  </div>
                )}
              </div>
            </Box>
          </main>
        </div>
      )}
    </div>
  );
};
