import React, { useEffect, useState } from "react";
import {
    Breadcrumbs,
    Link,
    Typography,
    Divider,
    CircularProgress,

} from "@mui/material";
import { Col, Container, Row, Image, Button, Table } from "react-bootstrap";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate, useParams } from "react-router-dom";
import { getApi } from "../../services";
import Cookies from 'js-cookie';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import PublicIcon from '@mui/icons-material/Public';
 import FacebookIcon from '@mui/icons-material/Facebook';
 import InstagramIcon from '@mui/icons-material/Instagram';
import { ApiState, PaymentState as SearchReduxState, useSelector } from "../../redux";
import {
    useMediaQuery,
    useTheme,
} from "@mui/material";
export const PracticeInformation: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const getapi = getApi();
    const theme = useTheme();
    const navigate = useNavigate();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const Apistate = useSelector<ApiState>(
        (state) => state.ApiReducer
    );

    useEffect(() => {
        getDatas()
    }, [])



    const [timedata, settimeData] = useState<any>([]);
    const [LANGdata, setLANGdata] = useState<any>([]);
    const [ACCdata, setACCdata] = useState<any>([]);
    const [SPLdata, setSPLdata] = useState<any>([]);
    const [FACdata, setFACdata] = useState<any>([]);
    const [Webdata, setWebdata] = useState<any>([]);




    const getDatas = async () => {
        setLoading(true);

        try {
            let data = {
                location_id: Apistate.location_id,
            };

            const res = await getapi.post("getpracticeinfo/", data);
            setLoading(false);
            //  console.log('getpracticeinfo details', res.data.data);
            //  console.log('LANGdata details-1', res.data.data[1].LANGdata[0]);
            setWebdata(res.data.data[0].Webdata);
              setLANGdata(res.data.data[1].LANGdata)
              setACCdata(res.data.data[2].ACCdata)
            settimeData(res.data.data[3].TIMEdata)
              setSPLdata(res.data.data[4].SPLdata)
               setFACdata(res.data.data[5].FACdata)
        } catch (err) {
            // Handle error if needed
        }
    }



    const openGoogleMaps = () => {

        const location = Cookies.get('Address');

        if (location !== undefined) {
            // Construct the Google Maps URL
            const googleMapsUrl = `https://www.google.com/maps?q=${location}`;
            // Open the Google Maps URL in a new tab
            window.open(googleMapsUrl, "_blank");
        } else {
            // Handle the case where the cookie 'Location_Name' doesn't exist
        }

    }

    const opening = (value: string) => {
        // Use regex to match the day name and the time range
        let match = value.match(/^(.*?)(\d{1,2}:\d{2}[ap]m - \d{1,2}:\d{2}[ap]m)$/);
        if (!match) {
            return { day: '', startTime: '', endTime: '' };
        }

        let day = match[1].trim();
        let timeRange = match[2];

        // Split the time range into start time and end time
        let timeParts = timeRange.split(' - ');
        let startTime = timeParts[0];
        let endTime = timeParts[1];

        return { day, startTime, endTime };
    };
    // useEffect(() =>{
    // console.log('FACdata',FACdata[0].FACNAME)
    // console.log('ACCdata',ACCdata[0].ACCNAME)
    // },[FACdata])

    return (
        <div>
            {loading ? (
                <div className="d-flex align-items-center loader justify-content-center">
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <section className="fvjobdetailheaderbgcolor"></section>

                    <main className="fvjobdetailheaderbgmain practice_info">
                        {!matches ?
                            <Container>
                                <Row>
                                    <Col lg={12} className="left-content mx-auto">


                                        <Box
                                            component={Paper}
                                            elevation={2}
                                            p={3}
                                            sx={{ mb: 2 }}
                                        >
                                            <div style={{ float: 'left' }} className="text-right text-align-left mt-2 justify-content-left align-item-left">

                                                <Link
                                                    className="breadcrumb"
                                                    underline="hover"
                                                    key="1"
                                                    color="inherit"
                                                    onClick={() => navigate(-1)}
                                                >
                                                    <ChevronLeftIcon sx={{ mr: 0.5 }} />
                                                    Back

                                                </Link>

                                            </div>
                                            <Typography className="bannertitle" variant="h6" component={'h6'}>Practice Information</Typography>
                                        </Box>
                                        <Row>
                                        {Webdata && Webdata.length > 0 && (
                                            <Col md={4} className="mb-3">
                                                {Webdata && Webdata.length > 0 && (
                                                    <Box
                                                        className="h-100"
                                                        component={Paper}
                                                        elevation={2}
                                                        sx={{ borderRadius: '10px' }}
                                                        p={3}
                                                    >



                                                        <Row>
                                                            <Col md={12}>
                                                                <Typography className="pgetitle mb-3" variant="h6" component={'h6'}>Contact Details</Typography>

                                                                {Webdata && Webdata.length > 0 && Webdata.map((value: any, idx: any) =>
                                                                    <>
                                                                        {value.LOCATION_BULDING_NAME && (
                                                                            <div style={{ cursor: 'pointer' }} className="d-flex mb-3" onClick={() => openGoogleMaps()}>
                                                                                {/* <Button
                                                                                    style={{
                                                                                        padding: 2,
                                                                                        marginRight: 10,
                                                                                        height: 'fit-content'
                                                                                    }}
                                                                                >
                                                                                    <LocationOnIcon />
                                                                                </Button> */}
                                                                                {Apistate.location_id == 'S25880ANA' ?
                                                                                 <Image
                                                                                 style={{ width: '38px', marginRight: 10, height: 'fit-content' }}
                                                                                 src={require("../../assets/images/pin.png")}
                                                                             />
                                                                                :
                                                                               
                                                                                <Image
                                                                                    style={{ width: '38px', marginRight: 10, height: 'fit-content' }}
                                                                                    src={require("../../assets/images/pin 1.png")}
                                                                                />
                                                                            }
                                                                                <Typography>{value.LOCATION_BULDING_NAME}</Typography>





                                                                                {/* <Typography>Suite 4/73 Gibson Avenue Padbury,WA 6025</Typography> */}
                                                                            </div>
                                                                        )}
                                                                        {value.PHONENUMBER && (
                                                                            <div onClick={() => window.location.href = `tel:${value.PHONENUMBER.replace(/[\s()-]/g, '')}`} className="d-flex mb-3">
                                                                                {/* <Button
                                                                                    style={{
                                                                                        padding: 2,
                                                                                        marginRight: 10,
                                                                                        height: 'fit-content'
                                                                                    }}
                                                                                >
                                                                                    <PhoneInTalkIcon />
                                                                                </Button> */}
                                                                               
                                                                                {Apistate.location_id == 'S25880ANA' ?
                                                                                
                                                                                <Image
                                                                                    style={{ width: '34px', marginRight: 10,height: 'fit-content' }}
                                                                                    src={require("../../assets/images/viber.png")}
                                                                                />
                                                                                :
                                                                                <Image
                                                                                    style={{ width: '34px', marginRight: 10,height: 'fit-content' }}
                                                                                    src={require("../../assets/images/viber 1.png")}
                                                                                />
                                                                            }
                                                                                <Typography>{value.PHONENUMBER}</Typography>

                                                                            </div>
                                                                        )}
                                                                        {value.WEBSITE && (
                                                                            <div style={{ cursor: 'pointer' }} onClick={() => window.open(value.WEBSITE, '_blank')} >
                                                                                <Typography sx={{ mb: 3 }}>
                                                                                    {/* <Button
                                                                                        style={{
                                                                                            padding: 2,
                                                                                            marginRight: 10
                                                                                        }}
                                                                                    >
                                                                                        <PublicIcon />
                                                                                    </Button> */}
                                                                                    {Apistate.location_id == 'S25880ANA' ?
                                                                                    <Image
                                                                                        style={{ width: '34px', marginRight: 10,height: 'fit-content' }}
                                                                                        src={require("../../assets/images/web.png")}
                                                                                    />
                                                                                    :
                                                                                    <Image
                                                                                        style={{ width: '34px', marginRight: 10,height: 'fit-content' }}
                                                                                        src={require("../../assets/images/globe 1.png")}
                                                                                    />
                                                                                }
                                                                                    {value.WEBSITE.split(/https?:\/\/(?:www\.)?/).filter((url: any) => url.trim() !== '')}
                                                                                </Typography>
                                                                            </div>
                                                                        )}
 {/* social media  */}
                                 {/* <Typography className="pgetitle mb-3" variant="h6" component={'h6'}>Social Media</Typography>
                                <div>
                                {Apistate.location_id == 'S25880ANA' ?
                                 <Image style={{ width: '33px', marginRight: 10,height: 'fit-content' }}
                                 src={require("../../assets/images/p-facebook.png")}
                           />
                                :
                                <Image style={{ width: '33px', marginRight: 10,height: 'fit-content' }}
                                       src={require("../../assets/images/facebook.png")}
                                 />
                                }
                                {Apistate.location_id == 'S25880ANA' ?
                                <Image style={{ width: '34px', marginRight: 10, height: 'fit-content' }}
                                src={require("../../assets/images/p-instagram.png")}
                                />
                                :
                                <Image style={{ width: '34px', marginRight: 10, height: 'fit-content' }}
                                src={require("../../assets/images/instagram.png")}
                                />
                            }
                            

</div>  */}
                                                                    </>
                                                                )}
                                                            </Col>

                                                        </Row>
                                                    </Box>
                                                )}

                                            </Col>
                                        )}
                                            {timedata && timedata.length > 0 && timedata[0].DAYNAME && (
                                                <Col md={4} className="mb-3">
                                                    <Box
                                                        sx={{ borderRadius: '10px' }}
                                                        className="h-100"
                                                        component={Paper}
                                                        elevation={2}
                                                        p={3}
                                                    >
                                                        <Typography className="pgetitle" variant="h6" component={'h6'}>Opening Hours</Typography>

                                                        <Table borderless className="openhrs">


                                                            {timedata && timedata.length > 0 && timedata.map((value: any, idx: any) => {
                                                                // Extract DAYNAME from value
                                                                const { DAYNAME } = value;

                                                                // Call opening function with DAYNAME value
                                                                const { day, startTime, endTime } = opening(DAYNAME);

                                                                return (
                                                                    <tr key={idx}>
                                                                        <td>{day}</td>
                                                                        <td>{startTime} - {endTime}</td>

                                                                    </tr>
                                                                );
                                                            })}



                                                        </Table>
                                                    </Box>
                                                </Col>
                                            )}
                                            {SPLdata && SPLdata.length > 0 && SPLdata[0].SPLNAME && (
                                                <Col md={4} className="mb-3">
                                                    <Box
                                                        sx={{ borderRadius: '10px' }}
                                                        className="h-100"
                                                        component={Paper}
                                                        elevation={2}
                                                        p={3}
                                                    >
                                                        <Typography className="pgetitle" variant="h6" component={'h6'}>Special Interests</Typography>

                                                        {SPLdata && SPLdata.length > 0 && SPLdata.map((value: any, idx: any) =>
                                                            <Typography sx={{ mt: 1 }}>{value.SPLNAME}</Typography>
                                                        )}
                                                    </Box>
                                                </Col>
                                            )}
                                            {FACdata && FACdata.length > 0 && FACdata[0].FACNAME && (
                                                <Col md={4} className="mb-3">
                                                    <Box
                                                        sx={{ borderRadius: '10px' }}
                                                        className="h-100"
                                                        component={Paper}
                                                        elevation={2}
                                                        p={3}
                                                    >
                                                        <Typography className="pgetitle" variant="h6" component={'h6'}>Facilities</Typography>
                                                        {FACdata.FACNAME}
                                                        {FACdata && FACdata.length > 0 && FACdata.map((value: any, idx: any) =>
                                                            <Typography sx={{ mt: 1 }}>{value.FACNAME}</Typography>
                                                        )}
                                                    </Box>
                                                </Col>
                                            )}
                                            {ACCdata && ACCdata.length >= 1 && ACCdata[0].ACCNAME && (
                                                <Col md={4} className="mb-3">
                                                    <Box
                                                        sx={{ borderRadius: '10px' }}
                                                        className="h-100"
                                                        component={Paper}
                                                        elevation={2}
                                                        p={3}
                                                    >
                                                        <Typography className="pgetitle acc" variant="h6" component={'h6'}>Accreditations</Typography>

                                                        {ACCdata && ACCdata.length > 0 && ACCdata.map((value: any, idx: any) =>
                                                            <Typography sx={{ mt: 1 }}>{value.ACCNAME}</Typography>
                                                        )}
                                                    </Box>
                                                </Col>
                                            )}
                                            {LANGdata && LANGdata.length > 0 && LANGdata[0].LANGNAME && (
                                                <Col md={4} className="mb-3">
                                                    <Box
                                                        sx={{ borderRadius: '10px' }}
                                                        className="h-100"
                                                        component={Paper}
                                                        elevation={2}
                                                        p={3}
                                                    >
                                                        <Typography className="pgetitle" variant="h6" component={'h6'}>Languages Spoken</Typography>

                                                        {LANGdata && LANGdata.length > 0 && LANGdata.map((value: any, idx: any) =>
                                                            <Typography sx={{ mt: 1 }}>{value.LANGNAME}</Typography>
                                                        )}
                                                    </Box>
                                                </Col>
                                            )}
                                        </Row>




                                    </Col>
                                </Row>
                            </Container>
                            :
                            // <Container>
                            //     <Row>
                            //         <Col lg={12} className="left-content mx-auto">

                            //             <Box
                            //                 className="h-100"
                            //                 component={Paper}
                            //                 elevation={2}
                            //                 p={3}
                            //             >

                            //                 <div style={{ float: 'left' }} className="text-right text-align-left justify-content-left align-item-left">
                            //                     <Link
                            //                         className="breadcrumb"
                            //                         underline="hover"
                            //                         key="1"
                            //                         color="inherit"
                            //                         onClick={() => navigate(-1)}
                            //                     >
                            //                         <ChevronLeftIcon sx={{ mr: 0.5 }} />
                            //                         Back
                            //                     </Link>
                            //                 </div>

                            //                 <Typography className="pgetitle headpgetitle" variant="h6" component={'h6'}>Practice Information</Typography>
                            //                 <Divider
                            //                     className="hr-profile"
                            //                     variant="middle"
                            //                 />

                            //                 <Row>
                            //                     {Webdata && Webdata.length > 0 && Webdata.map((value: any, idx: any) =>

                            //                         <Col>
                            //                             <Typography className="pgetitle mb-2" variant="h6" component={'h6'}>Contact details</Typography>
                            //                             {value.LOCATION_BULDING_NAME && (
                            //                                 <div style={{ cursor: 'pointer' }} className="d-flex mb-2" onClick={() => openGoogleMaps()}>
                            //                                     <Button
                            //                                         style={{
                            //                                             padding: 2,
                            //                                             marginRight: 10,
                            //                                             height: 'fit-content'
                            //                                         }}
                            //                                     >
                            //                                         <LocationOnIcon />
                            //                                     </Button>
                            //                                     <Typography >{value.LOCATION_BULDING_NAME} </Typography>
                            //                                 </div>
                            //                             )}
                            //                             {value.PHONENUMBER && (
                            //                                 <div onClick={() => window.location.href = `tel:${value.PHONENUMBER}`} className="d-flex mb-2">
                            //                                     <Button
                            //                                         style={{
                            //                                             padding: 2,
                            //                                             marginRight: 10,
                            //                                             height: 'fit-content'
                            //                                         }}
                            //                                     >
                            //                                         <PhoneInTalkIcon />
                            //                                     </Button>
                            //                                     <Typography>{value.PHONENUMBER}</Typography>
                            //                                 </div>
                            //                             )}
                            //                             {value.WEBSITE && (
                            //                                 <div style={{ cursor: 'pointer' }} onClick={() => window.open(value.WEBSITE, '_blank')} >
                            //                                     <Typography sx={{ mb: 2 }}>
                            //                                         <Button
                            //                                             style={{
                            //                                                 padding: 2,
                            //                                                 marginRight: 10
                            //                                             }}
                            //                                         >
                            //                                             <PublicIcon />
                            //                                         </Button>


                            //                                         {value.WEBSITE.split(/https?:\/\/(?:www\.)?/).filter((url: any) => url.trim() !== '')}
                            //                                     </Typography>
                            //                                 </div>
                            //                             )}
                            //                         </Col>
                            //                     )}
                            //                 </Row>
                            //                 {timedata && timedata.length > 0 && timedata[0].DAYNAME && (
                            //                     <Row>

                            //                         <Col>
                            //                             <Typography className="pgetitle" variant="h6" component={'h6'}>Opening hours</Typography>
                            //                             <Table borderless className="openhrs" style={{ maxWidth: '50%' }}>
                            //                                 {timedata && timedata.length > 0 && timedata.map((value: any, idx: any) =>
                            //                                     <tr>
                            //                                         <td>
                            //                                             {value.DAYNAME}
                            //                                         </td>
                            //                                         <td>
                            //                                             {value.OPENING}
                            //                                         </td>
                            //                                     </tr>

                            //                                 )}
                            //                             </Table>
                            //                         </Col>
                            //                     </Row>
                            //                 )}
                            //                 {ACCdata && ACCdata.length > 0 && ACCdata[0].ACCNAME &&
                            //                     (
                            //                         <Row>
                            //                             <Col>
                            //                                 <Typography className="pgetitle" variant="h6" component={'h6'}>Accreditations</Typography>

                            //                                 {ACCdata && ACCdata.length > 0 && ACCdata.map((value: any, idx: any) =>
                            //                                     <Typography sx={{ mt: 2 }}> {value.ACCNAME} </Typography>
                            //                                 )}
                            //                             </Col>
                            //                         </Row>
                            //                     )}
                            //                 {LANGdata && LANGdata.length > 0 && LANGdata[0].LANGNAME && (
                            //                     <Row>
                            //                         <Col>
                            //                             <Typography className="pgetitle" variant="h6" component={'h6'}>Languages spoken</Typography>

                            //                             {LANGdata && LANGdata.length > 0 && LANGdata.map((value: any, idx: any) =>
                            //                                 <Typography sx={{ mt: 2 }}>{value.LANGNAME}</Typography>
                            //                             )}
                            //                         </Col>
                            //                     </Row>
                            //                 )}
                            //                 {FACdata && FACdata.length > 0 && FACdata[0].FACNAME && (
                            //                     <Row>
                            //                         <Col>
                            //                             <Typography className="pgetitle mt-2" variant="h6" component={'h6'}>Facilities</Typography>

                            //                             {FACdata && FACdata.length > 0 && FACdata.map((value: any, idx: any) =>
                            //                                 <Typography sx={{ mt: 2 }}>{value.FACNAME}</Typography>
                            //                             )}
                            //                         </Col>
                            //                     </Row>
                            //                 )}
                            //                 {SPLdata && SPLdata.length > 0 && SPLdata[0].SPLNAME && (
                            //                     <Row>
                            //                         <Col>
                            //                             <Typography className="pgetitle mt-2" variant="h6" component={'h6'}>Special interests</Typography>

                            //                             {SPLdata && SPLdata.length > 0 && SPLdata.map((value: any, idx: any) =>
                            //                                 <Typography sx={{ mt: 2 }}>{value.SPLNAME}</Typography>
                            //                             )}

                            //                         </Col>
                            //                     </Row>
                            //                 )}
                            //                 {/* <Row>
                            //                     <Col>
                            //                         <Typography className="pgetitle mt-2 mb-2" variant="h6" component={'h6'}>Social media</Typography>
                            //                         <div>

                            //                             <FacebookIcon sx={{
                            //                                 fontSize: 48,
                            //                                 color: '#3e7ebc'
                            //                             }}
                            //                             />
                            //                             <InstagramIcon
                            //                                 sx={{
                            //                                     fontSize: 48,

                            //                                     color: '#3e7ebc'

                            //                                 }}
                            //                             />

                            //                         </div>

                            //                     </Col>
                            //                 </Row>  */}






                            //             </Box>

                            //         </Col>
                            //     </Row>
                            // </Container>


                            <Container>
                            <Row>
                                <Col lg={12} className="left-content mx-auto">


                                    <Box
                                        component={Paper}
                                        elevation={2}
                                        p={3}
                                        sx={{ mb: 2 }}
                                    >
                                        <div style={{ float: 'left' }} className="text-right text-align-left mt-2 justify-content-left align-item-left">

                                            <Link
                                                className="breadcrumb"
                                                underline="hover"
                                                key="1"
                                                color="inherit"
                                                onClick={() => navigate(-1)}
                                            >
                                                <ChevronLeftIcon sx={{ mr: 0.5 }} />
                                                Back

                                            </Link>

                                        </div>
                                        <Typography className="bannertitle" variant="h6" component={'h6'}>Practice Information</Typography>
                                    </Box>
                                    <Row>
                                    {Webdata && Webdata.length > 0 && (
                                        <Col md={12} className="mb-3">
                                            {Webdata && Webdata.length > 0 && (
                                                <Box
                                                    className="h-100"
                                                    component={Paper}
                                                    elevation={2}
                                                    sx={{ borderRadius: '10px' }}
                                                    p={3}
                                                >



                                                    <Row>
                                                        <Col md={12}>
                                                            <Typography className="pgetitle mb-3" variant="h6" component={'h6'}>Contact Details</Typography>

                                                            {Webdata && Webdata.length > 0 && Webdata.map((value: any, idx: any) =>
                                                                <>
                                                                    {value.LOCATION_BULDING_NAME && (
                                                                        <div style={{ cursor: 'pointer' }} className="d-flex mb-3" onClick={() => openGoogleMaps()}>
                                                                            {/* <Button
                                                                                style={{
                                                                                    padding: 2,
                                                                                    marginRight: 10,
                                                                                    height: 'fit-content'
                                                                                }}
                                                                            >
                                                                                <LocationOnIcon />
                                                                            </Button> */}
                                                                           {Apistate.location_id == 'S25880ANA' ?
                                                                                 <Image
                                                                                 style={{ width: '38px', marginRight: 10, height: 'fit-content' }}
                                                                                 src={require("../../assets/images/pin.png")}
                                                                             />
                                                                                :
                                                                               
                                                                                <Image
                                                                                    style={{ width: '38px', marginRight: 10, height: 'fit-content' }}
                                                                                    src={require("../../assets/images/pin 1.png")}
                                                                                />
                                                                            }
                                                                            <Typography>{value.LOCATION_BULDING_NAME}</Typography>





                                                                            {/* <Typography>Suite 4/73 Gibson Avenue Padbury,WA 6025</Typography> */}
                                                                        </div>
                                                                    )}
                                                                    {value.PHONENUMBER && (
                                                                        <div onClick={() => window.location.href = `tel:${value.PHONENUMBER.replace(/[\s()-]/g, '')}`} className="d-flex mb-3">
                                                                            {/* <Button
                                                                                style={{
                                                                                    padding: 2,
                                                                                    marginRight: 10,
                                                                                    height: 'fit-content'
                                                                                }}
                                                                            >
                                                                                <PhoneInTalkIcon />
                                                                            </Button> */}
                                                                           {Apistate.location_id == 'S25880ANA' ?
                                                                                <Image
                                                                                    style={{ width: '34px', marginRight: 10,height: 'fit-content' }}
                                                                                    src={require("../../assets/images/viber.png")}
                                                                                />
                                                                                :
                                                                                <Image
                                                                                    style={{ width: '34px', marginRight: 10,height: 'fit-content' }}
                                                                                    src={require("../../assets/images/viber 1.png")}
                                                                                />
                                                                            }
                                                                            <Typography>{value.PHONENUMBER}</Typography>

                                                                        </div>
                                                                    )}
                                                                    {value.WEBSITE && (
                                                                        <div style={{ cursor: 'pointer' }} onClick={() => window.open(value.WEBSITE, '_blank')} >
                                                                            <Typography sx={{ mb: 3 }}>
                                                                                {/* <Button
                                                                                    style={{
                                                                                        padding: 2,
                                                                                        marginRight: 10
                                                                                    }}
                                                                                >
                                                                                    <PublicIcon />
                                                                                </Button> */}
                                                                             {Apistate.location_id == 'S25880ANA' ?
                                                                                    <Image
                                                                                        style={{ width: '34px', marginRight: 10,height: 'fit-content' }}
                                                                                        src={require("../../assets/images/web.png")}
                                                                                    />
                                                                                    :
                                                                                    <Image
                                                                                        style={{ width: '34px', marginRight: 10,height: 'fit-content' }}
                                                                                        src={require("../../assets/images/globe 1.png")}
                                                                                    />
                                                                                }
                                                                                {value.WEBSITE.split(/https?:\/\/(?:www\.)?/).filter((url: any) => url.trim() !== '')}
                                                                            </Typography>
                                                                        </div>
                                                                    )}
 {/* social media */}
                             {/* <Typography className="pgetitle mb-3" variant="h6" component={'h6'}>Social Media</Typography>
                            <div>
                                {Apistate.location_id == 'S25880ANA' ?
                                 <Image style={{ width: '33px', marginRight: 10,height: 'fit-content' }}
                                 src={require("../../assets/images/p-facebook.png")}
                           />
                                :
                                <Image style={{ width: '33px', marginRight: 10,height: 'fit-content' }}
                                       src={require("../../assets/images/facebook.png")}
                                 />
                                }
                                {Apistate.location_id == 'S25880ANA' ?
                                <Image style={{ width: '34px', marginRight: 10, height: 'fit-content' }}
                                src={require("../../assets/images/p-instagram.png")}
                                />
                                :
                                <Image style={{ width: '34px', marginRight: 10, height: 'fit-content' }}
                                src={require("../../assets/images/instagram.png")}
                                />
                            }
                            

</div>  */}
                                                                </>
                                                            )}
                                                        </Col>

                                                    </Row>
                                                </Box>
                                            )}

                                        </Col>
                                    )}
                                        {timedata && timedata.length > 0 && timedata[0].DAYNAME && (
                                            <Col md={12} className="mb-3">
                                                <Box
                                                    sx={{ borderRadius: '10px' }}
                                                    className="h-100"
                                                    component={Paper}
                                                    elevation={2}
                                                    p={3}
                                                >
                                                    <Typography className="pgetitle" variant="h6" component={'h6'}>Opening Hours</Typography>

                                                    <Table borderless className="openhrs">


                                                        {timedata && timedata.length > 0 && timedata.map((value: any, idx: any) => {
                                                            // Extract DAYNAME from value
                                                            const { DAYNAME } = value;

                                                            // Call opening function with DAYNAME value
                                                            const { day, startTime, endTime } = opening(DAYNAME);

                                                            return (
                                                                <tr key={idx}>
                                                                    <td>{day}</td>
                                                                    <td>{startTime} - {endTime}</td>

                                                                </tr>
                                                            );
                                                        })}



                                                    </Table>
                                                </Box>
                                            </Col>
                                        )}
                                        {SPLdata && SPLdata.length > 0 && SPLdata[0].SPLNAME && (
                                            <Col md={12} className="mb-3">
                                                <Box
                                                    sx={{ borderRadius: '10px' }}
                                                    className="h-100"
                                                    component={Paper}
                                                    elevation={2}
                                                    p={3}
                                                >
                                                    <Typography className="pgetitle" variant="h6" component={'h6'}>Special Interests</Typography>

                                                    {SPLdata && SPLdata.length > 0 && SPLdata.map((value: any, idx: any) =>
                                                        <Typography sx={{ mt: 1 }}>{value.SPLNAME}</Typography>
                                                    )}
                                                </Box>
                                            </Col>
                                        )}
                                        {FACdata && FACdata.length > 0 && FACdata[0].FACNAME && (
                                            <Col md={12} className="mb-3">
                                                <Box
                                                    sx={{ borderRadius: '10px' }}
                                                    className="h-100"
                                                    component={Paper}
                                                    elevation={2}
                                                    p={3}
                                                >
                                                    <Typography className="pgetitle" variant="h6" component={'h6'}>Facilities</Typography>
                                                    {FACdata.FACNAME}
                                                    {FACdata && FACdata.length > 0 && FACdata.map((value: any, idx: any) =>
                                                        <Typography sx={{ mt: 1 }}>{value.FACNAME}</Typography>
                                                    )}
                                                </Box>
                                            </Col>
                                        )}
                                        {ACCdata && ACCdata.length >= 1 && ACCdata[0].ACCNAME && (
                                            <Col md={12} className="mb-3">
                                                <Box
                                                    sx={{ borderRadius: '10px' }}
                                                    className="h-100"
                                                    component={Paper}
                                                    elevation={2}
                                                    p={3}
                                                >
                                                    <Typography className="pgetitle acc" variant="h6" component={'h6'}>Accreditations</Typography>

                                                    {ACCdata && ACCdata.length > 0 && ACCdata.map((value: any, idx: any) =>
                                                        <Typography sx={{ mt: 1 }}>{value.ACCNAME}</Typography>
                                                    )}
                                                </Box>
                                            </Col>
                                        )}
                                        {LANGdata && LANGdata.length > 0 && LANGdata[0].LANGNAME && (
                                            <Col md={12} className="mb-3">
                                                <Box
                                                    sx={{ borderRadius: '10px' }}
                                                    className="h-100"
                                                    component={Paper}
                                                    elevation={2}
                                                    p={3}
                                                >
                                                    <Typography className="pgetitle" variant="h6" component={'h6'}>Languages Spoken</Typography>

                                                    {LANGdata && LANGdata.length > 0 && LANGdata.map((value: any, idx: any) =>
                                                        <Typography sx={{ mt: 1 }}>{value.LANGNAME}</Typography>
                                                    )}
                                                </Box>
                                            </Col>
                                        )}
                                    </Row>




                                </Col>
                            </Row>
                        </Container>

                        }
                    </main>
                </>
            )
            }
        </div>
    )
}