import { ApiData } from "../../../models/apidata";
import { UpdateApiData, UPDATE_API_DATA,ARESET_DATA,AResetData } from "../actions";
export const initialState: ApiData = {
    Doctors_ListingOrder: 0,
    location_id: '',
    login_from: '',
    userid: '',
    selectedSlotCount: '',
    selecteddate: '',
    uniqueid: '',
    selectedslot:'',
    AptLength:'',
    SlotLog:1,
    SlotMsg:'',    
}

const ApiReducer = (state: ApiData = initialState, action: UpdateApiData | AResetData):ApiData => {
    switch (action.type) {
        case UPDATE_API_DATA:
            return {

                ...state,
                [action.key]: action.value

            }
            case ARESET_DATA:
                return {
                    ...initialState,
                    location_id: state.location_id, // Preserve location_id from the initial state
                    login_from: state.login_from
                }
        default:
            return state
    }
}

export default ApiReducer