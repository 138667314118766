import React, { Fragment, useEffect, useRef, useState } from "react";
import './style.css'
import { useNavigate } from "react-router-dom";
import { getApi } from '../../services';
import CircularProgress from '@mui/material/CircularProgress';

import { ApiState, MobileState as SearchReduxState, themeReducer, useSelector } from "../../redux";
import { useDispatch } from "react-redux";
import { Resetdata, UpdatePaymentData } from "../../redux/User/actions";
import { useParams } from 'react-router-dom';
import { UpdateMobileData } from "../../redux/MobileApp/actions";
import { setThemeColor } from "../../redux/theme/action/themeActions";
import { UpdateApiData } from "../../redux/Api/actions";


export const SplashScreen: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [showSplash, setShowSplash] = useState(true);
    const { lid } = useParams<{ uuid: string; lid: string }>();
    const [location_id,setlocationid]=useState(lid? atob(lid):'')
    // Redux
  const searchRedux = useSelector<SearchReduxState>((state) => state.mobileReducer);
  const apistate = useSelector<ApiState>((state) => state.ApiReducer)
  const dispatch = useDispatch();
  const getapi = getApi();
  const navigate = useNavigate();


    useEffect(() => {
      //  console.log('searchredux',searchRedux)
        if (apistate.location_id === 'S25880ANA'){
          dispatch(setThemeColor('pink'));
          }
           else{
            dispatch(setThemeColor('blue'));
           }
        const timeout = setTimeout(() => {
           // setShowSplash(false);
        }, 2000);

        // Clean up the timeout to avoid memory leaks
        return () => clearTimeout(timeout);
    }, [searchRedux]);

    /* store the pharms in redux */
    useEffect(() =>{

         if( lid){
       
            dispatch(UpdateApiData('location_id',atob(lid)))
         }
    },[lid])


    /* get the cookie value */
    const getCookie = (name: string): string | null => {
        const cookies = document.cookie?.split(';').map(cookie => cookie.trim());
        
        for (const cookie of cookies) {
          const [cookieName, cookieValue] = cookie?.split('=');
          
          if (cookieName === name) {
            return decodeURIComponent(cookieValue);
          }
        }
        
        return null; // Cookie not found
      };
  
    useEffect(() => {
      practicedetails()
        const cooki:any = getCookie('userCookie');
        const usercooki = cooki?.split('_');
        dispatch(UpdateMobileData('location_id',location_id))
       // dispatch(UpdateMobileData('UUID',uuid))
        dispatch(UpdateMobileData('LoginFrom','MobileApp'))
      
        if (location_id) {
          localStorage.setItem('location_id', location_id);
          dispatch(UpdateMobileData('location_id',location_id))
         // localStorage.setItem('uuid', uuid);
        } else {
          // Handle the case where values are undefined
          console.error('Values are undefined, cannot store in localStorage.');
        }
       
      if(usercooki){
      //  console.log('Param 3:',usercooki[3]);
        dispatch(UpdateMobileData('phone_no',usercooki[3] && usercooki[3]))
        if( usercooki[0] && location_id === usercooki[1] )
        {
         // console.log('pharams matched')
          /* need to check the patients list then redirect */
          navigate('/patientslist')
        }
        else{
        //  console.log('pharams not matched')
          navigate('/login')
        }
      }
      else{
       // console.log('pharams not matched')
        navigate('/login')
      }

      }, [location_id]);



      /* practicedetails get function */

const practicedetails = async () => {
  setLoading(true);

  try {
    let data = {
      location_id: location_id,
    };

    const res = await getapi.post("practicedetails/", data);
//console.log('practicedetails',res)

   // console.log('length',res.data.data.length)
   dispatch(UpdateMobileData('LoginFrom','MobileApp'))
        dispatch(UpdatePaymentData("Is_ServerDown_Msg", res.data.data[0].Is_ServerDown_Msg));

        dispatch(UpdatePaymentData("BTN_1_URL", res.data.data[0].BTN_1_URL));
        dispatch(UpdatePaymentData("BTN_1", res.data.data[0].BTN_1));
        dispatch(
          UpdatePaymentData("Location_Name", res.data.data[0].Location_Name)
        );
        dispatch(
          UpdatePaymentData("location_id", res.data.data[0].Location_Id)
        );
        dispatch(
          UpdatePaymentData("general_note", res.data.data[0].General_Note)
        );
        dispatch(
          UpdatePaymentData("New_PatientMsg_No", res.data.data[0].New_PatientMsg_No)
        );    

        dispatch(
          UpdatePaymentData("New_PatientMsg", res.data.data[0].New_PatientMsg)
        );  
        dispatch(
          UpdatePaymentData("NewPatientAptMsg", res.data.data[0].NewPatientAptMsg)
        );    

        dispatch(
          UpdatePaymentData("Location_mobile", res.data.data[0].Phone_Number)
        );
        dispatch(
          UpdatePaymentData("Doctors_ListingOrder", res.data.data[0].Doctors_ListingOrder)
        );


        if (res.data.Success){
          setLoading(false);
   
        }
       // getData();
  
  } catch (err) {
    // Handle the error
    console.log("error", err);
    setLoading(false);
  }
};

    return (
        <Fragment>
            <div className={`splash-screen ${showSplash ? 'visible' : 'hidden'}`}>
                {/* Your splash screen content */}
               <div>
                <img
                    alt=""
                    src={require("../../assets/images/mobile/small_logo.png")}
                    className="d-inline-flex align-left header-logo"
                />
                     <span className='LocName text-white'>CODD Appointments</span> 
              </div>
              <div className="sp_loader">
              <CircularProgress sx={{ color:"#fff" }} />
              </div>
            </div>
            
        </Fragment>
    )
}