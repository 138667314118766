import React, { useEffect, useRef, useState } from "react";
import {
  Breadcrumbs,
  Link,
  Typography,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { Col, Container, Row, Image, Button } from "react-bootstrap";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "react-calendar/dist/Calendar.css";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ApiState, PaymentState as SearchReduxState, useSelector } from "../../redux";
import { useDispatch } from "react-redux";
import { UpdatePaymentData } from "../../redux/User/actions";
import { getApi } from "../../services";
import moment from "moment";
import { UpdateApiData } from "../../redux/Api/actions";
//import * as z from 'zod';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

interface Defltvalue {
  firstName: string;
  surname: string;
  dateOfBirthDD: string;
  dateOfBirthMM: string;
  dateOfBirthYYYY: string;
  phoneNumber: string;
  gender: string;
}

export const AppointmentForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getapi = getApi();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [inputDob, setInputDob] = useState("");
  const [invalidDD, setinvalidDD] = useState("");
  const [invalidMM, setinvalidMM] = useState("");
  const [invalidYY, setinvalidYY] = useState("");
  const [currentKeycode, setcurrentKeycode] = useState("");
  const [doctor,setDoctor] = useState<any>([])

  // Redux
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );
  const Apistate = useSelector<ApiState>(
    (state) => state.ApiReducer
  );

  useEffect(() =>{
    return () => {
      dispatch(UpdateApiData('SlotLog',''));
    };
  },[])

  useEffect(() =>{
    // Retrieve the cookie value
    var cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)DoctorDetails\s*=\s*([^;]*).*$)|^.*$/, '$1');
    
    // If the cookie value exists, decode it and parse the JSON string back to an array
    if (cookieValue) {
      var retrievedArray = JSON.parse(decodeURIComponent(cookieValue));
   
      setDoctor(retrievedArray)
    } else {
    
    }
    },[])


  useEffect(() => {
    
   let res =  moment(Apistate.selecteddate).format("YYYY-MM-DD").replace(/[^a-zA-Z0-9 ]/g, "")

  }, [searchRedux]);

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .required("First name required")
      .min(1, "First name must be at least 1 characters")
      .max(25, "First name must not exceed 25 characters"),
    surname: yup
      .string()
      .trim()
      .required("Surname required")
      .min(1, "Surname must be at least 1 characters")
      .max(25, "Surname must not exceed 25 characters"),

    dateOfBirthDD: yup
      .string()
      .required("Date required")
      .max(2, "Invalid Day"),

    dateOfBirthMM: yup
      .string()
      .required("Month required")
      .max(2, "Invalid Month"),

    dateOfBirthYYYY: yup
      .string()
      .required("Year required")
      .max(4, "Invalid Year")
      .min(4, "Invalid Year")
      .test('starts-with-zero', 'Year cannot start with 0', value => {
        if (value && value.startsWith('0')) {
          return false; // Fails validation if starts with 0
        }
        return true; // Passes validation otherwise
      }),

    phoneNumber: yup
      .string()
      .required("Phone number required")
      .matches(/^[0-9]{10}$/, "Invalid phone number"),
    gender: yup.string().required("Gender required"),
  });
  const {
    control,
    setValue,
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver<Defltvalue>(schema),
    // defaultValues: formData,
    //  validationSchema: schema,
  });

  const firstInputValue = watch("dateOfBirthDD");

  const validateDD = (event: any) => {
    const newValue = event.target.value;
    if (newValue > 31) {
      setinvalidDD("Invalid Day");
    } else {
      setinvalidDD("");
    }
  };

  const validateMM = (event: any) => {
    const newValue = event.target.value;
    if (newValue > 12) {
      setinvalidMM("Invalid Month");
    } else {
      setinvalidMM("");
    }
  };

  const validateYY = (event: any) => {
    const newValue = event.target.value;
    if (newValue.startsWith('0')) {
      setinvalidYY("Invalid Year");
    }

    if (
      newValue > moment(Apistate.selecteddate).format("YYYY") &&
      newValue < 1923
    ) {
      setinvalidYY("Invalid Year");
    } else {
      setinvalidYY("");
    }
  };

  const handleInputChangeDob = (event: any) => {
    const newValue = event.target.value;
    if (newValue.length == 2) {
      const ConcatnewValue = newValue + "/";
      setInputDob(ConcatnewValue);
    } else if (newValue.length == 5) {
      const ConcatnewValue = newValue + "/";
      setInputDob(ConcatnewValue);
    } else {
      const ConcatnewValue = newValue;
      setInputDob(ConcatnewValue);
    }
  };
const [error,seterror] = useState(false)
const [errormsg,seterrormsg] = useState('')

  const onSubmit: SubmitHandler<Defltvalue> = (data: any) => {
 
    let res =  moment(Apistate.selecteddate).format("YYYY-MM-DD").replace(/[^a-zA-Z0-9 ]/g, "")
    let sdata = {
      booked_from:Apistate.login_from[1] === 'book' ? 1 : 0,
      location_id_main: Apistate.location_id,
      date_time_id: res+Apistate.selectedslot,
      bdate: moment(Apistate.selecteddate).format("YYYY-MM-DD"),
      btime: Apistate.selectedslot,
      blength: Apistate.AptLength,
      location_id: Apistate.location_id,
      user_id: Apistate.userid ? Apistate.userid :Cookies.get("userid"),
      pfirstname: data.firstName,
      psurname: data.surname,
      pdob: moment(
        data.dateOfBirthYYYY +
          "-" +
          data.dateOfBirthMM +
          "-" +
          data.dateOfBirthDD
      ).format("YYYY-MM-DD"),
      pmobileno: data.phoneNumber,
      potp: "",
      bstatus: "",
      aptstype: "",
      aptsreason: searchRedux.selectedreason,
      sex: data.gender,
      bookingtime: "",
      medicareno: "",
      email: "",
      street: "",
      suburb: "",
      postcode: "",
      layout_id: "",
    };
 //console.log('submited data:',JSON.stringify(sdata) )
 
    getapi
      .post("bookinginsert/", sdata)
      .then((res) => {
   
        dispatch(UpdateApiData("uniqueid", res.data.UserId));
       
        if (res.data.Status === 200) {
          navigate("/otp");
        }
        else{
          seterror(true)
          seterrormsg(res.data.Message)
        }
      })
      .catch((err) => {
        //console.log("error", err);
      });

    return;
  };

  const convertSecondsToHMS = (seconds: any) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    // Convert to 12-hour format

    let hours12 = hours % 12 || 12;
    hours12 = hours12 === 0 ? 12 : hours12; // Handle midnight (12 AM)

    const amPm = hours < 12 ? "AM" : "PM";

    return `${hours12.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${amPm.toString().padStart(2, "0")}`;
  };

  const inputRefs = [useRef(null), useRef(null), useRef(null)];

  const handleInputChange = (e: any, index: any) => {
    const input = e.target;
    const value = input.value;

    // Remove any non-digit characters
    const cleanedValue = value.replace(/\D/g, "");

    // Limit the input to two digits
    if (cleanedValue.length > 2) {
      if (index === 2) {
        input.value = cleanedValue.slice(0, 4);
      } else {
        input.value = cleanedValue.slice(0, 2);
      }


    } else {
      input.value = cleanedValue;
     
    }

    // Move to the next input field if available
    if (cleanedValue.length === 3 && index < inputRefs.length - 1) {
      const nextInputRef = inputRefs[index + 1];
      if (nextInputRef && nextInputRef.current) {
        (nextInputRef.current as HTMLInputElement).focus();
      }
    }
  };

  const validateDay = (event: any) => {
    
    const inputValue = event.target.value;
    var keyCode = event.keyCode || event.which;
    setcurrentKeycode(keyCode);
    if(keyCode ==48)keyCode=96;
    if(keyCode ==49)keyCode=97;
    if(keyCode ==50)keyCode=98;
    if(keyCode ==51)keyCode=99;
    if(keyCode ==52)keyCode=100;
    if(keyCode ==53)keyCode=101;
    if(keyCode ==54)keyCode=102;
    if(keyCode ==55)keyCode=103;
    if(keyCode ==56)keyCode=104;
    if(keyCode ==57)keyCode=105;
    if (inputValue == "") {
      if (
        keyCode == 96 ||
        keyCode == 97 ||
        keyCode == 98 ||
        keyCode == 99 ||
        keyCode == 8
      ) {
        return true;
      } else {
   
        event.preventDefault();
      }
    } else if (inputValue.length == 1) {
      if (inputValue == "0") {
        if ((keyCode >= 97 && keyCode <= 105) || keyCode == 8) {
          return true;
        } else {
        
          event.preventDefault();
        }
      } else if (inputValue == "1") {
        if ((keyCode >= 90 && keyCode <= 105) || keyCode == 8) {
          return true;
        } else {
       
          event.preventDefault();
        }
      } else if (inputValue == "2") {
        if ((keyCode >= 96 && keyCode <= 105) || keyCode == 8) {
          return true;
        } else {
        
          event.preventDefault();
        }
      } else if (inputValue == "3") {
        if (keyCode == 96 || keyCode == 97 || keyCode == 8) {
          return true;
        } else {
        
          event.preventDefault();
        }
      }
    }
  };

  const validateMonth = (event: any) => {
    //  event.preventDefault();
    const inputValue = event.target.value;
    var keyCode = event.keyCode || event.which;
    setcurrentKeycode(keyCode);
    if(keyCode ==48)keyCode=96;
    if(keyCode ==49)keyCode=97;
    if(keyCode ==50)keyCode=98;
    if(keyCode ==51)keyCode=99;
    if(keyCode ==52)keyCode=100;
    if(keyCode ==53)keyCode=101;
    if(keyCode ==54)keyCode=102;
    if(keyCode ==55)keyCode=103;
    if(keyCode ==56)keyCode=104;
    if(keyCode ==57)keyCode=105;

    if (inputValue == "") {
      if (keyCode == 96 || keyCode == 97 || keyCode == 8) {
        return true;
      } else {
       
        event.preventDefault();
      }
    } else if (inputValue.length == 1) {
      if (inputValue == "0") {
        if ((keyCode >= 97 && keyCode <= 105) || keyCode == 8) {
          return true;
        } else {
        
          event.preventDefault();
        }
      } else if (inputValue == "1") {
        if (keyCode == 96 || keyCode == 97 || keyCode == 98 || keyCode == 8) {
          return true;
        } else {
         
          event.preventDefault();
        }
      }
    }
  };

  const validateYear = (event: any) => {
    //  event.preventDefault();

    var inputValue = event.target.value;
    var keyCode = event.keyCode || event.which;
    
    if(keyCode ==48)keyCode=96;
    if(keyCode ==49)keyCode=97;
    if(keyCode ==50)keyCode=98;
    if(keyCode ==51)keyCode=99;
    if(keyCode ==52)keyCode=100;
    if(keyCode ==53)keyCode=101;
    if(keyCode ==54)keyCode=102;
    if(keyCode ==55)keyCode=103;
    if(keyCode ==56)keyCode=104;
    if(keyCode ==57)keyCode=105;
    if (inputValue == "") {
      if (keyCode == 97 || keyCode == 98 || keyCode == 8) {
        return true;
      } else {
       
        event.preventDefault();
      }
    } else if (inputValue.length == 1) {
      inputValue = inputValue.charAt(0);
      if (inputValue == "1") {
        if (keyCode == 105 || keyCode == 8) {
          return true;
        } else {
         
          event.preventDefault();
        }
      } else if (inputValue == "2") {
        if (keyCode == 96 || keyCode == 8) {
          return true;
        } else {
        
          event.preventDefault();
        }
      }
    } else if (inputValue.length == 2) {
      inputValue = inputValue.charAt(0);

      if (inputValue == "1") {
      } else if (inputValue == "2") {
        if (keyCode == 96 || keyCode == 97 || keyCode == 98 || keyCode == 8) {
          return true;
        } else {
        
          event.preventDefault();
        }
      }
    } else if (inputValue.length == 3) {
      inputValue = inputValue.charAt(0);

      if (inputValue == "1") {

      } else if (inputValue == "2") {
        if (
          keyCode == 96 ||
          keyCode == 97 ||
          keyCode == 98 ||
          keyCode == 99 ||
          keyCode == 8
        ) {
          return true;
        } else {
         
        }
      }
    }

    


  };


  const handleInput = (e:any) => {
    const inputValue = e.target.value;
    const alphabeticValue = inputValue.replace(/[^A-Za-z\s]+/g, ''); // Remove non-alphabetic characters
    e.target.value = alphabeticValue; // Update the input value
  };

  return (
    <div>
      <section className="fvjobdetailheaderbgcolor"></section>
      {!matches ? (
        <main className="availableslots desktop_view">
          <Container>
            <Row className="row-eq-height">
              {/* col left starts  */}

              <Col lg={4}>
                <Box className="h-100" component={Paper} elevation={2} p={3}>
                  <div className="text-center align-center justify-content-center">
                    <Breadcrumbs separator="<" aria-label="breadcrumb">
                      <Link
                        className="breadcrumb"
                        underline="hover"
                        key="1"
                        color="inherit"
                        onClick={() => navigate(-1)}
                      >
                        <ChevronLeftIcon sx={{ mr: 0.5 }} />
                        Back
                      </Link>
                    </Breadcrumbs>
                    <br />

                    <Image
                      rounded
                      width={100}
                      height={100}
                      className="d-inline-flex align-left username object-fit-cover align-middle mt-3"
                      // src={item.image}
                      src={
                        doctor.ProfPhotoN ? doctor.ProfPhotoN : doctor.PROFILE_PHOTO_N ? doctor.PROFILE_PHOTO_N : require("../../assets/images/user_1.png")
                      }
                      roundedCircle
                    />
                    <Typography
                      className="text-center main_txt"
                      variant="h5"
                      component="h5"
                    >
                       {doctor.ISDOCTOR} {doctor.UserName ? doctor.UserName :doctor.USERNAME}
                    </Typography>
                    <Typography>
                      <small className="text-muted align-middle sub_text">
                      {doctor.Designation ? doctor.Designation : doctor.DESIGNATION}
                      </small>
                    </Typography>

                    <Divider
                      style={{
                        marginTop: 15,
                        marginBottom: 15,
                        height: 2,
                        background: "#ddd",
                      }}
                      variant="middle"
                    />
                  </div>

                  <div className="normal_txt frm_side blue-color">
                    <Typography>
                      Reason : {searchRedux.selectedreason}{" "}
                    </Typography>
                    <Typography>
                      Date & Time :{" "}
                      {moment(Apistate.selecteddate).format("ddd DD MMM")} |{" "}
                      {convertSecondsToHMS(Apistate.selectedslot)}
                    </Typography>
                  </div>
                </Box>
              </Col>
{error ?
  <Col lg={8} className="left-content slotbtn">
   <Box className="h-100" component={Paper} elevation={2} p={3}>
   <div  className="d-flex flex-direction-column align-items-center loader h-100 slotlog justify-content-center">
   <p>
     {errormsg}
     {/* Network issue is identified please try again later... */}
   </p>
   <Button
   onClick={()=> navigate('/appointments')}
   >
     Back
   </Button>
   </div>
 </Box>
 </Col>
 :
              <Col lg={8} className="left-content slotbtn">
                {Apistate.SlotLog === 0 ?
                <Box className="h-100" component={Paper} elevation={2} p={3}>
                  <div  className="d-flex flex-direction-column align-items-center loader h-100 slotlog justify-content-center">
                  <p>
                    {Apistate.SlotMsg}
                  </p>
                  <Button
                  onClick={()=> navigate('/appointments')}
                  >
                    Back
                  </Button>
                  </div>
                </Box>
                :
                <Box className="h-100" component={Paper} elevation={2} p={3}>

                 
                  <div className="mid_cnt">
                    <div className="text-center justify-content-center big-bar2 d-flex">
                      <Typography>Provide your details</Typography>
                    </div>

                    <br />

                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div>
                        <input
                          max={20}
                          maxLength={20}
                          type="text"
                          id="firstName"
                          className="form-control"
                          {...register("firstName")}
                          placeholder="First name (Name as per Medicare card)"
                          onInput={handleInput}
                         // pattern="[A-Za-z]+"
                        />
                        <p className="error">{errors.firstName?.message}</p>
                      </div>

                      <div>
                        <input
                         max={20}
                         maxLength={20}
                          type="text"
                          id="surname"
                          className="form-control"
                          {...register("surname")}
                          placeholder="Surname (Name as per Medicare card)"
                          onInput={handleInput}
                          //pattern="[A-Za-z]+"
                        />
                        <p className="error">{errors.surname?.message}</p>
                      </div>

                      <Row>
                        <div className="col-12">
                          <p className="DOBLable">Date of birth</p>
                          {/* <p>{currentKeycode}</p> */}
                        </div>
                        <div className="col-2">
                          <Controller
                            name="dateOfBirthDD"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <input
                                className="form-control dob_field"
                                {...field}
                                type="number"
                                placeholder="DD"
                                inputMode="numeric"
                                pattern="[0-3]*"
                                maxLength={2}                               
                                onBlur={validateDD}
                                onKeyDown={(event) => validateDay(event)}
                                onChange={(e) => {
                                 // field.onChange(e); // This is important to update RHF
                                  if (e.target.value.length <= 2) {
                                    field.onChange(e); // Update RHF only if length <= 2
                                  }
                                  if (e.target.value.length === 2) {
                                    document
                                      .getElementById("dateOfBirthMM")
                                      ?.focus();
                                  }
                                }}
                              />
                            )}
                          />
                          <p className="error">
                            {errors.dateOfBirthDD?.message} {invalidDD}
                          </p>
                        </div>
                        <div className="col-2">
                          <Controller
                            name="dateOfBirthMM"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <input
                                className="form-control dob_field"
                                id="dateOfBirthMM"
                                {...field}
                                type="number"
                                inputMode="numeric"
                                pattern="[0-3]*"
                                placeholder="MM"
                                maxLength={2}
                                onBlur={validateMM}
                                onKeyDown={(event) => validateMonth(event)}
                                onChange={(e) => {
                                  if (e.target.value.length <= 2) {
                                    field.onChange(e); // Update RHF only if length <= 2
                                  }
                                  if (e.target.value.length === 2) {
                                    document
                                      .getElementById("dateOfBirthYYYY")
                                      ?.focus();
                                  }
                                }}
                              />
                            )}
                          />
                          <p className="error">
                            {errors.dateOfBirthMM?.message} {invalidMM}
                          </p>
                        </div>

                        <div className="col-3">
                          <Controller
                            name="dateOfBirthYYYY"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <input
                                className="form-control dob_field"
                                id="dateOfBirthYYYY"
                                {...field}
                                type="number"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                placeholder="YYYY"
                                maxLength={4}
                                onKeyDown={(event) => validateYear(event)}
                                onBlur={validateYY}
                                onChange={(e) => {
                                  if (e.target.value.length <= 4) {
                                    field.onChange(e); // Update RHF only if length <= 2
                                  }
                                  if (e.target.value.length === 4) {
                                    document
                                      .getElementById("phoneNumber")
                                      ?.focus();
                                  }
                                }}
                              />
                            )}
                          />
                          <p className="error">
                            {errors.dateOfBirthYYYY?.message} {invalidYY}
                          </p>
                        </div>
                      </Row>

                      <div>
                        <input
                          type="number"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          id="phoneNumber"
                          className="form-control"
                          {...register("phoneNumber")}
                          placeholder="Mobile Number"
                        />
                        <p className="error">{errors.phoneNumber?.message}</p>
                      </div>

                      <div className="ml-3 genter_class">
         

                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              {...register("gender")}
                              value="2"
                              control={
                                <Radio
                                  sx={{
                                    "&.Mui-checked": {
                                      color: "#388FA2",
                                    },
                                  }}
                                />
                              }
                              label="Male"
                            />
                            <FormControlLabel
                              {...register("gender")}
                              value="1"
                              control={
                                <Radio
                                  sx={{
                                    "&.Mui-checked": {
                                      color: "#388FA2",
                                    },
                                  }}
                                />
                              }
                              label="Female"
                            />
                            <FormControlLabel
                              {...register("gender")}
                              value="0"
                              control={
                                <Radio
                                  sx={{
                                    "&.Mui-checked": {
                                      color: "#388FA2",
                                    },
                                  }}
                                />
                              }
                              label="Other"
                            />
                            <FormControlLabel
                              {...register("gender")}
                              value="3"
                              control={
                                <Radio
                                  sx={{
                                    "&.Mui-checked": {
                                      color: "#388FA2",
                                    },
                                  }}
                                />
                              }
                              label="Unknown"
                            />
                          </RadioGroup>
                        </FormControl>

                        <p className="error">{errors.gender?.message}</p>
                      </div>
                      <div className="book-apt-btn d-flex justify-content-center mt-4 mb-5">
                        <Button
                          className="book_appbtn mt-5 mb-5"
                          // onClick={handleOpenThirdModal}
                          type="submit"
                          id="submit"
                        >
                          Book Appointment
                        </Button>
                      </div>
                    </form>
                  </div>
                </Box>
      }
              </Col>
}
              {/* col left ends  */}

              {/* col right starts  */}

              {/* col right  ends  */}
            </Row>
          </Container>
        </main>
      ) : (
        <main className="mobile_view availableslots">
          <Box className="h-100" component={Paper} elevation={2} p={2}>

          <Breadcrumbs separator="<" aria-label="breadcrumb">
                      <Link
                        className="breadcrumb"
                        underline="hover"
                        key="1"
                        color="inherit"
                        onClick={() => navigate(-1)}
                      >
                        <ChevronLeftIcon sx={{ mr: 0.5 }} />
                        Back
                      </Link>
                    </Breadcrumbs>
                    <br/>
            <Row style={{ paddingLeft:16 }}>
              <Col xs={2} className="text-center d-flex justify-content-center">
             

                <Image
                  rounded
                  width={62}
                  height={62}
                  className="d-inline-flex align-left username object-fit-cover align-middle"
                  src={
                    doctor.ProfPhotoN ? doctor.ProfPhotoN : doctor.PROFILE_PHOTO_N ? doctor.PROFILE_PHOTO_N : require("../../assets/images/user_1.png")
                  }
                  roundedCircle
                />
              </Col>
              <Col>
                <Typography
                  className="text-left main_txt"
                  variant="h5"
                  component="h5"
                >
                 {doctor.ISDOCTOR} {doctor.UserName ? doctor.UserName :doctor.USERNAME}
                </Typography>
                <Typography>
                  <small className="text-muted align-middle sub_text">
                  {doctor.Designation ? doctor.Designation : doctor.DESIGNATION}
                  </small>
                </Typography>
              </Col>
            </Row>

            <Divider
              style={{
                marginLeft: 0,
                marginRight: 0,
                marginTop: 15,
                marginBottom: 15,
                height: 2,
                background: "#ddd",
              }}
              variant="middle"
            />

            <Typography className="blue-f-colr text-center">
              {searchRedux.selectedreason}{" "}
            </Typography>
            <Typography className="blue-f-colr text-center">
              {moment(Apistate.selecteddate).format("ddd DD MMM")} |{" "}
              {convertSecondsToHMS(Apistate.selectedslot)}
            </Typography>
            <Divider
              style={{
                marginLeft: 0,
                marginRight: 0,
                marginTop: 15,
                marginBottom: 15,
                height: 2,
                background: "#ddd",
              }}
              variant="middle"
            />


{error ?
  <Col lg={8} className="left-content slotbtn">
   <Box className="h-100" component={Paper} elevation={2} p={3}>
   <div  className="d-flex flex-direction-column align-items-center loader h-100 slotlog justify-content-center">
   <p>
     {errormsg}
     {/* Network issue is identified please try again later... */}
   </p>
   <Button
   onClick={()=> navigate('/appointments')}
   >
     Back
   </Button>
   </div> 
 </Box>
 </Col>
 :

<>

{Apistate.SlotLog === 0 &&
            <div className="text-center justify-content-center big-bar2 d-flex">
              <Typography>Provide your details</Typography>
            </div>
}
            {Apistate.SlotLog === 0 ?
                <Box className="h-100" component={Paper} elevation={2} p={3}>
                  <div className="d-flex align-items-center loader h-100 slotlog justify-content-center">
                  <p>
                    {Apistate.SlotMsg}
                  </p>
                  <Button
                  onClick={()=> navigate('/appointments')}
                  >
                    Back
                  </Button>
                  </div>
                </Box>
                :
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <input
                 max={20}
                 maxLength={20}
                  type="text"
                  id="firstName"
                  className="form-control"
                  {...register("firstName")}
                  placeholder="First name (Name as per Medicare card)"
                  onInput={handleInput}
                         // pattern="[A-Za-z]+"
                />
                <p className="error">{errors.firstName?.message}</p>
              </div>

              <div>
                <input
                 max={20}
                 maxLength={20}
                  type="text"
                  id="surname"
                  className="form-control"
                  {...register("surname")}
                  placeholder="Surname (Name as per Medicare card)"
                  onInput={handleInput}
                         // pattern="[A-Za-z]+"
                />
                <p className="error">{errors.surname?.message}</p>
              </div>

              <Row>
                <div className="col-12">
                  <p style={{ marginBottom: "12px", fontSize: "14px" }}>
                    Date of birth
                  </p>
                  {/* <p>{currentKeycode}</p> */}
                </div>

                <div className="col-4 pd-r">
                  <Controller
                    name="dateOfBirthDD"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        className="form-control dateOfBirthDDMMYYYY"
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        placeholder="DD"
                        onKeyDown={(event) => validateDay(event)}
                        maxLength={2}
                        onBlur={validateDD}
                        onChange={(e) => {
                          if (e.target.value.length <= 2) {
                            field.onChange(e); // Update RHF only if length <= 2
                          }

                          if (e.target.value.length === 2) {
                            document.getElementById("dateOfBirthMM")?.focus();
                          }
                        }}
                      />
                    )}
                  />
                  <p className="error">
                    {errors.dateOfBirthDD?.message} {invalidDD}
                  </p>
                </div>
                <div className="col-4 pd-r">
                  <Controller
                    name="dateOfBirthMM"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        className="form-control dateOfBirthDDMMYYYY"
                        id="dateOfBirthMM"
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        placeholder="MM"
                        onKeyDown={(event) => validateMonth(event)}
                        maxLength={2}
                        onBlur={validateMM}
                        onChange={(e) => {
                          if (e.target.value.length <= 2) {
                            field.onChange(e); // Update RHF only if length <= 2
                          }
                          if (e.target.value.length === 2) {
                            document.getElementById("dateOfBirthYYYY")?.focus();
                          }
                        }}
                      />
                    )}
                  />
                  <p className="error">
                    {errors.dateOfBirthMM?.message} {invalidMM}
                  </p>
                </div>

                <div className="col-4 pd-r">
                  <Controller
                    name="dateOfBirthYYYY"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        className="form-control dateOfBirthDDMMYYYY"
                        id="dateOfBirthYYYY"
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        placeholder="YYYY"
                        maxLength={4}
                        onBlur={validateYY}
                        onKeyDown={(event) => validateYear(event)}
                        onChange={(e) => {
                          if (e.target.value.length <= 4) {
                            field.onChange(e); // Update RHF only if length <= 2
                          }
                          if (e.target.value.length === 4) {
                            document.getElementById("phoneNumber")?.focus();
                          }
                        }}
                      />
                    )}
                  />
                  <p className="error">
                    {errors.dateOfBirthYYYY?.message} {invalidYY}
                  </p>
                </div>
              </Row>

              <div>
                <input
                  type="number"
                  id="phoneNumber"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  className="form-control"
                  {...register("phoneNumber")}
                  placeholder="Mobile Number"
                />
                <p className="error">{errors.phoneNumber?.message}</p>
              </div>

              <div className="ml-3 genter_class">
                <FormControl className="lg-12">
                  <RadioGroup
                    className="radio_button ml-00"
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      {...register("gender")}
                      value="2"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: "#388FA2",
                            },
                          }}
                        />
                      }
                      label="Male"
                    />
                    <FormControlLabel
                      {...register("gender")}
                      value="1"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: "#388FA2",
                            },
                          }}
                        />
                      }
                      label="Female"
                    />
                    <FormControlLabel
                      {...register("gender")}
                      value="0"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: "#388FA2",
                            },
                          }}
                        />
                      }
                      label="Other"
                    />
                    <FormControlLabel
                      {...register("gender")}
                      value="3"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: "#388FA2",
                            },
                          }}
                        />
                      }
                      label="Unknown"
                    />
                  </RadioGroup>
                </FormControl>

                <p className="error">{errors.gender?.message}</p>
              </div>
              <div className="book-apt-btn d-flex justify-content-center mt-4 mb-5">
                <Button  className="book_appbtn" type="submit" id="submit">
                  Book Appointment
                </Button>
              </div>
            </form>
      }
     
      </>
    }
          </Box>
        </main>
      )}
    </div>
  );
};
