// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 980px){
    .header .container{
        text-align: center;
    }
}

@media (min-width: 1400px){
.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1200px!important;
}
}`, "",{"version":3,"sources":["webpack://./src/responsive.css"],"names":[],"mappings":"AAAA;IACI;QACI,kBAAkB;IACtB;AACJ;;AAEA;AACA;IACI,2BAA2B;AAC/B;AACA","sourcesContent":["@media (min-width: 980px){\n    .header .container{\n        text-align: center;\n    }\n}\n\n@media (min-width: 1400px){\n.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {\n    max-width: 1200px!important;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
