import { TypedUseSelectorHook, useSelector as useReduxSelector } from 'react-redux';
import { combineReducers } from 'redux'
import paymentReducer from './User/reducer'
import themeReducer from './theme/reducer/themeReducer';
import mobileReducer from './MobileApp/reducer';
import ApiReducer from './Api/reducer';
const rootReducers = combineReducers({
    paymentReducer,
    themeReducer,
    mobileReducer,
    ApiReducer
})

export type PaymentState = ReturnType<typeof paymentReducer>
export type RootState = ReturnType<typeof rootReducers>
export type themeReducer = ReturnType<typeof themeReducer>
export type MobileState = ReturnType<typeof mobileReducer>
export type ApiState = ReturnType<typeof ApiReducer>

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector

export default rootReducers