import React, { Fragment, useEffect, useState } from 'react'

import { Col, Container, Row, Image } from "react-bootstrap";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
  Backdrop,
  Modal,
  Fade,
  CircularProgress,

} from '@mui/material';
import { useNavigate } from "react-router-dom";
import { getApi } from '../../services';
import moment from 'moment';
import { useDispatch } from "react-redux";
import { UpdateMobileData } from "../../redux/MobileApp/actions";
import { ApiState, MobileState as SearchReduxState, useSelector } from "../../redux";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'; 
import { themeReducer } from '../../redux'
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 1,
};

const dstyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 3,
};


export const EditPatientsList: React.FC = () => {
  const searchRedux = useSelector<SearchReduxState>((state) => state.mobileReducer);
  const themeRedux = useSelector<themeReducer>(
    (state) => state.themeReducer
  );
  const apistate = useSelector<ApiState>((state) => state.ApiReducer)
  const [loading,setLoading] = useState(false)
  const [firstname, setFirstname] = useState(searchRedux.user_firstname);
  const [surname, setSurname] = useState(searchRedux.user_surname);
  //const [dob, setDOB] = useState(searchRedux.user_dob);
  const initialDOB = moment(searchRedux.user_dob); // Formatting the initial date of birth

const [dob, setDOB] =useState(moment(initialDOB, 'DD/MM/YYYY'));;
  const dispatch = useDispatch();
  const [firstnameError, setFirstnameError] = useState(false);
  const [surnameError, setSurnameError] = useState(false);
  const [dobError, setDOBError] = useState(false);
  const [submitting,setsubmitting]= useState(false)
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [dopen, setdOpen] = React.useState(false);
  const handledOpen = () => setdOpen(true);
  const handledClose = () => setdOpen(false);


  const navigate = useNavigate()
  const getapi = getApi();


  const isValidDate = (dateString:any) => {
    return moment(dateString, 'YYYY-MM-DD', true).isValid();
  };

  const validateInputs = () => {
    let isValid = true;

    if (firstname.trim() === '') {
      setFirstnameError(true);
      isValid = false;
    } else {
      setFirstnameError(false);
    }


    if (surname.trim() === '') {
      setSurnameError(true);
      isValid = false;
    } else {
      setSurnameError(false);
    }

    // Add validation logic for date of birth
    if (dob === null) {
      setDOBError(true);
      isValid = false;
    } else {
      setDOBError(false);
    }
    return isValid;
  };


  const validateName = (name: string): boolean => {
    // Regular expression pattern to allow only alphabetic characters
    const namePattern = /^[a-zA-Z\s]*$/;
    return namePattern.test(name);
  };

  const handleFirstnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (validateName(value) || value === '') {
      setFirstname(value);
      setFirstnameError(false);
    } else {
     // setFirstnameError(true);
    }
  };

  const handleSurnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (validateName(value) || value === '') {
      setSurname(value);
      setSurnameError(false);
    } else {
     // setSurnameError(true);
    }
  };

useEffect(()=>{
 // console.log('edit redux',searchRedux)
},[])


const deleteuser = () =>{
    let data ={
      pid: searchRedux.pid
    }
    getapi
    .post("delentryhistory/", data)
    .then((response) => {
    //  console.log("response patients delete data", response);
      navigate('/patientslist')
    })
    .catch((error) => {
      console.log(error);
    });

}


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setsubmitting(true)
    if (validateInputs()) {
      let data = {
        firstname: firstname,
        surname: surname,
        dob: dob,
        pid: searchRedux.pid,
        udid: searchRedux.UUID,
        mobile: searchRedux.phone_no ? searchRedux.phone_no :localStorage.getItem('phone_no'),
        gender: searchRedux.Gender,
        location_id: apistate.location_id ? apistate.location_id :localStorage.getItem('location_id')
      };
  
      getapi
        .post("updtentryhistory/", data)
        .then((response) => {
        //  console.log("response patients data", response);
          setsubmitting(false)
          if (response.data.Success) {
            dispatch(UpdateMobileData('user_firstname',firstname ))
            dispatch(UpdateMobileData('user_surname',surname))
            dispatch(UpdateMobileData('user_dob',dob))
            handleOpen()
            setTimeout(() => {
              navigate('/patientslist');
            }, 3000);
           // navigate('/patientslist');
          } else {
            setsubmitting(false)
          }
        })
        .catch((error) => {
        //  console.log(error);
          setsubmitting(false)
        });
    } else {
      setsubmitting(false)
      // Form validation failed
     // console.log('Form validation failed');
    }
  };


  const resetForm = () => {
    setFirstname('');
    setSurname('');
  //  setDOB(undefined);
    setFirstnameError(false);
    setSurnameError(false);
    setDOBError(false);
    navigate('/patientslist')
  };

  // Get today's date in the format YYYY-MM-DD to set the max attribute
  const today = new Date().toISOString().split('T')[0];
  return (
    <Fragment>
 <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className="editpatientslist mt-2 float-left w-100">


       
          <Box>
            <Row className='mt-3'>
              <Col>
                <Typography className='plisttext'>Edit Patient Info</Typography>
              </Col>
              {/* <Col className='d-flex justify-content-end'>
                <div className='text-right d-flex justify-content-right align-item-right'>
                  <Button
                    style={{
                      textTransform: 'capitalize',
                      fontSize: 12,
                      background: '#BE1E2D',
                      fontWeight: 600
                    }}
                    onClick={handledOpen}
                    variant='contained'
                    color='secondary'
                  >
                    Delete
                  </Button>
                </div>
              </Col> */}

            </Row>
          </Box>
          <form onSubmit={handleSubmit}>
            <Fragment>
          <Box className="float-start mt-3 rounded-3 w-100" component={Paper} elevation={2} p={2}>
           
            <div  className='inputs-block mt-3'>
        <FormControl fullWidth error={firstnameError}>
          <FormLabel className="edit-form-label">First Name</FormLabel>
          <OutlinedInput
           sx={{
            borderColor:'#989898',
            borderRadius: '10px',
            textAlign: 'left', // Center aligns the entered text
            '& input::placeholder': {
              textAlign: 'left', // Center aligns the placeholder text
              color:'#989898',
              opacity:1,
              fontWeight:500
            },
          }}
        //   inputProps={{
        //     pattern: '/^[a-zA-Z]*$/'
        //   }}
        placeholder='(Name as per medicare card)'
            error={firstnameError}
            value={firstname}
            fullWidth
            onChange={handleFirstnameChange}
            className="mt-1 sinput-border"
          />
          {firstnameError && <FormHelperText>This field is required</FormHelperText>}
        </FormControl>
      </div>
      {/* Add similar blocks for Surname and Date of Birth */}
      
       <div className='inputs-block  mt-3'>
        <FormControl fullWidth error={surnameError}>
          <FormLabel className="edit-form-label">Surname</FormLabel>
          <OutlinedInput
          placeholder='(Name as per medicare card)'
            sx={{
                borderColor:'#989898',
                borderRadius: '10px',
                textAlign: 'left', // Center aligns the entered text
                '& input::placeholder': {
                  textAlign: 'left', // Center aligns the placeholder text
                  color:'#989898',
                  opacity:1,
                  fontWeight:500
                },
              }}
            error={surnameError}
            value={surname}
            fullWidth
            onChange={handleSurnameChange}
            className="mt-1 sinput-border"
          />
          {surnameError && <FormHelperText>This field is required</FormHelperText>}
        </FormControl>
      </div> 
      {/* for Date of Birth */}

      {/* <div className='inputs-block  mt-3 mb-5'>
        <FormControl fullWidth error={dobError}>
          <FormLabel className="edit-form-label">Date of Birth</FormLabel>
          <OutlinedInput
            sx={{
                borderColor:'#989898',
                borderRadius: '10px',
                textAlign: 'left', // Center aligns the entered text
                '& input::placeholder': {
                  textAlign: 'left', // Center aligns the placeholder text
                  color:'#989898',
                  fontWeight:500
                },
              }}
              inputProps={{ 
                max: today,
                style: { color: '#000000' }, // Text color
              placeholderstyle: { color: '#d9d9d9' }, 
            }}
           //   placeholder='[DD/MM/YYYY]'
            error={dobError}
            type='date'
            value={dob}
            fullWidth
            onChange={   (e) => {
              const enteredDate = e.target.value;
              setDOB(enteredDate);
              setDOBError(false);
            }}
            className="mt-1 sinput-border"
          />
          {dobError && <FormHelperText>This field is required</FormHelperText>}
        </FormControl>
      </div>  */}
      <div className='inputs-block  mt-3 mb-2'>
      <FormControl id='bobd' fullWidth error={dobError}>
          <FormLabel className="edit-form-label">Date of Birth</FormLabel>
      <DatePicker 
       closeOnSelect={true}
      format="DD/MM/YYYY"
       value={dob}
       disableFuture
     // maxDate={ moment()}
       onChange={(e:any) => {
         setDOB(e); 
         setDOBError(false);
         console.log('dob',e)
       }}
       sx={{
        borderColor:'#989898',
        borderRadius: '10px',
        textAlign: 'left', // Center aligns the entered text
        '& input::placeholder': {
          textAlign: 'left', // Center aligns the placeholder text
          color:'#989898',
          fontWeight:500
        },
      }}
       />
      </FormControl>

        {/* <FormControl fullWidth error={dobError}>
          <FormLabel className="edit-form-label">Date of Birth</FormLabel>
          <OutlinedInput
          id='datein'
            sx={{
                borderColor:'#989898',
                borderRadius: '10px',
                textAlign: 'left', // Center aligns the entered text
                '& input::placeholder': {
                  textAlign: 'left', // Center aligns the placeholder text
                  color:'#989898',
                  fontWeight:500
                },
              }}
              inputProps={{ 
                max: today,
                style: { color: '#000000' }, // Text color
                placeholderstyle: { color: '#d9d9d9' }, 
            }}
              placeholder='[DD/MM/YYYY]'
            error={dobError}
            type='date'
            value={dob}
            fullWidth
            onChange={(e) => {
              setDOB(e.target.value); 
              setDOBError(false);
            }}
            className="mt-1 sinput-border"
          />
          {dobError && <FormHelperText>This field is required</FormHelperText>}
        </FormControl> */}
      </div> 


              {/* <div  className='inputs-block  mt-4 mb-5'>
        <TextField
          sx={{
            borderColor:'#989898',
            borderRadius: '10px',
            textAlign: 'left', // Center aligns the entered text
            '& input::placeholder': {
              textAlign: 'left', // Center aligns the placeholder text
              color:'#989898',
              fontWeight:500
            },
          }}
          fullWidth
          label="Date of Birth"
          type="date"
          value={dob}
          onChange={(e) => {
            setDOB(e.target.value);
            setDOBError(false);
          }}
          InputLabelProps={{ shrink: true }}
          error={dobError}
          helperText={dobError ? 'Please enter a valid date' : ''}
        />
      </div>  */}
          
            
          </Box>
          <Row>
                <Col md={12} className='mt-3 mb-3'>
                <Button
                    type='submit'
                    className='bbtn btn-primary'
                    fullWidth
                    color='secondary'
                    variant='contained'
                    sx={{
                      color:"#fff",
                      textTransform: 'capitalize',
                      fontWeight: 600,
                      borderRadius:25,
                      fontSize: 19
                    }}

                  >
                    {submitting ? <CircularProgress sx={{color:"#FFF"}} size={32}/> :  'Update'}
                  </Button>

                
                </Col>

                <Col md={12}>
                <Button
                    className='cbtn otnbtn-primary'
                    fullWidth
                    variant='outlined'
                    color='secondary'
                    onClick={resetForm}
                    sx={{
                      borderRadius:25,
                      textTransform: 'capitalize',
                      fontWeight: 600,
                      fontSize: 19
                    }}
                  >
                    Cancel
                  </Button>

                </Col>
<Col md={12} className='mt-3 mb-3'>
<Button
className='btn-primary btn-blue'
                    style={{
                      borderRadius:25,
                      textTransform: 'capitalize',
                      fontSize: 19,                      
                      fontWeight: 600,
                      height:50,
                      color:'#fff'
                    }}
                    onClick={handledOpen}
                    variant='contained'
                    color='secondary'
                    fullWidth
                  >
                    Delete
                  </Button>

</Col>
              </Row>
              </Fragment>
              </form>
      </div>
    

{/* update success modal */}
      <Modal
      id={themeRedux.themeColor}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box  sx={style}>
            <div className='align-middle h-100 mt-5 no_app justify-content-center text-center'>
              {apistate.location_id === 'S25880ANA' ?
              <Image src={require("../../assets/images/mobile/pink/check.png")} />
              :
              <Image src={require("../../assets/images/mobile/check.png")} />
      }
              <p className='pt-5 pb-5 no-text'>Patient info was updated successfully.</p>
              <Row className='mb-3'>

                <Col xs={3}></Col>
                <Col className='d-flex justify-content-center'>
                  <Button
                    type='submit'
                   className="bbtn btn-primary btn-blue"
                    fullWidth
                    variant='contained'
                    onClick={()=>{
                      handleClose()
                      navigate('/patientslist')
                    }}
                    sx={{

                      textTransform: 'capitalize',
                      fontWeight: 600,
                      fontSize: 16
                    }}

                  >
                    Close
                  </Button>

                </Col>
                <Col xs={3}></Col>
              </Row>

            </div>
          </Box>
        </Fade>
      </Modal>
{/* delete conformation modal */}
<Modal
       id={themeRedux.themeColor}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={dopen}
        onClose={handledClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={dopen}>
          <Box sx={dstyle}>
            <div className='align-middle h-100 mt-5 no_app justify-content-center text-center'>
              <Image src={require("../../assets/images/mobile/warning1.png")} />
              <p className='pt-5 pb-5 no-text'>Are you certain about removing the member?</p>
              <Row>
                <Col>
                  <Button
                    className='cbtn btn-primary btn-pink'
                    fullWidth
                    variant='contained'
                    onClick={handledClose}
                    sx={{

                      textTransform: 'capitalize',
                      fontWeight: 600,
                      fontSize: 16
                    }}
                  >
                    No
                  </Button>
                </Col>

                <Col>
                  <Button
                  onClick={()=> deleteuser()}
                    type='submit'
                    className='bbtn btn-primary'
                    fullWidth
                    variant='contained'
                    sx={{

                      textTransform: 'capitalize',
                      fontWeight: 600,
                      fontSize: 16
                    }}

                  >
                    Yes
                  </Button>

                </Col>

              </Row>

            </div>
          </Box>
        </Fade>
      </Modal>
      </LocalizationProvider>
    </Fragment>
  )
}