import {
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  Paper,
  Breadcrumbs,
  Link,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import { Button, FormLabel, Col, Row } from "react-bootstrap";
import { Container, Image, Dropdown } from "react-bootstrap";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useAutocomplete } from "@mui/material/useAutocomplete";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import "./homecss.css";

export const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [reasondata, setReasonData] = useState<any>([]);
  const top100Films = [
    { label: "Nimna Abeydeera", id: 1 },
    { label: "Janaki Balasubramaniam", id: 2 },
    { label: "Gosagan Gopalakrishnan", id: 3 },
    { label: "Viswanadh Nookala", id: 4 },
  ];

  return (
    <Fragment>
      {!matches ? (
        <Container className="webview">
          <Row>
            <Col md={10} className="mx-auto">
              <Box
                component={Paper}
                elevation={2}
                p={0}
                mt={0}
                className="boxes"
              >
                <div className="login-div">
                  <Row>
                    <Col md={6}>
                      <img
                        src={require("../../assets/images/Maskgroupweb.png")}
                        alt="Logo"
                        className="img-fluid"
                      />
                    </Col>
                    <Col md={6} className="mt-10">
                      <div className="text-center mt-4">
                        <Typography className="first-text-web" variant="h4">
                          Find Your GP
                        </Typography>
                      </div>
                      <div>
                        <form className="custom-login-form p-4">
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={top100Films}
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField {...params} label="Search GP" />
                            )}
                          />
                        </form>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Box>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container className="mobileview">
          <Row>
            <Col lg={12} className="left-content mx-auto">
              <Box className="h-100" component={Paper} elevation={2} p={2}>
                <Row>
                  <Col lg={3}></Col>
                  <Col lg={6}>
                    <div className="align-content-center">
                      <h3 className="top_button d-flex justify-content-center align-items-center">
                        Patient Alert
                      </h3>
                      <h4 className="d-flex justify-content-center smt align-items-center pat-title mt-3">
                        <b>Do you have ANY of these symptoms</b>
                      </h4>
                    </div>

                    <div className="p-2">
                      <ul className="list_symtom">
                        <li className="symtoms">
                          <span>Fever</span>
                        </li>
                        <li className="symtoms">
                          <span>Cough</span>
                        </li>
                        <li className="symtoms">
                          <span>Cold</span>
                        </li>
                        <li className="symtoms">
                          <span>Sore throat</span>
                        </li>
                        <li className="symtoms">
                          <span>Shortness of breath</span>
                        </li>
                        <li className="symtoms">
                          <span>Any flu-like symptoms</span>
                        </li>
                        <li className="symtoms">
                          <span>Runny nose</span>
                        </li>
                        {/* <li>
      <span>Have you visited any of the exposure sites listed by WA health</span>
  </li>
  <li>
      <span>Travelled overseas/Interstate in the last two weeks</span>
  </li> */}
                      </ul>
                    </div>
                    <div className=" justify-content-center align-content-center align-content-center mb-5 mt--10">
                      <Row>
                        <Col xs={1} md={1}></Col>
                        <Col xs={5} md={5}>
                          <Button
                            variant="primary"
                            className="buttons btn-blue mt--0"
                            onClick={() => navigate("/patient-alert-yes")}
                          >
                            <b>Yes</b>
                          </Button>
                        </Col>
                        <Col xs={5} md={5}>
                          <Button
                            variant="primary"
                            className="buttons btn-blue mt--0"
                            //onClick={() => (window.location.href = item.ProfileLink)}
                            onClick={() => navigate("/profile")}
                          >
                            <b>No</b>
                          </Button>
                        </Col>
                        <Col xs={1} md={1}></Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Box>
            </Col>
          </Row>
        </Container>
      )}
    </Fragment>
  );
};
