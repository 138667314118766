import { TOGGLE_THEME, SET_THEME_COLOR } from '../action/themeActions';
import { ThemeData } from '../../../models/themedata';

// Define the initial state type
export interface ThemeState {
    darkMode: boolean;
    themeColor: string;
  }

const initialState: ThemeState = {
  darkMode: false,
  themeColor: 'blue', // Default theme color
};

const themeReducer = (state = initialState, action:any) => {
  switch (action.type) {
    case TOGGLE_THEME:
      return {
        ...state,
        darkMode: !state.darkMode,
      };
    case SET_THEME_COLOR:
      return {
        ...state,
        themeColor: action.payload,
      };
    default:
      return state;
  }
};

export default themeReducer;