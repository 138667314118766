import React, { useEffect, useState } from "react";
import {
  Breadcrumbs,
  Link,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";
import { Col, Container, Row, Image, Button } from "react-bootstrap";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate, useParams } from "react-router-dom";
import { getApi } from "../../services";
import Cookies from "js-cookie";
import { PaymentState as SearchReduxState, useSelector,ApiState } from "../../redux";
import { useDispatch } from "react-redux";
import { UpdatePaymentData, Resetdata } from "../../redux/User/actions";
import moment from "moment";
import { UpdateApiData } from "../../redux/Api/actions";

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  event.preventDefault();
//  console.info("You clicked a breadcrumb.");
}

type APTReason = {
  [key: string]: any;
};
export const Reason: React.FC = () => {
  const searchredux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );
  const [doctor,setDoctor] = useState<any>([])
  const [aptreason,setAptreason] = useState<any>([])

  const navigate = useNavigate();
  const { id } = useParams();
  const getapi = getApi();
  // FirstAvailable Time
  const [FirstAvail, setFirstAvail] = useState<any>([]);
  
  const [dataReason, setdataReason] = useState<any>([]);

  // Redux
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );
  const Apistate = useSelector<ApiState>(
    (state) => state.ApiReducer
  );

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [loadingReason, setloadingReason] = useState(false);

  const convertSecondsToHMS = (seconds: any) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    // Convert to 12-hour format

    let hours12 = hours % 12 || 12;
    hours12 = hours12 === 0 ? 12 : hours12; // Handle midnight (12 AM)

    const amPm = hours < 12 ? "AM" : "PM";

    return `${hours12.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${amPm.toString().padStart(2, "0")}`;
  };

 
  useEffect(() => {

    if(doctor.APTDate ?doctor.APTDate : doctor.ADATE !=null  && doctor.APTDate ?doctor.APTDate : doctor.ADATE !='-'){
      const VsetFirstAvailD =moment(doctor.APTDate ? doctor.APTDate : doctor.ADATE).format("ddd DD MMM");
      const VsetFirstAvailT =convertSecondsToHMS(doctor.APTTime ? doctor.APTTime : doctor.ATIME);
      setFirstAvail(VsetFirstAvailD+" "+VsetFirstAvailT)
    }else{
      setFirstAvail("Appointments not available")
    }
  
 
  }, [doctor.APTDate,doctor.ADATE]);


  const storeDoctorData = (apt: APTReason) => {
    const jsonString = JSON.stringify(apt);
  
    // Set the cookie with the JSON string
    document.cookie = `APTReason=${encodeURIComponent(jsonString)}; expires=Thu, 01 Jan 2030 00:00:00 UTC; path=/`;
  };

  

  useEffect (()=>{
    setLoading(false);
   },[])


  useEffect(() =>{
    // Retrieve the cookie value
    var cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)DoctorDetails\s*=\s*([^;]*).*$)|^.*$/, '$1');
    
    // If the cookie value exists, decode it and parse the JSON string back to an array
    if (cookieValue) {
      var retrievedArray = JSON.parse(decodeURIComponent(cookieValue));
      setDoctor(retrievedArray)
    } else {
 
    }

    

    },[dataReason])






  useEffect(()=>{

UserReasonData()

  },[])

  const UserReasonData = () => {
    setloadingReason(true);
  
    let data = {
      location_id: Apistate.location_id,
      user_id: Apistate.userid ? Apistate.userid : doctor.USERID ? doctor.USERID : Cookies.get('userid'),
    };
 
    getapi
      .post("aptreasonbyuser/", data)
      .then((res) => {
        setloadingReason(false);
       
        setdataReason(res.data.data);
        setAptreason(res.data.data)
        // var jsonString = JSON.stringify(res.data.data);
        // document.cookie = 'aptreasonbyuser=' + encodeURIComponent(jsonString);
        
      })
      .catch((err) => {
       
      });
  };


  return (
    <div>
      {loadingReason ? (
        <div className="d-flex align-items-center loader justify-content-center">
          <CircularProgress />
        </div>
      ) : (
        <>
          <section className="fvjobdetailheaderbgcolor"></section>

          {/* {data.map((item: any, idx: number) => ( */}
            <main className="fvjobdetailheaderbgmain">
              <Container>
                <Row className="row-eq-height ">
                  {/* col left starts  */}

                  <Col lg={12} className="left-content mx-auto">
                    <Box
                      className="h-100 card-reason-pad"
                      component={Paper}
                      elevation={2}
                      p={3}
                    >
                      <Breadcrumbs separator="<" aria-label="breadcrumb">
                        <Link
                          className="breadcrumb"
                          underline="hover"
                          key="1"
                          color="inherit"                        
                          onClick={() => navigate(-1)}
                        >
                          <ChevronLeftIcon sx={{ mr: 0.5 }} />
                          Back
                        </Link>
                      </Breadcrumbs>
                      <br />
                      <Row  style={{ paddingLeft:16 }}>
                        <Col lg={2} className="col-2 ml--10">
                          <p className="text-center pd-0">
                            <Image
                              rounded
                              width={65}
                              height={100}
                              className="d-inline-flex align-left username object-fit-cover align-middle profile-img"
                              src={
                                doctor.ProfPhotoN ? doctor.ProfPhotoN : doctor.PROFILE_PHOTO_N ? doctor.PROFILE_PHOTO_N : require("../../assets/images/user_1.png")
                              }
                              //  src={require('../../assets/images/user_1.png')}
                              roundedCircle
                            />
                          </p>
                        </Col>
                        <Col lg={9} className="col-10">
                          <div className="mt--15 ml-26">
                            <Typography
                              className="main_txt mt-3"
                              variant="h5"
                              component="h5"
                            >
                              {doctor.ISDOCTOR} {doctor.UserName ? doctor.UserName :doctor.USERNAME}
                            </Typography>
                            <Typography>
                              <b className="color-515151">
                              {doctor.Designation ? doctor.Designation : doctor.DESIGNATION}
                              </b>
                            </Typography>

                            <Typography className="sub_txt2 mt-2 font-14 show-web">
                              <b className="color-00B8E9">Next Available :</b>{" "}
                              <b className="color-00B8E9">
                                {FirstAvail}
                              </b>
                            </Typography>
                            <Typography className="sub_txt2 mt-2 font-14 show-mob">
                              <Row>
                                <div className="col-12">
                                  <b className="color-00B8E9">
                                    Next Available :  <b className="color-00B8E9">
                                    {FirstAvail}
                                  </b>
                                  </b>{" "}
                                </div>
                                <div className="col-12">
                                 
                                </div>
                              </Row>
                            </Typography>
                          </div>
                        </Col>
                        <Divider className="hr-profile" variant="middle" />
                      </Row>
                      <Row className="pad-profile text-center">
                        <Col lg={12} className="mt-m4 pd-0">
                          <p className="ReasonTitle bg-white-reason titleactive">
                            Select Appointment Reason
                            <Breadcrumbs
                              separator="<"
                              aria-label="breadcrumb"
                              className="cus-breadcrumb"
                            >
                              <ChevronLeftIcon sx={{ mr: 0.1 }} />
                            </Breadcrumbs>
                          </p>
                        </Col>

                        {aptreason.map((item: any, idx: number) => (
                          <Col lg={12} className="pd-0">
                            <p
                              className="ReasonTitle bg-white-reason cursor"
                              onClick={() => {
                                storeDoctorData(item)
                                dispatch(
                                  UpdatePaymentData(
                                    "selectedreasonid",
                                    item.ReasonId
                                  )
                                );
                                dispatch(
                                  UpdatePaymentData(
                                    "selectedreason",
                                    item.MainReason
                                  )
                                );
                               dispatch(UpdateApiData("selectedSlotCount",item.SlotCount)); 
                                Cookies.set("selectedSlotCount",item.SlotCount)
                                dispatch(UpdatePaymentData("ReasonForUnSet",item.ReasonForUnSet)); 
                                
                                navigate("/appointments/");
                              }}
                            >
                              <b>{item.MainReason}</b>
                            </p>
                          </Col>
                        ))}
                      </Row>
                    </Box>
                  </Col>
                </Row>
              </Container>
            </main>
          {/* ))} */}
        </>
      )}
    </div>
  );
};
