import { Box, Paper } from "@mui/material";
import React, { Fragment, useState } from "react";
import { Col, Row, Container, Image } from "react-bootstrap";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import "./homecss.css";

import { PaymentState as SearchReduxState, useSelector } from "../../redux";
import { useDispatch } from "react-redux";
import { UpdatePaymentData, Resetdata } from "../../redux/User/actions";

export const ServerDown: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const searchredux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );

  return (
    <Fragment>
      {!matches ? (
        <Container className="webview">
          <Row>
            <Col md={10} className="mx-auto">
              <Box
                component={Paper}
                elevation={2}
                p={0}
                mt={0}
                className="boxes"
              >
                <div className="login-div">
                  <Row>
                    <Col>
                      <div className="text-center">
                        <h4 className="text-center p-5 sdown fw-bold">
                        {/* The Server is down. Please Try again Later or for appointments please contact the Practice Ph: <a href="tel:0893311760">0893311760</a>. */}
                       {searchredux.Is_ServerDown_Msg}
                        </h4>
                       
                      </div>
                    </Col>
                  </Row>
                </div>
              </Box>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container className="mobileview">
          <Row>
            <Col lg={12} className="left-content mx-auto">
              <Box className="h-100" component={Paper} elevation={2} p={2}>
                <Row>
                  <Col lg={3}>
                  <div className="text-center">
                        <h4 className="text-center p-5 sdown fw-bold">
                        {/* The Server is down. Please Try again Later or for appointments please contact the Practice Ph: <a href="tel:0893311760">0893311760</a>. */}
                       {searchredux.Is_ServerDown_Msg}
                        </h4>
                       
                      </div>
                  </Col>
                </Row>
              </Box>
            </Col>
          </Row>
        </Container>
      )}
    </Fragment>
  );
};
