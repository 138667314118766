import React, { Fragment } from 'react'

import { Col, Container, Row, Image } from "react-bootstrap";
import { PaymentState as SearchReduxState, useSelector,themeReducer } from "../redux";
import { MobileState as MobileReduxState } from "../redux";
export const Mobileheader = () => {
    const searchRedux = useSelector<SearchReduxState>(
        (state) => state.paymentReducer
      );
      const mobileRedux = useSelector<MobileReduxState>(
        (state) => state.mobileReducer
      );

    return (
        <Fragment>
            <div className="logo_header d-flex align-items-center h-75 flex-column justify-content-center">
            {mobileRedux.location_id === 'S25880ANA' ?
      <img
        alt=""
        src={require("../assets/images/Pinklogo1.png")}
        className="pink_header-logo"
      />
   :
                <Image src={require("../assets/images/mobile/mlogo.png")} />
    }
    {mobileRedux.location_id != 'S25880ANA' &&
                <p className='app_logo'>{searchRedux.Location_Name}</p>
}
            </div>
        </Fragment>


    )
}

