import axios from "axios";

export const getApi = () => (
    axios.create({       
       // baseURL : "https://coddapt.coddapps.com.au/api",
          baseURL : "https://coddgpapi.coddapps.com.au/api",
        
    
        headers: {
            Validate: 'y2s4pyj52nzr49jnuxxgqk5jtj28cj', 
            'Content-Type': 'application/json'
       
        },  
    })
)

