import { createStore, applyMiddleware } from "redux";
import rootReducer from './index';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist'
import { CookieStorage } from 'redux-persist-cookie-storage'
import Cookies from 'cookies-js'
import themeReducer from '../redux/theme/reducer/themeReducer';

const middleware  = [thunk];

const persistConfig = {
    key: 'root',
    storage: new CookieStorage(Cookies),
  //  blacklist: ['searchReducer', 'talentReducer']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const reduxStore = () => {
    let store: any = createStore(persistedReducer, applyMiddleware(...middleware))
    const themestore = createStore(themeReducer);
    let persistor = persistStore(store)
    return { store, persistor,themestore }
}




export default reduxStore
//export type RootState = ReturnType<typeof rootReducer>



