import React, { Fragment, useEffect, useState } from 'react'

import { Col, Container, Row, Image } from "react-bootstrap";
import { Box, Button,CircularProgress,Divider,Grid,List,ListItem,ListItemAvatar,ListItemText,Paper, Typography, styled } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { getApi } from '../../services';
import { ApiState, MobileState as SearchReduxState, useSelector } from "../../redux";
import { useDispatch } from "react-redux";

import { UpdateMobileData } from "../../redux/MobileApp/actions";
import ModeIcon from '@mui/icons-material/Mode';
import Cookies from 'js-cookie';

export const PatientsList: React.FC = () => {
  const searchRedux = useSelector<SearchReduxState>((state) => state.mobileReducer);
  const apistate = useSelector<ApiState>((state) => state.ApiReducer)
  const [location,setloc] = useState('')
  const [loading,setLoading] = useState(false)
  const [data,setdata] = useState([])
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const getapi = getApi();
  const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));
useEffect(() =>{
  console.log('redux',searchRedux)
  setloc(btoa(apistate.location_id))

},[searchRedux,localStorage.getItem('location_id')])

useEffect(() =>{
  Cookies.set('test-cookie','hello world')
},[])

useEffect(() =>{
 let tt= Cookies.get('test-cookie')
//  console.log(tt)
},[])

  const getData =() =>{
 //   event.preventDefault();
 setLoading(true)
    let data ={
        
        "location_id" : apistate.location_id ? apistate.location_id : localStorage.getItem('location_id'),
        "phone_no" : searchRedux.phone_no ? searchRedux.phone_no :localStorage.getItem('phone_no')
      //  "udid" : searchRedux.UUID ? searchRedux.UUID :localStorage.getItem('uuid')
    }
  //  console.log(data)
    
        getapi
          .post("getentryhistory/",data)
          .then((response) => {
            console.log("response patients data",response);
            setLoading(false)
            if(response.data.Success){
              setdata(response.data.data)
            }
            else{
              navigate('/add-patientslist')
            }
            
          })
          .catch((error) => {
            console.log(error);
            setLoading(false)
          
          });
      
}

useEffect(() =>{
 // console.log('searchredux',searchRedux)
  getData()
},[])
      
    return (
        <Fragment>
        {loading ? (
        <div className="d-flex align-items-center loader h-100 justify-content-center">
          <CircularProgress />
        </div>
      ) : (  
            <div className="patientslist mt-2 float-left w-100">
              
                <Box>
                    <Row>
                        <Col>
                        <Typography className='plisttext'>Patients list</Typography>
                        </Col>
                        {/* <Col className='d-flex justify-content-end'>
                            <div className='text-right d-flex justify-content-right align-item-right'>
                           <Button
                           onClick={()=> navigate('/add-patientslist')}
                           style={{ 
                            textTransform: 'capitalize',
                            fontSize:12,
                            color:"#fff",
                            fontWeight:600
                        }}
                           variant='contained'
                           color='secondary'
                           > 
                            Add Member
                           </Button>
                           </div>
                        </Col> */}

                    </Row>
                </Box>

                <Box className="h-100 mt-4 w-100" sx={{minHeight:'77vh'}}>
                  {data.length != 0 ?
                <Grid container spacing={2}>

                <Grid item xs={12} md={6} style={{ marginBottom:30 }}>
                {data && data.map((item:any,idx:any) =>
   <Box  component={Paper} elevation={2} style={{
    marginBottom:5
   }} p={1}>
 <ListItem
             
             secondaryAction={
               <Button
               sx={{
                 minWidth:30,
                 maxWidth:30
               }}
               onClick={(() => 
                 
                 {
                   dispatch(UpdateMobileData('user_firstname',item.FirstName ))
                   dispatch(UpdateMobileData('user_surname',item.SurName))
                   dispatch(UpdateMobileData('user_dob',item.Dob))
                   dispatch(UpdateMobileData('user_gender',item.Gender))
                   dispatch(UpdateMobileData('pid',item.Id))
                   navigate('/edit-patientslist')
                 }
                 )}
               style={{ 
                textTransform: 'capitalize',
                fontSize:10,
                width:30,
                fontWeight:600
            }}
               variant='contained'
               className="editbutton btn-blue"
               > 
               <ModeIcon style={{
                 fontSize:12
               }}
               />
               </Button>
             }
           >
             <ListItemAvatar>
              {apistate.location_id === 'S25880ANA' ?
               <Image src={require("../../assets/images/mobile/pink/ProfileSkyblue.png")}/>
               :
               <Image src={require("../../assets/images/mobile/ProfileSkyblue.png")}/>
            }
             </ListItemAvatar>
             <ListItemText
              onClick={() =>{
                dispatch(UpdateMobileData('user_firstname',item.FirstName ))
                dispatch(UpdateMobileData('user_surname',item.SurName))
                dispatch(UpdateMobileData('user_dob',item.Dob))
                dispatch(UpdateMobileData('user_gender',item.Gender))
                dispatch(UpdateMobileData('pid',item.Id))
                navigate('/?'+location)
              }}
               primary={item.FirstName+' '+item.SurName}
               // secondary={secondary ? 'Secondary text' : null}
             />
           </ListItem>
   </Box>
   )}

             
       
        </Grid>

        <div className='text-center w-100 fixed-bottom d-flex justify-content-center align-item-center'>
                           <Button
                            className='btn-primary'
                           onClick={()=> navigate('/add-patientslist')}
                           style={{ 
                            color:"#fff",
                            textTransform: 'capitalize',
                            fontWeight: 600,
                            borderRadius:25,
                            fontSize: 17,
                            width:'90%',
                            marginBottom:25
                           
                        }}
                           variant='contained'
                           color='secondary'
                           > 
                            Add Member
                           </Button>
                           </div>


                </Grid>
                :
               <div className='align-middle h-100 mt-5 no_app justify-content-center text-center'>
                {apistate.location_id === 'S25880ANA' ?
                <Image src={require("../../assets/images/mobile/pink/list1.png")}/>
                :
                <Image src={require("../../assets/images/mobile/list1.png")}/>
                      }
                
                <p className='p-3 no-text'>No available members; please register a new one.</p>

                <div className='text-center w-100 fixed-bottom d-flex justify-content-center align-item-center'>
                           <Button
                           className='btn-primary'
                           onClick={()=> navigate('/add-patientslist')}
                           style={{ 
                            color:"#fff",
                            textTransform: 'capitalize',
                            fontWeight: 600,
                            borderRadius:25,
                            fontSize: 17,
                            width:'90%',
                            marginBottom:25
                           
                        }}
                           variant='contained'
                           color='secondary'
                           > 
                            Add Member
                           </Button>
                           </div>

                </div>

                
}
                </Box>
            </div> 
      )
              }
        </Fragment>


    )
}