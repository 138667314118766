import { PaymentData } from "../../../models/userdata";
import { UpdatePaymentData, UPDATE_PAYMENT_DATA,RESET_DATA,ResetData } from "../actions";
export const initialState: PaymentData = {
    // Id:0,
    //location_id:'',
   // login_from:'',
    // AptLength:'',
    // username: '',
    // userid:'',
    // Billdetails:[],
    // APT_Time_Length: '',
    // APTDate:'',
    // APTTime:'',
    // IsAvailable: '',
    // Gender:'',
    // Academy: '',
    // ProfPhotoN:'',
    // ProfPic: '',
    // Profmsg:'',
    // AptreasonList:[],
    selectedreasonid: 0,
    selectedSlotCount:'',
    selectedreason:'',
    // selecteddate:'',
    selectedslot:'',
    // uniqueid:'',
    // BTN_1_URL:'', 
    Location_Name:'',
    // bill:'',
    // general_note:'',
    // Location_mobile:'',
    // patient_general_note:'',
    // New_PatientMsg_No:'',
    // New_PatientMsg:'',
    // Doctors_ListingOrder:0,
    // Designation:'',
    ReasonForUnSet:0,
    Is_ServerDown_Msg:'',
    // SlotLog:1,
    // SlotMsg:'',
    Patient_Alert:1,
    // NewPatientAptMsg:0,
    // BTN_1:0
}

const paymentReducer = (state: PaymentData = initialState, action: UpdatePaymentData | ResetData):PaymentData => {
    switch (action.type) {
        case UPDATE_PAYMENT_DATA:
            return {

                ...state,
                [action.key]: action.value

            }
            case RESET_DATA:
                return {
                    ...initialState,
                    //location_id: state.location_id, // Preserve location_id from the initial state
                   // login_from: state.login_from,
                    // userid:state.userid,
                    // username:state.username,
                    // APTDate:state.APTDate,
                    // APTTime:state.APTTime
                }
        default:
            return state
    }
}

export default paymentReducer